import { Box, Button, Stack, Switch, Tooltip, Typography } from "@mui/material";
import MainCard from "../../components/cards/MainCard";
import {
  GridActionsCellItem,
  GridColDef,
  GridPaginationModel,
  GridSortModel,
} from "@mui/x-data-grid";
import { IconEdit, IconTrash, IconKey, IconRestore } from "@tabler/icons-react";

import DataGridTable from "../../components/DataGridTable";
import { useCallback, useRef, useState } from "react";
import { useModal } from "mui-modal-provider";
import AddUserModal from "./AddUserModal";
import useUser from "../../../hooks/useUser";
import moment from "moment";
import DeleteUserModal from "./DeleteUserModal";
import { debounce } from "lodash";
import ChangePasswordModal from "./ChangePasswordModal";
import SearchField from "../../components/inputs/SearchField";
import BasicButton from "../../components/buttons/BasicButton";
import { ROLE } from "../../../constants/MenuSelection";
import useAuth from "../../../hooks/useAuth";
import RestoreUserModal from "./RestoreUserModal";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";


const Users = () => {
  const { userId } = useAuth();
  // const { isStaff } = useUser(userId);

  const [search, setSearch] = useState("");
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "createdAt", sort: "desc" },
  ]);
  const [showDeletedUsers, setShowDeletedUsers] = useState(false);

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 12,
  });

  const { users, isLoadingUsers, totalUsers, isStaff } = useUser(
    userId,
    search,
    paginationModel,
    sortModel,
    undefined,
    showDeletedUsers
  );

  const { showModal } = useModal();

  const handleAddUser = useCallback(
    (user?: any) => {
      const modal: any = showModal(AddUserModal, {
        onClose: () => modal.hide(),
        user,
      });
    },
    [showModal]
  );

  const handleDeleteUser = useCallback(
    (user: any) => {
      const modal: any = showModal(DeleteUserModal, {
        onClose: () => modal.hide(),
        user1: user,
      });
    },
    [showModal]
  );

  const handlePasswordChange = useCallback(
    (user?: any) => {
      const modal: any = showModal(ChangePasswordModal, {
        onClose: () => modal.hide(),
        user1: user,
      });
    },
    [showModal]
  );

  const handleRestoreCases = useCallback(
    (data: any) => {
      console.log({ data });
      const modal: any = showModal(RestoreUserModal, {
        onClose: () => modal.hide(),
        data,
        id: data?._id,
      });
    },
    [showModal]
  );

  const columns: GridColDef[] = [
    {
      field: "recordId",
      headerName: "Record ID#",
      minWidth: 140,
      renderCell: (params: any) => params?.row?.recordId || "-",
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    {
      field: "firstName",
      headerName: "First Name",
      minWidth: 160,
      renderCell: (params: any) => capitalizeFirstLetter(params?.row?.firstName),
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      minWidth: 160,
      renderCell: (params: any) => capitalizeFirstLetter(params?.row?.lastName),
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 200,
      flex: 1,
      renderCell: (params: any) => params?.row?.email,
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    {
      field: "role",
      headerName: "Role",
      minWidth: 200,
      renderCell: (params: any) =>
        ROLE?.find((role: any) => role?.value === params?.row?.role)?.label ||
        "-",
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    {
      field: "isActive",
      headerName: "Status",
      minWidth: 120,
      renderCell: (params: any) => {
        return (
          <Button
            sx={{
              textTransform: "capitalize",
              color: "black",
              width: 80,
              pointerEvents: "none",
              "&:hover": {
                backgroundColor: params?.row?.isActive ? "#ADDFAD" : "#FF9494", // or specify any other color
              },
              backgroundColor: params?.row?.isActive ? "#ADDFAD" : "#FF9494",
            }}
            size="small"
          >
            {params?.row?.isActive ? "Active" : "Inactive"}
          </Button>
        );
      },
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    {
      field: "mobileNumber",
      headerName: "Mobile Number",
      minWidth: 160,
      renderCell: (params: any) => params?.row?.mobileNumber || "-",
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      minWidth: 160,
      renderCell: (params: any) => params?.row?.phoneNumber || "-",
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    {
      field: "createdAt",
      headerName: "Created Date",
      minWidth: 200,
      renderCell: (params: any) =>
        moment(params?.row?.createdAt).format("MM/DD/YYYY, h:mm A"),
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    // {
    //   field: "updatedAt",
    //   headerName: "Updated Date",
    //   minWidth: 180,
    //   renderCell: (params: any) =>
    //     moment(params?.row?.updatedAt).format("MM/DD/YYYY, h:mm A"),
    //   renderHeader: (params: any) => params?.colDef?.headerName,
    // },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      minWidth: 160,
      getActions: ({ row }: any) => {
        return !showDeletedUsers
          ? [
              <Tooltip title="Edit">
                <GridActionsCellItem
                  icon={<IconEdit />}
                  label="Edit"
                  onClick={() => handleAddUser(row)}
                />
              </Tooltip>,

              <Tooltip title="Delete">
                <GridActionsCellItem
                  icon={<IconTrash />}
                  label="View"
                  color="error"
                  onClick={() => handleDeleteUser(row)}
                />
              </Tooltip>,
              <Tooltip title="Change Password">
                <GridActionsCellItem
                  icon={<IconKey />}
                  label="Change Password"
                  onClick={() => handlePasswordChange(row)}
                />
              </Tooltip>,
            ]
          : [
              <Tooltip title="Restore User">
                <GridActionsCellItem
                  disabled={isStaff}
                  color="error"
                  icon={<IconRestore fontSize="large" />}
                  label={"Restore"}
                  onClick={() => handleRestoreCases(row)}
                />
              </Tooltip>,
            ];
      },
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
  ];

  // const handleSearchChange = (event: any) => {
  //   setSearch(event.target.value);
  // };

  // Add debounce to hold render events
  const debouncedSetSearch = useRef(
    debounce((value) => setSearch(value), 400)
  ).current;

  const handleSearchChange = useCallback(
    (event: any) => {
      debouncedSetSearch(event.target.value);
    },
    [debouncedSetSearch]
  );

  const handleSortModelChange = (newModel: any[]) => {
    setSortModel(newModel);
  };

  const showDeletedUser = () => {
    setShowDeletedUsers(!showDeletedUsers);
  };

  return (
    <Box>
      <MainCard
        title={<Typography variant="h1">Users</Typography>}
        actions={
          <Stack direction={"row"} alignItems={"center"} spacing={2}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography sx={{ fontSize: 16 }}>Show Deleted Users</Typography>
              <Switch
                inputProps={{ "aria-label": "ant design" }}
                size="small"
                onChange={() => {
                  showDeletedUser();
                }}
              />
            </Stack>
            <SearchField
              onChange={handleSearchChange}
              size="small"
              placeholder="Search user"
            />

            <BasicButton
              label={"Add Users"}
              variant="contained"
              color="success"
              size={window.innerWidth < 1420 ? "small" : "medium"}
              onClick={() => handleAddUser(null)}
            />
          </Stack>
        }
        content={
          <DataGridTable
            onPaginationModelChange={setPaginationModel}
            paginationModel={paginationModel}
            column={columns}
            rows={users}
            isLoading={isLoadingUsers}
            totalRows={totalUsers}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
          />
        }
      />
    </Box>
  );
};

export default Users;
