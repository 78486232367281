import styled from "@emotion/styled";
import {
  TextField as MUITextField,
  TextFieldProps as MUITextFieldProps,
} from "@mui/material";
import React from "react";
import { Controller, FieldError } from "react-hook-form";

// Type definition for the custom TextField props
export type TextFieldProps = Omit<MUITextFieldProps, "name"> & {
  name: string;
  optional?: boolean;
  parseError?: (error: FieldError) => string;
  isPassword?: boolean;
  max?: number;
  width?: string;
};

// Styled TextField component with custom styles
const CustomTextField = styled(MUITextField, {
  shouldForwardProp: (prop) => prop !== "isPassword",
})<{ isPassword?: boolean }>(({ isPassword }) => ({
  "& label.Mui-focused": {
    color: "#6F7E8C",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": { borderRadius: "12px" },
    fontSize: "1rem",
    fontFamily: "'Arial', sans-serif",
    backgroundColor: "#f5f5f500",
    "&.Mui-focused fieldset": {
      borderColor: "#6F7E8C",
    },
    // overflow: "hidden", // Ensures content respects the border radius
    borderRadius: "12px", // Ensures the root also has border-radius
  },
  "& .MuiInputBase-input": {
    fontSize: isPassword ? "1rem" : "1rem",
  },
  "& .MuiInputLabel-root": {
    fontSize: "0.875rem",
  },
}));

/**
 * Custom TextField component wrapped with Controller from react-hook-form
 * @component MuiTextField
 * @author Sanjay
 *
 */
const MuiTextField = React.memo(
  ({
    parseError,
    type,
    required,
    name,
    width,
    label,
    optional,
    isPassword,
    ...rest
  }: TextFieldProps): JSX.Element => {
    // const { control, handleSubmit } = useForm();

    return (
      <Controller
        name={name}
        render={({
          field: { value, onChange, onBlur },
          fieldState: { invalid, error },
        }) => (
          <CustomTextField
            {...rest}
            inputProps={{ ...rest.inputProps }}
            name={name}
            // InputLabelProps={{ shrink: true }}
            label={optional ? `${label} (Optional)` : label}
            value={value || ""}
            onChange={onChange}
            onBlur={onBlur}
            required={required}
            type={type}
            error={invalid}
            sx={{ width: width ? width : "100%" }}
            isPassword={type === "password"} // Pass isPassword prop to styled component
            helperText={
              error
                ? typeof parseError === "function"
                  ? parseError(error as any)
                  : error.message
                : rest.helperText
            }
          />
        )}
      />
    );
  }
);

export default MuiTextField;
