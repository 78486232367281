import { Box, Button, DialogProps } from "@mui/material";
import BasicModal from "../../components/modal/BasicModal";
import { useSnackbarHelper } from "../../components/Snackbar";
import useUser from "../../../hooks/useUser";

interface Props extends DialogProps {
  onClose: () => void;
  data?: any;
  todoId?: string;
  caseId?: string;
}

const RestoreUserModal = ({ onClose, data, id, ...props }: Props) => {
  // const { deleteTodo } = useTodo({ caseId });
  const { restoreUser, isRestoreUserLoading } = useUser(id);
  const showSnackbar = useSnackbarHelper();

  const handleRestoreCase = async () => {
    try {
      const response = await restoreUser();
      console.log({ response });
      const message = response?.message;
      showSnackbar(message, "success");

      onClose();
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <Box>
      <BasicModal
        onClose={onClose}
        onSubmit={handleRestoreCase}
        title="Restore User"
        content="Do you really want to restore this user?"
        actions={
          <>
            <Button
              variant="contained"
              type="submit"
              color="success"
              sx={{ textTransform: "capitalize" }}
              disabled={isRestoreUserLoading}
            >
              Restore
            </Button>
            <Button
              variant="outlined"
              sx={{ textTransform: "capitalize" }}
              onClick={() => onClose()}
              color="error"
            >
              Cancel
            </Button>
          </>
        }
        {...props}
      />
    </Box>
  );
};

export default RestoreUserModal;
