import { useMemo } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  DialogProps,
  Stack,
} from "@mui/material";
import MuiTextField from "../../components/inputs/MuiTextField";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "../../components/inputs/Select";
import Checkbox from "../../components/inputs/CheckBox";
import useUser from "../../../hooks/useUser";
import BasicModal from "../../components/modal/BasicModal";
import { User } from "../../../types/users";
import PhoneNumber from "../../components/inputs/PhoneNumber";
import { useSnackbarHelper } from "../../components/Snackbar";
import { ROLE } from "../../../constants/MenuSelection";
// import io from "socket.io-client";

interface Props extends DialogProps {
  onClose: () => void;
  user: User;
}
const AddUserModal = ({ onClose, user, ...props }: Props) => {
  const showSnackbar = useSnackbarHelper();
  const { addUser, isAddingUser, updateUser, isUpdatingUser } = useUser(
    user?._id
  );

  const initialValues = useMemo(
    () => ({
      // userName: user?.userName || "",
      firstName: user?.firstName || "",
      middleName: user?.middleName || "",
      lastName: user?.lastName || "",
      phoneNumber: user?.phoneNumber || "",
      mobileNumber: user?.mobileNumber || "",
      email: user?.email || "",
      role: user?.role || "",
      isActive: user?.isActive || false,
      canChangeEmail: user?.canChangeEmail || false,
      canChangeMobileNumber: user?.canChangeMobileNumber || false,
      canChangePhoneNumber: user?.canChangePhoneNumber || false,
      canChangePassword: user?.canChangePassword || false,
    }),
    [user]
  );

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const phoneRegExp = /^\+\d{1,3} \(\d{3}\) \d{3}-\d{4}$/;

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .matches(
        /^(?:[a-zA-Z]+(?:\s[a-zA-Z]+)*)?$/,
        "First name should not contain symbols/numbers or spaces"
      )
      .required("Required"),
    middleName: Yup.string().matches(
      /^(?:[a-zA-Z]+(?:\s[a-zA-Z]+)*)?$/,
      "Middle name should not contain symbols/numbers or spaces"
    ),
    lastName: Yup.string()
      .matches(
        /^(?:[a-zA-Z]+(?:\s[a-zA-Z]+)*)?$/,
        "Last name should not contain symbols/numbers or spaces"
      )
      .required("Required"),
    email: Yup.string()
      .required("Required")
      .email("Must be a valid email")
      .transform((value, originalValue) => originalValue.toLowerCase())
      .matches(emailRegex, "Must be a valid email"),
    phoneNumber: Yup.string()
      .nullable() // Allow null values
      .notRequired() // Allow the field to be optional
      .test("phoneNumber", "Phone number is not valid", function (value) {
        if (!value || value.trim() === "" || value === "+") {
          return true; // Pass validation if the field is blank or just "+"
        }
        return phoneRegExp.test(value); // Apply regex validation if not blank
      }),
    mobileNumber: Yup.string()
      .required("Required")
      .matches(phoneRegExp, "Mobile number is not valid"),
    role: Yup.string().required("Required"),
    isActive: Yup.boolean(),
    canChangeEmail: Yup.boolean(),
    canChangeMobileNumber: Yup.boolean(),
    canChangePhoneNumber: Yup.boolean(),
    canChangePassword: Yup.boolean(),
  });

  const formContext = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });
  // const socket = io("http://localhost:8000", {
  //   transports: ["websocket", "polling", "flashsocket"],
  // });

  // const handleUserLogOut = () => {
  //   socket.emit("register", {
  //     userId: user._id,
  //     message: "Your Id has been InActive. Please contact to the admin.",
  //   });
  // };
  const handleSubmit = async (values?: any) => {
    try {
      const data = !!user ? await updateUser(values) : await addUser(values);

      const message = data?.message || "Success";
      // if (!values?.isActive) {
      //   handleUserLogOut();
      // }
      showSnackbar(message, "success");
      onClose();
    } catch (error: any) {
      const message = error?.response?.data?.message || "An error occurred";

      showSnackbar(message, "error");
    }
  };

  return (
    <BasicModal
      onClose={onClose}
      formContext={formContext}
      onSubmit={handleSubmit}
      title={!!user ? "Update User" : "Add User"}
      content={
        <Box>
          <Grid
            container
            spacing={2}
            sx={{
              "& .outlined .MuiOutlinedInput-notchedOutline": {
                border: "2px solid #AFD2F0 !important",
              },
              "& .changeRadius .MuiOutlinedInput-notchedOutline": {
                borderRadius: "12px !important",
              },
            }}
          >
            <Grid item md={6} xs={12}>
              <MuiTextField
                fullWidth
                label="First Name"
                name="firstName"
                required
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <MuiTextField fullWidth label="Middle Name" name="middleName" />
            </Grid>
            <Grid item md={6} xs={12}>
              <MuiTextField
                fullWidth
                label="Last Name"
                name="lastName"
                required
              />
            </Grid>
            {/* <Grid item md={6} xs={12}>
              <MuiTextField
                fullWidth
                label="Username"
                name="userName"
                required
              />
            </Grid> */}
            <Grid item md={6} xs={12}>
              <MuiTextField fullWidth label="Email" name="email" required />
            </Grid>
            <Grid item md={6} xs={12}>
              <Select
                label="Role"
                name="role"
                options={
                  !!user
                    ? ROLE
                    : [
                        { label: "Lawyer", value: "LAWYER" },
                        { label: "Staff", value: "STAFF" },
                      ]
                }
                fullWidth
                disabled={!!user ? true : false}
                required
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <PhoneNumber
                fullWidth
                label="Mobile Number"
                name="mobileNumber"
                className="changeRadius"
                required
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <PhoneNumber
                fullWidth
                label="Phone Number"
                name="phoneNumber"
                className="changeRadius"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Stack direction={"row"} alignItems={"center"} ml={1}>
                <Typography>Is Active ?</Typography>{" "}
                <Checkbox name="isActive" />
              </Stack>
            </Grid>
            <Grid item md={12} xs={12}>
              {/* <Grid
                container
                p={1}
                sx={{ border: "1px solid lightgrey", borderRadius: 2 }}
              >
                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Access Settings
                  </Typography>{" "}
                </Grid>
                <Grid item md={6}>
                  <Stack direction={"row"} alignItems={"center"}>
                    <Checkbox name="canChangeEmail" />
                    <Typography variant="subtitle1">
                      Can change email.
                    </Typography>{" "}
                  </Stack>
                </Grid>
                <Grid item md={6}>
                  <Stack direction={"row"} alignItems={"center"}>
                    <Checkbox name="canChangeMobileNumber" />
                    <Typography>Can change mobile number.</Typography>
                  </Stack>
                </Grid>
                <Grid item md={6}>
                  <Stack direction={"row"} alignItems={"center"}>
                    <Checkbox name="canChangePassword" />
                    <Typography>Can change password.</Typography>
                  </Stack>
                </Grid>
                <Grid item md={6}>
                  <Stack direction={"row"} alignItems={"center"}>
                    <Checkbox name="canChangePhoneNumber" />
                    <Typography variant="subtitle1">
                      Can change phone number.
                    </Typography>
                  </Stack>
                </Grid>
              </Grid> */}
            </Grid>
          </Grid>
        </Box>
      }
      actions={
        <>
          <Button
            variant="contained"
            color="success"
            sx={{ textTransform: "capitalize" }}
            type="submit"
            disabled={!!user ? isUpdatingUser : isAddingUser}
          >
            {!!user ? "Update" : "Add"}
          </Button>
          <Button
            color="error"
            variant="outlined"
            sx={{ textTransform: "capitalize" }}
            onClick={() => onClose()}
          >
            Cancel
          </Button>
        </>
      }
      {...props}
    />
  );
};

export default AddUserModal;
