import { Box, Button, DialogProps, Grid } from "@mui/material";
import BasicModal from "../../components/modal/BasicModal";
import { useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import useCaseNotes from "../../../hooks/useCaseNote";
import MuiTextField from "../../components/inputs/MuiTextField";
import { useSnackbarHelper } from "../../components/Snackbar";

interface Props extends DialogProps {
  onClose: () => void;

  data?: any;
  refetchCaseNotes?: any;
}

function AddCaseNoteModal({
  onClose,
  data,
  refetchCaseNotes,
  ...props
}: Props) {
  // Get ID from URL
  // const param = useParams();
  const caseNoteId = data?._id || "";
  const caseId = window.location.pathname.split("/").pop();
  const { user } = useSelector((state: any) => state.user);
  const { addCaseNotes, editCaseNotes, isAddingNotes } = useCaseNotes({});
  const initialValues = useMemo(
    () => ({
      note: data?.note || "",
    }),
    [data]
  );

  const validationSchema: any = useMemo(
    () => Yup.object({ note: Yup.string().required("Required") }),
    []
  );
  const showSnackbar = useSnackbarHelper();
  const formContext = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });
  const handleSubmit = async (values?: any) => {
    values.createdBy = user?.userId;
    values.caseId = caseId;
    try {
      const response = data
        ? await editCaseNotes({ id: caseNoteId, payload: values })
        : await addCaseNotes(values);
      const message = response?.message || "Success";

      showSnackbar(message, "success");
      onClose();
      refetchCaseNotes();
    } catch (error: any) {
      showSnackbar(error?.response?.data?.message, "error");
    }
  };

  return (
    <BasicModal
      formContext={formContext}
      onClose={onClose}
      onSubmit={handleSubmit}
      title={data ? "Update Case Note" : "Add Case Note"}
      content={
        <Box>
          <Grid container>
            <Grid item xs={12}>
              <MuiTextField
                name="note"
                label="Note"
                fullWidth
                required
                multiline
                rows={4}
              />
            </Grid>
          </Grid>
        </Box>
      }
      actions={
        <>
          <Button
            color="error"
            variant="outlined"
            onClick={onClose}
            sx={{ textTransform: "capitalize" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            sx={{ textTransform: "capitalize" }}
            type="submit"
            disabled={isAddingNotes}
          >
            {!!data ? "Update" : "Add"}
          </Button>
        </>
      }
      {...props}
    />
  );
}

export default AddCaseNoteModal;
