import { ReactNode, createContext, useEffect, useState } from "react";
import { Socket, io } from "socket.io-client";
import useAuth from "../hooks/useAuth";

// Define the context type
interface SocketContextType {
  socket: Socket | null;
}

// Create a SocketContext to hold the socket instance
export const SocketContext = createContext<SocketContextType | undefined>(
  undefined
);

// Define the provider component
interface SocketProviderProps {
  children: ReactNode;
}

export const SocketProvider = ({ children }: SocketProviderProps) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const { userId } = useAuth();

  useEffect(() => {
    const socketInstance = io(`${process.env.REACT_APP_API_URL}`);
    setSocket(socketInstance);

    // Listen for connection
    socketInstance.on("connect", () => {
      console.log("socket connected");
      if (userId) {
        socketInstance.emit("setup", userId);
      }
    });

    // Listen for disconnection
    socketInstance.on("disconnect", () => {
      console.log("Disconnected from server");
    });

    // Optional: Listen for errors
    socketInstance.on("error", (error) => {
      console.error("Socket error:", error);
    });

    // Cleanup the socket connection and listeners
    return () => {
      socketInstance.off("connect");
      socketInstance.off("disconnect");
      socketInstance.off("error");
      socketInstance.disconnect();
    };
  }, [userId]);

  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
};
