import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, DialogProps, Grid } from "@mui/material";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import useUser from "../../../hooks/useUser";
import { User } from "../../../types/users";
import BasicModal from "../../components/modal/BasicModal";
import Password from "../../components/Password";
import { useSnackbarHelper } from "../../components/Snackbar";

// import io from "socket.io-client";
// import { useSocket } from "../../../hooks/useSocket";
interface Props extends DialogProps {
  onClose: () => void;
  user1: User;
}

const ChangePasswordModal = ({ onClose, user1, ...props }: Props) => {
  const showSnackbar = useSnackbarHelper();
  const { changePassword, isChangingPassword } = useUser(user1?._id);
  const initialValues = useMemo(
    () => ({ newPassword: "", confirmPassword: "" }),
    []
  );

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .max(255)
      .required("Required")
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Password must contain at least 8 characters, one uppercase, one number and one special case character"
      ),
    confirmPassword: Yup.string()
      .required("Required")
      .oneOf([Yup.ref("newPassword")], "Passwords must match"),
  });

  const formContext = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const handleSubmit = async (values?: any) => {
    try {
      const data = await changePassword({ newPassword: values?.newPassword });

      const message = data?.message || "Success";
      // handleUserLogOut();
      showSnackbar(message, "success");
      onClose();
    } catch (error: any) {
      const message = error?.response?.data?.message || "An error occurred";

      showSnackbar(message, "error");
    }
  };

  return (
    <BasicModal
      onClose={onClose}
      formContext={formContext}
      onSubmit={handleSubmit}
      title={"Change Password"}
      content={
        <Box>
          <Grid container justifyContent="center" spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12}>
              <Password
                name="newPassword"
                label="New Password"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Password
                name="confirmPassword"
                label="Confirm Password"
                required
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
      }
      actions={
        <>
          <Button
            variant="contained"
            color="success"
            sx={{ textTransform: "capitalize" }}
            type="submit"
            disabled={isChangingPassword}
          >
            Update
          </Button>
          <Button
            color="error"
            variant="outlined"
            sx={{ textTransform: "capitalize" }}
            onClick={() => onClose()}
          >
            Cancel
          </Button>
        </>
      }
      {...props}
    />
  );
};

export default ChangePasswordModal;
