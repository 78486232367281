import React from "react";
import { Box, Avatar, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface CustomSnackbarProps {
  avatarSrc: string;
  title: string;
  body: string;
  bgcolor: string;
  onClose: () => void;
}

// Wrap the CustomSnackbar component with React.forwardRef
const CustomSnackbar = React.forwardRef<HTMLDivElement, CustomSnackbarProps>(
  ({ avatarSrc, title, body, bgcolor, onClose }, ref) => {
    return (
      <Box
        ref={ref} // Forward the ref here
        display="flex"
        alignItems="center"
        p={1}
        sx={{
          backgroundColor: bgcolor,
          color: "white",
          //   boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
          borderRadius: 1,
          width: "308px",
        }}
      >
        <Avatar
          alt="User Avatar"
          src={avatarSrc}
          sx={{ mr: 2, backgroundColor: "white" }}
        />
        <Box flex={1}>
          <Typography variant="h6">{title}</Typography>
          <Typography variant="body2">{body}</Typography>
        </Box>
        <IconButton
          size="small"
          color="inherit"
          onClick={onClose}
          sx={{ position: "absolute", top: 0, right: 0, p: 0.5 }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    );
  }
);

export default CustomSnackbar;
