import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyByTLuWyDmozNqMBCQpZURkgWgDcRMtxkY",
  authDomain: "coxlaw-3d309.firebaseapp.com",
  projectId: "coxlaw-3d309",
  storageBucket: "coxlaw-3d309.firebasestorage.app",
  messagingSenderId: "530481544143",
  appId: "1:530481544143:web:03b5a278204fd16db8f0ba",
};

const vapidKey =
  "BOXdY_IIVYntgp2MICfgKATrBBi5602stEcQ90cfLeqcRgS_E5Eoev87t7gA9gg5gsNh4BtNUnQTJqAlryyLZ8U";
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestFCMToken = async () => {
  return Notification.requestPermission()
    .then((permission) => {
      if (permission === "granted") {
        return getToken(messaging, { vapidKey });
      } else {
        // throw new Error("Notification not granteed");
        console.warn(
          "Notification permission denied. Proceeding without FCM token."
        );
        return "";
      }
    })
    .catch((error) => {
      console.error("Error getting in the FCM token", error);
      throw error;
    });
};

// Create a reusable onMessage listener
export const onMessageListener = (callback: (payload: any) => void) => {
  const messaging = getMessaging();

  const unsubscribe = onMessage(messaging, (payload) => {
    callback(payload);
  });

  return unsubscribe;
};
