import styled from "@emotion/styled";
import { Box, Button, DialogProps, Grid, TextField } from "@mui/material";
import moment from "moment";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import usePayment from "../../../hooks/usePayment";
import MuiTextField from "../../components/inputs/MuiTextField";
import Select from "../../components/inputs/Select";
import BasicModal from "../../components/modal/BasicModal";
import { useSnackbarHelper } from "../../components/Snackbar";
import BasicDatePicker from "../../components/inputs/BasicDatePicker";

interface Props extends DialogProps {
  onClose: () => void;
  caseId?: any;
  data?: any;
  contactName?: string;
  payment_spread?: any;
  clientEmail?: string;
  clientZip?: string;
  refetchPayment: () => void;
}

const CustomTextField = styled(TextField)({
  "& .MuiInputBase-input": {
    "&::-webkit-scrollbar": {
      width: "8px", // Change this value to adjust the scrollbar width
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  },
});
function AddPaymentsModal({
  onClose,
  caseId,
  data,
  contactName,
  clientEmail,
  clientZip,
  payment_spread,
  refetchPayment,
  ...props
}: Props) {
  const { updateScheduling, isUpdatingPayment } = usePayment(caseId?.id);
  const showSnackbar = useSnackbarHelper();

  const initialValues = useMemo(
    () => ({
      dueDate: moment(payment_spread?.dueDate).format("MM/DD/YYYY") || "",
      paymentMadeDate: moment(Date.now()),
      paidBy: contactName || "",
      type: data?.type || "",
      amount: payment_spread?.payment || "",
      notes: "",
      chequeNumber: null,
    }),
    [data, payment_spread, contactName]
  );

  const validationSchema = Yup.object().shape({
    dueDate: Yup.date().required("Required"),
    paymentMadeDate: Yup.date().required("Required"),
    paidBy: Yup.string(),
    type: Yup.string().required("Required"),
    amount: Yup.string().required("Required"),
    notes: Yup.string(),
    chequeNumber: Yup.string().when("type", (type, schema) => {
      return type?.[0] === "cheque"
        ? schema.required("Required")
        : schema.notRequired().nullable();
    }),
  });

  const formContext = useForm({
    defaultValues: initialValues,
    resolver: yupResolver<any>(validationSchema),
  });
  const { watch } = formContext;
  const type = watch("type");
  const handleSubmit = async (values?: any) => {
    try {
      const paymentScheduling = {
        isPaid: true,
        paymentSpreadId: payment_spread?._id,
        date: values?.dueDate || "",
        paymentMadeDate: values?.paymentMadeDate || Date.now(),
        paidBy: values?.paidBy || "",
        type: values?.type || "",
        amount: values?.amount || "",
        notes: values?.notes || "",
        feeQuoted: data?.feeQuoted || "",
        totalPaidAmount: data?.totalPaidAmount || 0,
        customerId: data?.customerId,
        journalEntryId: data?.journalEntryId,
        chequeNumber: values?.chequeNumber || null,
        clientEmail: clientEmail,
        clientName: contactName,
        clientZip: clientZip,
      };
      const response = await updateScheduling({
        schedulingId: data?._id,
        payload: paymentScheduling,
      });
      // const response = await deleteScheduling(id);
      if (response?.status === 200) {
        const message = "Payment Scheduling Updated successfully";
        showSnackbar(message, "success");
      }
      onClose();
      refetchPayment();
    } catch (error: any) {
      const message = error?.response?.data?.message || "An error occurred";
      showSnackbar(message, "error");
      onClose();
    }
  };
  return (
    <BasicModal
      formContext={formContext}
      onClose={onClose}
      onSubmit={handleSubmit}
      title={"Payment Made"}
      content={
        <Box>
          <Grid
            container
            spacing={2}
            sx={{
              "& .outlined .MuiOutlinedInput-notchedOutline": {
                border: "2px solid #AFD2F0 !important",
              },
            }}
          >
            <Grid item sm={6}>
              <MuiTextField
                fullWidth
                label="Due Date"
                name="dueDate"
                required
                disabled={true}
              />
            </Grid>
            <Grid item sm={6}>
              <BasicDatePicker
                label="Payment made Date"
                name="paymentMadeDate"
                required
              />
            </Grid>
            <Grid item sm={6}>
              <MuiTextField fullWidth label="Paid By" name="paidBy" />
            </Grid>
            <Grid item sm={6}>
              <Select
                label="Type"
                disabled
                name="type"
                options={[
                  { label: "Credit Card", value: "creditcard" },
                  { label: "Cheque", value: "cheque" },
                  { label: "cash", value: "cash" },
                  { label: "Money Order", value: "moneyOrder" },
                  { label: "Paypal", value: "paypal" },
                  { label: "Venmo", value: "venmo" },
                  { label: "Law pay", value: "lawpay" },
                  { label: "Cash App", value: "cashapp" },
                  { label: "Refund", value: "refund" },
                  { label: "Other", value: "other" },
                  { label: "Edit", value: "edit" },
                ]}
                fullWidth
              />
            </Grid>
            <Grid item sm={6}>
              <MuiTextField
                fullWidth
                label="Amount"
                name="amount"
                required
                disabled
              />
            </Grid>
            {type === "cheque" && (
              <Grid item sm={6}>
                <MuiTextField
                  fullWidth
                  label="Cheque Number"
                  name="chequeNumber"
                  required={type === "cheque" ? true : false}
                />
              </Grid>
            )}
            <Grid item sm={12}>
              {/* <TextField multiline name="notes" label="Notes" rows={2} /> */}
              {/* <Controller
                name="notes"
                control={formContext.control}
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    name="notes"
                    label="Details"
                    fullWidth
                    multiline
                    rows={2}
                    InputProps={{
                      style: {
                        borderRadius: "12px",
                      },
                      classes: {
                        input: "custom-scrollbar", // Apply the custom scrollbar class here
                      },
                    }}
                  />
                )}
              /> */}
              <MuiTextField
                name="notes"
                label="Details"
                fullWidth
                rows={2}
                multiline
              />
            </Grid>
          </Grid>
        </Box>
      }
      actions={
        <>
          <Button
            color="error"
            variant="outlined"
            onClick={onClose}
            disabled={isUpdatingPayment}
            sx={{ textTransform: "none" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            sx={{ textTransform: "capitalize" }}
            type="submit"
            disabled={isUpdatingPayment}
          >
            Mark as Paid
          </Button>
        </>
      }
      {...props}
    />
  );
}

export default AddPaymentsModal;
