import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Chip,
  DialogProps,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { debounce } from "lodash";
import { useCallback, useMemo, useRef, useState } from "react";
import { ROLE } from "../../../constants/MenuSelection";
import useAuth from "../../../hooks/useAuth";
import useChat from "../../../hooks/useChat";
import useUser from "../../../hooks/useUser";
import { useSnackbarHelper } from "../../components/Snackbar";
import SearchField from "../../components/inputs/SearchField";
import BasicModal from "../../components/modal/BasicModal";
import TableSkeleton from "../../components/skeleton/TableSkeleton";

interface Props extends DialogProps {
  onClose: () => void;
  id?: string;
  caseData?: any;
  setSelectedContact: any;
}

const CreateChatModal = ({
  onClose,
  caseData,
  setSelectedContact,
  ...props
}: Props) => {
  const [selectedUser, setSelectedUser] = useState<any | null>(null);
  const [search, setSearch] = useState<string>("");

  const { users, isLoadingUsers } = useChat(
    undefined,
    undefined,
    search,
    caseData?._id
  );

  const { createChat, isCreatingChat } = useChat();
  const { userId } = useAuth();
  const { user } = useUser(userId);
  const showSnackbar = useSnackbarHelper();

  const caseId = useMemo(() => caseData?._id, [caseData]);

  // Add debounce to hold render events
  const debouncedSetSearch = useRef(
    debounce((value) => setSearch(value), 400)
  ).current;

  const handleSearchChange = useCallback(
    (event: any) => {
      debouncedSetSearch(event.target.value.trim());
    },
    [debouncedSetSearch]
  );

  const handleCreateChat = async () => {
    try {
      const isSupportUser =
        selectedUser?.role === "SUPPORT_CONTACT" ||
        selectedUser?.role === "ADDITIONAL_CONTACT";
      const payload = {
        senderId: user?._id,
        receiverId: selectedUser?._id,
        caseId: isSupportUser ? caseId || selectedUser?.caseId?._id : null,
      };
      let response = await createChat(payload);
      setSelectedContact(response?.chat);

      const message = response?.message || "Success";
      showSnackbar(message, "success");
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <BasicModal
      onClose={onClose}
      title="New Chat"
      content={
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} p={2}>
              <SearchField
                fullWidth
                placeholder="Search by name, role or case Id"
                onChange={handleSearchChange}
              />
              {!!selectedUser && (
                <Box mt={1} ml={2}>
                  <Chip
                    // label={`${selectedUser.name} (${selectedUser.caseUniqueID})`}
                    label={` ${selectedUser?.firstName} ${selectedUser?.lastName}
                    ${
                      selectedUser?.caseId?.caseId
                        ? " (" +
                          [
                            { label: "Lawyer", value: "LAWYER" },
                            { label: "Staff", value: "STAFF" },
                            {
                              label: `Support-${selectedUser?.caseId?.caseId}`,
                              value: "SUPPORT_CONTACT",
                            },
                            {
                              label: `Additional-${selectedUser?.caseId?.caseId}`,
                              value: "ADDITIONAL_CONTACT",
                            },
                            {
                              label: "Admin",
                              value: "ADMIN",
                            },
                          ]?.find(
                            (role: any) => role?.value === selectedUser?.role
                          )?.label +
                          ")"
                        : " (" +
                          ROLE?.find(
                            (role) => role?.value === selectedUser?.role
                          )?.label +
                          ")"
                    }`}
                    color="info"
                    onDelete={() => setSelectedUser(null)}
                  />
                </Box>
              )}
            </Grid>
            <List
              sx={{
                bgcolor: "white",
                height: window.innerWidth < 1420 ? 220 : 320,
                width: "100%",
                overflowY: "auto",
              }}
            >
              {isLoadingUsers ? (
                <TableSkeleton />
              ) : (
                <Box>
                  {users?.length > 0 ? (
                    <>
                      {users?.map((user: any, index: number) => (
                        <ListItem
                          key={index}
                          component={ButtonBase}
                          onClick={() => setSelectedUser(user)}
                          sx={{
                            height: 60,
                            borderBottom: "1px solid #ddd",
                            padding: 1,
                            bgcolor: "white",
                            backgroundColor:
                              selectedUser?._id === user?._id
                                ? "	#E8E8E8"
                                : "none",
                            "&:hover": {
                              backgroundColor: "	#E8E8E8",
                            },
                          }}
                        >
                          <ListItemAvatar>
                            <Avatar
                              src={
                                user?.profileImg
                                  ? user?.profileImg
                                  : "/path/to/avatar1.png"
                              }
                            />
                          </ListItemAvatar>

                          {/* Badge to show message count */}
                          <ListItemText
                            primary={
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                {user?.firstName} {user?.lastName}
                                {""}
                                {user?.caseId?.caseId &&
                                  " (" + user?.caseId?.caseId + ")"}
                              </Box>
                            }
                            secondary={
                              ROLE?.find((role) => role?.value === user?.role)
                                ?.label
                            }
                          />
                        </ListItem>
                      ))}
                    </>
                  ) : (
                    <Box
                      sx={{
                        height: 220,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography>No contacts found</Typography>
                    </Box>
                  )}
                </Box>
              )}
            </List>
          </Grid>
        </>
      }
      actions={
        <>
          <Button
            variant="outlined"
            color="error"
            sx={{ textTransform: "capitalize" }}
            onClick={() => onClose()}
            disabled={isCreatingChat}
          >
            Cancel
          </Button>
          <Button
            color="success"
            variant="contained"
            sx={{ textTransform: "capitalize" }}
            onClick={handleCreateChat}
            disabled={!selectedUser || isCreatingChat}
          >
            Create
          </Button>
        </>
      }
      {...props}
    />
  );
};

export default CreateChatModal;
