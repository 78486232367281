import { Box, Button, DialogProps } from "@mui/material";

import useCases from "../../../hooks/useCases";
import useContacts from "../../../hooks/useContacts";
import BasicModal from "../../components/modal/BasicModal";
import { useSnackbarHelper } from "../../components/Snackbar";

interface Props extends DialogProps {
  onClose: () => void;

  data?: any;
  contactId: string;
  caseId: any;
}

function ConfirmationModal({
  onClose,
  data,
  contactId,
  caseId,
  ...props
}: Props) {
  const showSnackbar = useSnackbarHelper();
  const { updateContact, updateLoading } = useContacts();
  const { refetchCase } = useCases(caseId);
  const getPayload = (values?: any) => {
    const phone = data?.phone?.map((d: any) => ({
      phone: d?.phone,
      phoneType: d?.phoneType,
    }));
    const email = data?.email?.map((d: any) => ({
      email: d?.email,
      emailType: d?.emailType,
    }));
    return {
      firstName: data?.firstName,
      lastName: data?.lastName,
      address: data?.address,
      country: data?.country,
      state: data?.state,
      city: data?.city,
      zip: data?.zip || "",
      mi: data?.mi || "",
      suffix: data?.suffix || "",
      nickName: data?.nickName || "",
      email: email,
      phone: phone,
      inmate: data?.inmate?._id || undefined,
      isDeleted: data?.isDeleted,
      note: data?.note || "",
      sid: data?.sid || "",
      tdcj: data?.tdcj || "",
      unit: data?.unit || "",
      inmate_dob: data?.inmate_dob || null,
      unit_location: data?.unit_location || "",
      createdBy: data?.createdBy,
      additionalSupportContact: {
        firstName: "",
        lastName: "",
        address: "",
        country: "",
        state: "",
        city: "",
        zip: "",
        mi: "",
        suffix: "",
        nickName: "",
        email: [{ email: "", emailType: "" }],
        phone: [{ phone: "", phoneType: "" }],
      },
    };
  };

  const handleSubmit = async (values?: any) => {
    const payload = getPayload(values);
    try {
      const data = await updateContact({ id: contactId, payload: payload });

      const message = "Contact deleted successfully";
      refetchCase();
      showSnackbar(message, "success");
      onClose();
    } catch (error: any) {
      const message = error?.response?.data?.message || "An error occurred";

      showSnackbar(message, "error");
    }
  };
  return (
    <BasicModal
      onClose={onClose}
      onSubmit={handleSubmit}
      title={"Delete Additional Contact"}
      content={
        <Box>Do you want to delete this additional support contact ?</Box>
      }
      actions={
        <>
          <Button
            variant="contained"
            color="error"
            sx={{ textTransform: "capitalize" }}
            type="submit"
            disabled={updateLoading}
          >
            Delete
          </Button>
          <Button
            color="error"
            variant="outlined"
            sx={{ textTransform: "capitalize" }}
            onClick={() => onClose()}
          >
            Cancel
          </Button>
        </>
      }
      {...props}
    />
  );
}

export default ConfirmationModal;
