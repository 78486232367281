import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Button, DialogProps, Grid } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

//components
import { RefferalTypes, RepType } from "../../../constants/MenuSelection";
import useAuth from "../../../hooks/useAuth";
import useCases from "../../../hooks/useCases";
import useContacts from "../../../hooks/useContacts";
import useDocuSign from "../../../hooks/useDocuSign";
import useUser from "../../../hooks/useUser";
import { contactTypes } from "../../../types/contactTypes";
import { isValidateNextParoleDateFormat } from "../../../utils/isValidateNextParoleDateFormat";
import { isValidateParoleEligibilityDate } from "../../../utils/isValidateParoleDateFormat";
import { useSnackbarHelper } from "../../components/Snackbar";
import BasicAutocomplete from "../../components/inputs/BasicAutocomplete";
import BasicDatePicker from "../../components/inputs/BasicDatePicker";
import Checkbox from "../../components/inputs/CheckBox";
import MuiTextField from "../../components/inputs/MuiTextField";
import NumberField from "../../components/inputs/NumberField";
import BasicModal from "../../components/modal/BasicModal";
import TableSkeleton from "../../components/skeleton/TableSkeleton";

interface Props extends DialogProps {
  onClose: () => void;
  data?: contactTypes;
}

function AddNewCaseModal({ onClose, data, ...props }: Props) {
  const { SendDocuSignMail, isLoadingDocuSignMail } = useDocuSign();

  const { inmates, isContactsLoading } = useContacts(
    undefined,
    undefined,
    undefined,
    true
  );

  const { userId } = useAuth();
  const { addCase, isAddCaseLoading, boardMembers } = useCases();

  const { repTypes } = useUser(userId);
  const { lawyers, staff } = useUser(
    undefined,
    undefined,
    undefined,
    undefined,
    true
  );

  const showSnackbar = useSnackbarHelper();

  const members = boardMembers?.map((option: any) => {
    return { label: option?.name, value: option?.name };
  });
  const initialValues = useMemo(
    () => ({
      contactId: null,
      paroleEligibilityDate: null,
      nextParoleEligibilityDate: null,
      caseStatus: "unretained",
      repType: "",
      feeQuoted: "",
      initialContactDate: null,
      followUpDate: null,
      retentionDate: null,
      mailType: "",
      // phoneType: "",
      lawyer: null,
      secondaryLawyer: null,
      staff: null,
      leadVoter: "",
      referralType: "",
      isExtraordinaryVoteCase: false,
      isExistingClient: false,
      referralDetails: "",
      paroleHiringDateTime: null,
    }),
    []
  );

  const validationSchema: any = useMemo(
    () =>
      Yup.object({
        contactId: Yup.object().required("Required"),
        paroleEligibilityDate: Yup.date().nullable(),
        nextParoleEligibilityDate: Yup.string().nullable(),
        caseStatus: Yup.string().required("Required"),
        repType: Yup.string().required("Required"),
        feeQuoted: Yup.string(),
        initialContactDate: Yup.date().nullable(),
        followUpDate: Yup.date().nullable(),
        retentionDate: Yup.date().nullable(),
        mailType: Yup.string().required("Required"),
        // phoneType: Yup.string().required("Required"),
        // lawyer: Yup.string().when(
        //   "caseStatus",
        //   (caseStatus: any, schema: any) => {
        //     return caseStatus?.[0] !== "active"
        //       ? schema.nullable()
        //       : schema.required("Required");
        //   }
        // ),
        lawyer: Yup.string().required("Required"),
        secondaryLawyer: Yup.string().nullable(),
        staff: Yup.string().required("Required"),
        leadVoter: Yup.string().notRequired(),
        referralType: Yup.string().nullable(),
        isExtraordinaryVoteCase: Yup.boolean(),
        isExistingClient: Yup.boolean(),
        referralDetails: Yup.string(),
        paroleHiringDateTime: Yup.date().nullable(),
      }),
    []
  );

  const formContext = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { watch, setValue } = formContext;
  // const caseStatus = watch("caseStatus");
  const repType = watch("repType");

  const contact: any = watch("contactId");
  const lawyer = watch("lawyer");
  const secondaryLawyer = watch("secondaryLawyer");

  useEffect(() => {
    //validate and set parole eligibility date
    if (
      isValidateParoleEligibilityDate(contact?.inmate?.paroleEligibilityDate)
    ) {
      setValue("paroleEligibilityDate", contact?.inmate?.paroleEligibilityDate);
    } else {
      setValue("paroleEligibilityDate", null);
    }

    //validate and set parole eligibility date
    if (
      isValidateNextParoleDateFormat(
        contact?.inmate?.paroleReviewInfo?.nextParoleDate
      )
    ) {
      setValue(
        "nextParoleEligibilityDate",
        contact?.inmate?.paroleReviewInfo?.nextParoleDate
      );
    } else {
      setValue("nextParoleEligibilityDate", null);
    }
  }, [contact, setValue]);

  useEffect(() => {
    //set feeQuoted according to fee type
    const matchingRepType = repTypes?.find(
      (rep: any) => rep?.type?.toLowerCase() === repType?.toLowerCase()
    );
    if (matchingRepType) {
      setValue("feeQuoted", matchingRepType?.value);
    }
    if (contact) {
      setValue("paroleEligibilityDate", contact?.inmate?.paroleEligibilityDate);
    }
  }, [repType, setValue, contact, repTypes]);

  useEffect(() => {
    if (lawyer) {
      setValue("caseStatus", "active");
    }
  }, [lawyer, setValue]);
  const handleDocuSignMail = async (values: any) => {
    try {
      const data = await SendDocuSignMail(values);
      // const message = data?.message || "Success";
      // showSnackbar(message, "success");
      return data;
    } catch (error: any) {
      const message = error?.response?.data?.message || "An error occurred";
      showSnackbar(message, "error");
    }
  };

  const handleSubmit = async (values: any) => {
    values.followUpDate = values?.followUpDate ? [values.followUpDate] : null;
    const { contactId, mailType, isExistingClient } = values;

    const inmateId = contactId?.inmate?._id;

    const contactName = contactId?.firstName + " " + contactId?.lastName;
    const inmateName = contactId?.inmate?.name;
    const tdcjNumber = contactId?.inmate?.tdcjNumber;
    const sidNumber = contactId?.inmate?.sidNumber;

    const contactMail = contactId?.email?.find(
      (e: any) => e?.emailType === mailType
    )?.email;

    // const contactPhone = contactId?.phone?.find(
    //   (e: any) => e?.phoneType === phoneType
    // )?.phone;

    try {
      // If the client is existing, not send the agreement
      const { envelopeId, docuSignUrl } = !isExistingClient
        ? (await handleDocuSignMail({
            contactName,
            contactMail,
            // contactPhone,
            inmateName,
            tdcjNumber,
            sidNumber,
          })) || {}
        : { envelopeId: null, docuSignUrl: null };

      if (envelopeId || (isExistingClient && envelopeId === null)) {
        // create new case
        await addCase({
          ...values,
          contactId: contactId?._id,
          inmateId,
          envelopeId,
          docuSignUrl,
          isExistingClient,
        });
        const message = !isExistingClient
          ? "The case has been added, and the agreement has been sent to support contact."
          : "Case added successfully.";
        showSnackbar(message, "success");
        onClose();
      }
    } catch (error: any) {
      const message = error?.response?.data?.message || "An error occurred";

      showSnackbar(message, "error");
    }
  };

  const capitalizeFirstLetter = (string: string) => {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }
  };
  const mailTypes = useMemo(() => {
    if (!contact?.email) return [];
    const array = contact.email.map((item: any) => {
      return {
        label: capitalizeFirstLetter(item?.emailType),
        value: item?.emailType,
      };
    });
    return array;
  }, [contact]);

  // const phoneTypes = useMemo(() => {
  //   if (!contact?.phone) return [];
  //   const array = contact.phone.map((item: any) => {
  //     return {
  //       label: capitalizeFirstLetter(item?.phoneType),
  //       value: item?.phoneType,
  //     };
  //   });
  //   return array;
  // }, [contact]);

  if (isContactsLoading) {
    return <TableSkeleton />;
  }

  return (
    <BasicModal
      formContext={formContext}
      onClose={onClose}
      onSubmit={handleSubmit}
      title={"Add Case"}
      height="calc(100vh - 230px)"
      content={
        <Grid container spacing={2} sx={{ alignItems: "center" }}>
          <Grid item xs={6}>
            <BasicAutocomplete
              label="Contacts"
              placeholder="Search contacts"
              name="contactId"
              required
              options={inmates}
              getOptionLabel={(option: any) =>
                `${option?.firstName} ${option?.lastName}`
              }
            />
          </Grid>

          <Grid item sm={6}>
            <BasicAutocomplete
              label="Mail Type"
              name="mailType"
              options={mailTypes}
              required
              getOptionValue={(option: any) => option?.value}
            />
          </Grid>
          {/* <Grid item sm={6}>
            <BasicAutocomplete
              label="Phone Type"
              name="phoneType"
              options={phoneTypes}
              required
              getOptionValue={(option: any) => option?.value}
            />
          </Grid> */}
          {/* <Grid item xs={6}>
            <BasicAutocomplete
              label="Case Status"
              name="caseStatus"
              required
              options={AddCaseFileStatus}
              getOptionValue={(option: any) => option?.value}
            />
          </Grid> */}
          <Grid item xs={6}>
            <BasicDatePicker
              label="Parole Eligibility Date"
              name="paroleEligibilityDate"
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <MuiTextField
              label="Next Parole Eligibility Date"
              name="nextParoleEligibilityDate"
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            {" "}
            <BasicAutocomplete
              label="Rep Type"
              name="repType"
              required
              options={RepType}
              getOptionValue={(option: any) => option?.value}
            />
          </Grid>
          <Grid item sm={6}>
            <NumberField
              label="Fee Quoted"
              name="feeQuoted"
              prefix="$ "
              decimalScale={2}
              fixedDecimalScale={true}
              disabled
              // options={Task}
            />
          </Grid>
          <Grid item xs={6}>
            <BasicDatePicker
              label="Initial Contact Date"
              name="initialContactDate"
            />
          </Grid>
          <Grid item xs={6}>
            <BasicDatePicker label="Follow Up Date" name="followUpDate" />
          </Grid>
          <Grid item sm={6}>
            <BasicDatePicker label="Retention Date" name="retentionDate" />
          </Grid>

          <Grid item xs={6}>
            <BasicDatePicker
              label="Parole Hiring Date"
              name="paroleHiringDateTime"
            />
          </Grid>
          <Grid item sm={6}>
            <BasicAutocomplete
              label="Lawyer"
              name="lawyer"
              options={lawyers
                ?.filter((user: any) => user?._id !== secondaryLawyer)
                ?.map((user: any) => ({
                  label: `${user.firstName} ${user.lastName}`,
                  value: user?._id,
                }))}
              getOptionValue={(option: any) => option?.value}
              required
            />
          </Grid>
          <Grid item sm={6}>
            <BasicAutocomplete
              label="Secondary Lawyer"
              name="secondaryLawyer"
              options={lawyers
                ?.filter((user: any) => user?._id !== lawyer)
                ?.map((user: any) => ({
                  label: `${user.firstName} ${user.lastName}`,
                  value: user?._id,
                }))}
              getOptionValue={(option: any) => option?.value}
            />
          </Grid>
          <Grid item xs={6}>
            <BasicAutocomplete
              label="Staff"
              name="staff"
              required
              options={staff?.map((user: any) => ({
                label: `${user.firstName} ${user.lastName}`,
                value: user?._id,
              }))}
              getOptionValue={(option: any) => option?.value}
            />
          </Grid>
          <Grid item xs={6}>
            <BasicAutocomplete
              label="Lead Voter"
              name="leadVoter"
              options={members}
              getOptionValue={(option: any) => option?.value}
            />
          </Grid>
          <Grid item xs={6}>
            <Checkbox
              name="isExtraordinaryVoteCase"
              label="Extraordinary Vote Case?"
            />
          </Grid>
          <Grid item xs={6}>
            <Checkbox
              name="isExistingClient"
              label="Is Existing Client?"
              // labelPlacement="end"
            />
          </Grid>
          <Grid item xs={12}>
            <BasicAutocomplete
              label="Referral Type"
              name="referralType"
              options={RefferalTypes}
              getOptionValue={(option: any) => option?.value}
            />
          </Grid>
          <Grid item xs={12}>
            <MuiTextField
              minRows={2}
              multiline
              label="Referral Details"
              // placeholder="Referral Details"
              name="referralDetails"
              sx={{ borderColor: "#B2BAC2" }}
              // options={Task}
              //
            />
          </Grid>
          {/* <Grid item xs={6}>
            <CustomDateTime
              label="Parole Hiring Date&Time"
              name="paroleHiringDateTime"
            />
          </Grid>
          <Grid item xs={6}>
            <BasicAutoComplete
              multiple
              label="Parole Vote Result"
              name="paroleVoteResult"
              options={PayroleVoteResult}
              getOptionValue={(option: any) => option?.value}
            />
          </Grid> */}
        </Grid>
      }
      actions={
        <Grid container spacing={2} sx={{ alignItems: "center" }}>
          <Grid item xs={7}>
            {!inmates?.length && (
              <Alert
                sx={{
                  height: 35,
                  pt: 0,
                  pb: 0,
                  overflow: "hidden",
                  lineHeight: 1,
                  alignItems: "center",
                  backgroundColor: "transparent", // Remove background color
                  boxShadow: "none", // Remove box shadow
                  border: "none", // Remove border
                  color: "#d32f2f",
                }}
                severity="error"
              >
                Add/associate a contact with its inmate.
              </Alert>
            )}
          </Grid>
          <Grid item xs={5} textAlign={"right"}>
            <Button
              color="error"
              variant="outlined"
              onClick={onClose}
              disabled={
                isAddCaseLoading || isLoadingDocuSignMail || !inmates?.length
              }
              sx={{ textTransform: "capitalize", mr: 1 }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{ textTransform: "capitalize" }}
              disabled={
                isAddCaseLoading || isLoadingDocuSignMail || !inmates?.length
              }
              type="submit"
            >
              Add
            </Button>
          </Grid>
        </Grid>
      }
      {...props}
    />
  );
}

export default AddNewCaseModal;
