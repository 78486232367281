import { Box, Grid, Stack, Typography } from "@mui/material";
import laywerLogo from "../../../assets/images/laywerLogo.png";
import AuthCardWrapper from "../../layouts/Wrappers/AuthCardWrapper";
import JWTDeleteAccount from "./JWTDeleteAccount";

// ============================|| AUTH1 - FORGOT PASSWORD ||============================ //
const DeleteAccount = () => {
  return (
    <AuthCardWrapper>
      <Grid
        container
        spacing={1}
        direction={"column"}
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          alt="The house from the offer."
          src={`${laywerLogo}`}
          width={"30%"}
          sx={{ alignSelf: "center", mb: 8 }}
        />
        <Stack sx={{ width: "50%", height: "fit-content" }} spacing={2}>
          <Grid item xs={12}>
            <Stack alignItems="flex-start" justifyContent="center" spacing={1}>
              <Typography
                // color="black"
                gutterBottom
                variant={"h2"}
              >
                Delete Your Account
              </Typography>
              <Typography variant="subtitle1" textAlign="left">
                Enter your email to receive an OTP.
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <JWTDeleteAccount />
          </Grid>
        </Stack>
      </Grid>
    </AuthCardWrapper>
  );
};
export default DeleteAccount;
