import {
  Avatar,
  Badge,
  Box,
  ButtonBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import moment from "moment";
import useChat from "../../../hooks/useChat";
import TableSkeleton from "../../components/skeleton/TableSkeleton";

// Style to show online users
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

export const formatDate = (date: string | Date, showToday?: boolean) => {
  const inputDate = moment(date);
  const today = moment();
  const yesterday = today.clone().subtract(1, "days");

  if (inputDate.isSame(today, "day")) {
    return showToday ? "Today" : inputDate.format("hh:mm A"); // Return time if it's today
  }

  if (inputDate.isSame(yesterday, "day")) {
    return "Yesterday"; // Return 'Yesterday' if it's yesterday
  }

  return inputDate.format("MM/DD/YYYY"); // Return date in MM/DD/YYYY format
};

const ContactList = ({
  caseId,
  search,
  handleSelectItem,
  selectedContact,
  onlineUsers,
}: any) => {
  const { chats, chatMessage, isLoadingChats } = useChat(
    undefined,
    undefined,
    search,
    caseId
  );

  const sliceLength =
    window?.innerWidth > 1500
      ? 40
      : window?.innerWidth > 1200
        ? 30
        : window?.innerWidth > 1000
          ? 20
          : 10;

  // useEffect(() => {
  //   if (
  //     !chats.length ||
  //     !chats?.some((chat: any) => chat._id === selectedContact?._id)
  //   ) {
  //     handleSelectItem(null);
  //   }
  // }, [chats, selectedContact, handleSelectItem]);

  return (
    <List
      sx={{
        bgcolor: "white",
        height: !!caseId ? "calc(100vh - 480px)" : "calc(100vh - 420px)",
        overflowY: "auto",
      }}
    >
      {isLoadingChats ? (
        <TableSkeleton />
      ) : (
        <Box>
          {chats?.length > 0 ? (
            <>
              {chats?.map((chat: any, index: number) => {
                const onlineUserSet = new Set(onlineUsers);
                const isAnyUserOnline =
                  chat?.receiver?.some((r: any) => onlineUserSet.has(r?._id)) ??
                  false;

                const profileImageUrl = !!chat?.isGroupChat
                  ? chat?.groupImageUrl
                  : chat?.receiver?.[0]?.profileImg || "/default-avatar.png";

                let name = chat?.isGroupChat
                  ? chat?.groupName || ""
                  : chat?.receiver?.[0]?.firstName +
                    " " +
                    chat?.receiver?.[0]?.lastName;

                if (
                  !chat?.isGroupChat &&
                  window?.innerWidth > 850 &&
                  (caseId || window.innerWidth <= 1440)
                ) {
                  name =
                    chat?.receiver?.[0]?.firstName +
                      " " +
                      chat?.receiver?.[0]?.lastName[0] || "";
                }

                return (
                  <ListItem
                    key={index}
                    component={ButtonBase}
                    onClick={() => handleSelectItem(chat)}
                    sx={{
                      borderBottom: "1px solid lightgrey",
                      bgcolor: "white",
                      backgroundColor:
                        selectedContact?._id === chat?._id ? "#cce8f1" : "none",
                      "&:hover": {
                        backgroundColor: "#e6f7ff", // A lighter shade on hover
                      },
                      padding: 2,
                      height: 70,
                      marginLeft: "-10px",
                    }}
                  >
                    {isAnyUserOnline ? (
                      <ListItemAvatar>
                        <StyledBadge
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          variant="dot"
                        >
                          <Avatar
                            src={profileImageUrl}
                            sx={{
                              width: 40,
                              height: 40,
                              border: "1px solid lightgrey",
                            }} // Set a fixed size for the avatar
                          />
                        </StyledBadge>
                      </ListItemAvatar>
                    ) : (
                      <ListItemAvatar>
                        <Avatar
                          src={profileImageUrl}
                          sx={{
                            width: 40,
                            height: 40,
                            border: "1px solid lightgrey",
                          }} // Set a fixed size for the avatar
                        />
                      </ListItemAvatar>
                    )}

                    {/* Badge to show message count */}
                    <ListItemText
                      primary={
                        <Stack spacing={0.5}>
                          <Stack
                            direction={"row"}
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Stack
                              spacing={1}
                              direction="row"
                              alignItems="center"
                            >
                              <Typography variant="body1" fontWeight="bold">
                                {window?.innerWidth > 1100
                                  ? name
                                  : chat?.isGroupChat
                                    ? chat?.groupName || ""
                                    : chat?.receiver?.[0]?.firstName + " "}
                              </Typography>
                              {window?.innerWidth > 1100 && (
                                <Typography
                                  variant="body2"
                                  fontWeight="normal"
                                  color="textSecondary"
                                >
                                  (
                                  {!!chat?.isGroupChat
                                    ? chat?.caseId?.caseId
                                    : [
                                        { label: "Lawyer", value: "LAWYER" },
                                        { label: "Staff", value: "STAFF" },
                                        {
                                          label: `Support-${chat?.receiver?.[0]?.caseId?.caseId}`,
                                          value: "SUPPORT_CONTACT",
                                        },
                                        {
                                          label: `Additional-${chat?.receiver?.[0]?.caseId?.caseId}`,
                                          value: "ADDITIONAL_CONTACT",
                                        },
                                        {
                                          label: "Admin",
                                          value: "ADMIN",
                                        },
                                      ]?.find(
                                        (role: any) =>
                                          role?.value ===
                                          chat?.receiver?.[0]?.role
                                      )?.label}
                                  )
                                </Typography>
                              )}
                            </Stack>

                            {window?.innerWidth > 850 && (
                              <Stack
                                spacing={1}
                                direction="row"
                                alignItems="center"
                              >
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  sx={{ fontSize: "12px" }}
                                >
                                  {chat?.latestMessage?.createdAt &&
                                    formatDate(chat?.latestMessage?.createdAt)}
                                </Typography>
                              </Stack>
                            )}
                          </Stack>
                          {window?.innerWidth < 1100 && (
                            <Typography
                              variant="body2"
                              fontWeight="normal"
                              sx={{ fontSize: "10px" }}
                              color="textSecondary"
                            >
                              (
                              {!!chat?.isGroupChat
                                ? chat?.caseId?.caseId
                                : [
                                    { label: "Lawyer", value: "LAWYER" },
                                    { label: "Staff", value: "STAFF" },
                                    {
                                      label: `Support-${chat?.receiver?.[0]?.caseId?.caseId}`,
                                      value: "SUPPORT_CONTACT",
                                    },
                                    {
                                      label: `Additional-${chat?.receiver?.[0]?.caseId?.caseId}`,
                                      value: "ADDITIONAL_CONTACT",
                                    },
                                    {
                                      label: "Admin",
                                      value: "ADMIN",
                                    },
                                  ]?.find(
                                    (role: any) =>
                                      role?.value === chat?.receiver?.[0]?.role
                                  )?.label}
                              )
                            </Typography>
                          )}
                        </Stack>
                      }
                      secondary={
                        <Stack mt={0.5}>
                          <Typography
                            fontStyle={chat?.count ? "italic" : "normal"}
                            color="textSecondary"
                          >
                            {chat?.latestMessage?.message?.length > sliceLength
                              ? `${chat?.latestMessage?.message?.slice(0, sliceLength)}...`
                              : chat?.latestMessage?.message
                                ? chat?.latestMessage?.message
                                : chat?.latestMessage?.attachments?.length > 0
                                  ? chat?.latestMessage?.attachments[
                                      chat.latestMessage.attachments.length - 1
                                    ]?.name?.length > sliceLength
                                    ? `${chat?.latestMessage?.attachments[
                                        chat.latestMessage.attachments.length -
                                          1
                                      ]?.name?.slice(0, sliceLength)}...`
                                    : chat?.latestMessage?.attachments[
                                        chat.latestMessage.attachments.length -
                                          1
                                      ]?.name
                                  : "No message"}
                          </Typography>
                          <Badge
                            badgeContent={chat?.count || 0}
                            color="success"
                            sx={{
                              mr: 1,
                              fontSize: "14px", // Ensure badge text is legible
                              minWidth: "20px", // Prevent badge from shrinking too small
                            }}
                          />
                        </Stack>
                      }
                      disableTypography
                    />
                  </ListItem>
                );
              })}
            </>
          ) : (
            <Stack
              sx={{
                justifyContent: "center",
                height: caseId ? "calc(100vh - 480px)" : "calc(100vh - 420px)",
              }}
            >
              <Typography textAlign={"center"}>{chatMessage}</Typography>
            </Stack>
          )}
        </Box>
      )}
    </List>
  );
};

export default ContactList;
