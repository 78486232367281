import Menu from "@mui/material/Menu";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import ProfileSection from "../layouts/Header/ProfileSection";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useModal } from "mui-modal-provider";
import LogoutModal from "../pages/authentication/logout";
import useAuth from "../../hooks/useAuth";
import useUser from "../../hooks/useUser";

export default function BasicMenu({
  screenWidth,
  user,
  changeTabs,
  activeTab,
}: any) {
  const { userId } = useAuth();
  const { isAdmin } = useUser(userId);
  const [rotated, setRotated] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setRotated((prev) => !prev);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Use effect to override any unwanted style changes
  useEffect(() => {
    const handleBodyOverflow = () => {
      if (document.body.style.overflow === "hidden") {
        document.body.style.overflow = "auto";
      }
    };

    window.addEventListener("click", handleBodyOverflow);
    return () => {
      window.removeEventListener("click", handleBodyOverflow);
    };
  }, []);

  const MenuItemsAdmin = useMemo(
    () =>
      screenWidth < 1270
        ? [
            { label: "Cases", url: "/cases" },
            { label: "Contacts", url: "/contacts" },
            { label: "Prospects", url: "/prospects" },
            { label: "Time Entry", url: "/time-entry" },
            { label: "Payments", url: "/payments" },
            { label: "DocuSign", url: "/docusign-waiting" },
            { label: "Messages", url: "/sms" },
            { label: "Account Settings", url: "/settings" },
            { label: "Users", url: "/users" },
          ]
        : [
            { label: "Account Settings", url: "/settings" },
            { label: "Users", url: "/users" },
          ],
    [screenWidth]
  );

  const MenuItemsOthers = useMemo(
    () =>
      screenWidth < 1270
        ? [
            { label: "Cases", url: "/cases" },
            { label: "Contacts", url: "/contacts" },
            { label: "Prospects", url: "/prospects" },
            { label: "Time Entry", url: "/time-entry" },
            { label: "Payments", url: "/payments" },
            { label: "DocuSign", url: "/docusign-waiting" },
            { label: "Messages", url: "/sms" },
            { label: "Account Settings", url: "/settings" },
            // { label: "Change Password", url: "/change-password" },
          ]
        : [{ label: "Account Settings", url: "/settings" }],
    [screenWidth]
  );

  return (
    <Box>
      <IconButton
        disableRipple
        sx={{ color: "#E3001B", p: 1 }}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <ProfileSection user={user} rotated={rotated} />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <IconMenu
          MenuItems={isAdmin ? MenuItemsAdmin : MenuItemsOthers}
          handleClose={handleClose}
          changeTabs={changeTabs}
          activeTab={activeTab}
        />
      </Menu>
    </Box>
  );
}

function IconMenu({ MenuItems, handleClose, changeTabs, activeTab }: any) {
  const navigate = useNavigate();
  const { showModal } = useModal();

  const handleLogout = useCallback(() => {
    const modal: any = showModal(LogoutModal, {
      onClose: () => modal.hide(),
    });
  }, [showModal]);

  return (
    <MenuList>
      {MenuItems.map((item: any, i: number) => (
        <MenuItem key={i}>
          <ListItemText
            onClick={() => {
              navigate(item?.url);
              changeTabs(item?.url);
              handleClose();
            }}
          >
            <Typography
              sx={{
                color: activeTab === item?.url ? "#008EBB" : "#364152",
                textDecoration: "none",
                fontSize: "16px",
                lineHeight: "21px",
                fontFamily: "'Poppins', sans-serif",
                fontWeight: "500",
              }}
            >
              {item.label}
            </Typography>
          </ListItemText>
        </MenuItem>
      ))}
      <MenuItem>
        <ListItemText onClick={handleLogout}>
          <Typography
            sx={{
              textDecoration: "none",
              fontSize: "16px",
              lineHeight: "21px",
              fontFamily: "'Poppins', sans-serif",
              fontWeight: "500",
              color: "#364152",
            }}
          >
            Logout
          </Typography>
        </ListItemText>
      </MenuItem>
    </MenuList>
  );
}
