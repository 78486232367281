import {
  Autocomplete,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  GridActionsCellItem,
  GridColDef,
  GridColumnHeaderParams,
  GridPaginationModel,
  GridSortModel,
} from "@mui/x-data-grid";
import { debounce } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import MainCard from "../../components/cards/MainCard";
import DataGridTable from "../../components/DataGridTable";
import SettingsIcon from "@mui/icons-material/Settings";
import useLeads from "../../../hooks/useLeads";
import SearchField from "../../components/inputs/SearchField";
import { searchLeads } from "../../../utils/searchLeads";
import useSite from "../../../hooks/useSite";
import NoResultFound from "../../components/NoResultFound";
import { Link } from "react-router-dom";
import SyncIcon from "@mui/icons-material/Sync";
import DetailOfInteractionModal from "./DetailOfInteractionModal";
import { useModal } from "mui-modal-provider";
import ConvertLeadToContact from "./ConvertLeadToContact";
import useAuth from "../../../hooks/useAuth";
import { IconEye } from "@tabler/icons-react";
import ViewDetailOfInteractionModal from "./ViewDetailOfInteractionModal";
import MarkAsColdLead from "./MarkAsColdLead";
import TableSkeleton from "../../components/skeleton/TableSkeleton";

const leadsFilterOptions = [
  { label: "Show Active", value: "active" },
  {
    label: "Show Converted",
    value: "converted",
  },

  {
    label: "Show Cold Leads",
    value: "coldLeads",
  },
];
const Prospects = () => {
  const [currentValue, setCurrentValue] = useState(null); // Add this state variable to hold the current value
  const [rotated, setRotated] = useState(false);
  // const { request } = useAuth();
  const { showModal } = useModal();
  const { site, isSiteLoading } = useSite();
  const [anchorEl, setAnchorEl] = useState(null);
  const { userId } = useAuth();
  const [search, setSearch] = useState("");
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "created_time", sort: "desc" },
  ]);
  const [selectedValue, setSelectedValue] = useState(leadsFilterOptions[0]);

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 12,
  });

  const open = Boolean(anchorEl);

  const handleClick = (event: any, value: any) => {
    setAnchorEl(event.currentTarget);
    setCurrentValue(value);
  };

  useEffect(() => {
    const handleTabBlur = () => {
      // Close the menu when the tab loses focus
      handleClose();
    };
    // Attach event listeners for blur and focus
    window.addEventListener("blur", handleTabBlur);

    // Cleanup the event listeners on component unmount
    return () => {
      window.removeEventListener("blur", handleTabBlur);
    };
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    leads,
    inLoadingLeads,
    refetchLeads,
    isRefetchingLeads,
    convertLead,
    isConvertingLead,
    AddDetailOfInteraction,
    isAddingDetail,
    prospects,
  } = useLeads(
    !!site?.zoho?.accessToken,
    selectedValue?.value === "converted" ? true : false,
    selectedValue?.value === "coldLeads" ? true : false
  );

  const columns: GridColDef[] = [
    {
      field: "firstName",
      headerName: "Lead Name",
      flex: 1,
      minWidth: 200,
      renderCell: (params: any) => {
        return `${params?.row?.firstName}`;
      },
      renderHeader: (params: any) => params?.colDef?.headerName ?? "-",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 260,
      renderCell: (params: any) => params?.row?.email || "-",
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    {
      field: "mobile",
      headerName: "Mobile",
      minWidth: 120,
      renderCell: (params: any) => params?.row?.mobile || "-",
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    {
      field: "phoneNumber",
      headerName: "Phone",
      minWidth: 120,
      renderCell: (params: any) => params?.row?.phoneNumber || "-",
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    {
      field: "leadSource",
      headerName: "Lead Source",
      minWidth: 160,
      renderCell: (params: any) => params?.row?.leadSource || "-",
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    {
      field: "owner",
      headerName: "Owner",
      flex: 1,
      minWidth: 200,
      renderCell: (params: any) => params?.row?.owner || "-",
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    {
      field: "created_time",
      headerName: "Created Time",
      minWidth: 200,
      renderCell: (params: any) =>
        moment(params?.row?.created_time).format("MM/DD/YYYY, h:mm A"),
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    {
      field: "modified_time",
      headerName: "Modified Time",
      minWidth: 200,
      renderCell: (params: any) =>
        moment(params?.row?.modified_time).format("MM/DD/YYYY, h:mm A"),
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    {
      field: "isContacted",
      headerName: "Status ",
      flex: 1,
      minWidth: 150,
      renderCell: (params: any) => {
        return params?.row?.isContacted ? (
          <Typography sx={{ color: "green" }}>Contacted</Typography>
        ) : (
          <Typography color="error">Not Contacted</Typography>
        );
      },
      renderHeader: (params: any) => params?.colDef?.headerName ?? "-",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      minWidth: 120,
      getActions: (value: any) => {
        return [
          <div>
            <GridActionsCellItem
              disabled={
                selectedValue?.value === "converted" ||
                selectedValue?.value === "coldLeads"
              }
              icon={<SettingsIcon />}
              label="View"
              onClick={(e) => {
                handleClick(e, value);
              }}
              sx={{
                mr: 2,
                "& .MuiSvgIcon-root": { fontSize: 20 },
              }}
            />
            {anchorEl && (
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                slotProps={{
                  paper: {
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.05))",
                      ml: -4,
                      mt: -4,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: 2,
                        mr: 3,
                      },
                      "&::before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: "15%",
                        right: 0,
                        width: 10,
                        height: 20,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem
                  onClick={() => handleDetailOfInteraction(currentValue)}
                >
                  Enter details of Interaction
                </MenuItem>
                <Divider sx={{ my: 1 }} />
                <MenuItem
                  onClick={() =>
                    handleConvertLeadToContact(currentValue, leads)
                  }
                >
                  Convert Lead to Contact
                </MenuItem>
                {selectedValue?.value !== "coldLeads" && (
                  <div>
                    <Divider sx={{ my: 1 }} />
                    <MenuItem
                      onClick={() => handleMarkAsColdLead(currentValue)}
                    >
                      Mark Lead as Cold
                    </MenuItem>
                  </div>
                )}
              </Menu>
            )}
          </div>,
          <GridActionsCellItem
            disabled={
              selectedValue?.value === "converted" ||
              value?.row?.detailOfInteraction?.length < 1 ||
              selectedValue?.value === "coldLeads"
            }
            icon={<IconEye />}
            label="View"
            onClick={(e) => {
              handleViewInfo(value?.id);
            }}
            sx={{
              mr: 2,
              "& .MuiSvgIcon-root": { fontSize: 20 },
            }}
          />,
        ];
      },
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <Typography sx={{ fontWeight: "bold" }}>
            {params?.colDef?.headerName}
          </Typography>
        );
      },
    },
  ];

  // Add debounce to hold render events
  const debouncedSetSearch = useRef(
    debounce((value) => setSearch(value), 400)
  ).current;

  const handleSearchChange = useCallback(
    (event: any) => {
      debouncedSetSearch(event.target.value.trim());
    },
    [debouncedSetSearch]
  );

  const handleChange = (event: any, newValue: any) => {
    setSelectedValue(newValue);
  };
  const handleSortModelChange = (newModel: any[]) => {
    setSortModel(newModel);
  };

  const handleRefetch = () => {
    setRotated((prev) => !prev);
    refetchLeads(); // Your function to refetch leads
  };

  const handleDetailOfInteraction = useCallback(
    (currentValue: any) => {
      const modal: any = showModal(DetailOfInteractionModal, {
        onClose: () => modal.hide(),
        AddDetailOfInteraction,
        isAddingDetail,
        currentValue,
        userId,
      });
      handleClose();
    },
    //eslint-disable-next-line
    [showModal]
  );

  // Update the prospectId and fetch the associated data

  const handleViewInfo = useCallback(
    (value: any) => {
      const modal: any = showModal(ViewDetailOfInteractionModal, {
        onClose: () => modal.hide(),
        data: value,
        site: site,
      });
      handleClose();
    },
    //eslint-disable-next-line
    [showModal]
  );

  const handleConvertLeadToContact = useCallback(
    (row: any, leads: any) => {
      const modal: any = showModal(ConvertLeadToContact, {
        onClose: () => modal.hide(),
        data: row?.row,
        leads,
        convertLead,
        isConvertingLead,
        inLoadingLeads,
        userId,
      });
      handleClose();
    },
    //eslint-disable-next-line
    [showModal]
  );

  const handleMarkAsColdLead = useCallback(
    (row?: any) => {
      const modal: any = showModal(MarkAsColdLead, {
        onClose: () => modal.hide(),
        AddDetailOfInteraction,
        isAddingDetail,
        value: row?.row,
        userId,
      });
      handleClose();
    },
    //eslint-disable-next-line
    [showModal]
  );

  // const handleMarkAsColdLead = () => {
  //   if (reason === "") {
  //     enqueueSnackbar("Please enter a reason to mark a cold lead.", {
  //       variant: "error",
  //     });
  //   }
  // };

  return (
    <Box>
      <MainCard
        title={
          <Typography variant={window?.innerWidth < 1420 ? "h2" : "h1"}>
            Prospects
          </Typography>
        }
        actions={
          <Stack direction={"row"} alignItems={"center"} spacing={2}>
            <Tooltip title={"Refresh"}>
              <IconButton disableRipple onClick={() => handleRefetch()}>
                <SyncIcon
                  style={{
                    transform: rotated ? "rotate(360deg)" : "rotate(0deg)",
                    transition: "transform 1s ease",
                  }}
                />
              </IconButton>
            </Tooltip>
            <Autocomplete
              disablePortal
              size="small"
              options={leadsFilterOptions}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              value={selectedValue}
              sx={{ width: 190 }}
              onChange={handleChange}
              renderInput={(params) => <TextField {...params} label="Filter" />}
            />
            <SearchField
              onChange={handleSearchChange}
              size="small"
              placeholder="Search Prospects"
            />
          </Stack>
        }
        content={
          isSiteLoading ? (
            <TableSkeleton />
          ) : (
            <>
              {!!site?.zoho?.accessToken ? (
                <DataGridTable
                  paginationMode={"client"}
                  onPaginationModelChange={setPaginationModel}
                  paginationModel={paginationModel}
                  column={columns}
                  rows={!!search ? searchLeads(prospects, search) : prospects}
                  isLoading={inLoadingLeads || isRefetchingLeads}
                  sortModel={sortModel}
                  onSortModelChange={handleSortModelChange}
                />
              ) : (
                <NoResultFound>
                  Zoho is not integrated. Click{" "}
                  <Link to="/settings?tab=zohoIntegration"> here </Link> to
                  integrate Zoho.
                </NoResultFound>
              )}
            </>
          )
        }
      />
    </Box>
  );
};

export default Prospects;
