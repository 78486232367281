import { yupResolver } from "@hookform/resolvers/yup";
import { Button, DialogProps, Grid, Typography } from "@mui/material";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import {
  COUNT_TYPES,
  EVENTS,
  LETTERS,
  TIME_FRAME,
} from "../../../constants/MenuSelection";
import useWorkflows from "../../../hooks/useWorkflows";
import { useSnackbarHelper } from "../../components/Snackbar";
import BasicAutocomplete from "../../components/inputs/BasicAutocomplete";
import MuiTextField from "../../components/inputs/MuiTextField";
import BasicModal from "../../components/modal/BasicModal";

interface Props extends DialogProps {
  onClose: () => void;
  data?: any;
  workflowId?: string;
  taskId?: string;
  event?: string;
}

const AddWorkflowTodoModal = ({
  onClose,
  workflowId,
  taskId,
  data,
  event,
  ...props
}: Props) => {
  const showSnackbar = useSnackbarHelper();
  const {
    addWorkflowTodo,
    isAddingWorkflowTodo,
    updateWorkflowTodo,
    isUpdatingWorkflowTodo,
  } = useWorkflows(workflowId, taskId);

  const initialValues = useMemo(
    () => ({
      task: data?.task || "",
      description: data?.description || "",
      intervalValue: data?.dueDate?.intervalValue || "0",
      intervalUnit: data?.dueDate?.intervalUnit || "days",
      conditionType: data?.dueDate?.conditionType || "after",
      event: !!data ? data?.dueDate?.event || "" : event || "",
      letter: data?.letter || [],
      recipient: data?.recipient || "",
    }),
    [data, event]
  );

  const schema: any = Yup.object({
    task: Yup.string().required("Required"),
    // .matches(/^\S*$/, "Task should not contain spaces"),
    description: Yup.string().required("Required"),
    // .matches(/^\S*$/, "Description should not contain spaces"),
    intervalValue: Yup.string()
      .matches(/^\d+$/, "Must be a positive number")
      .required("Required"),
    intervalUnit: Yup.string().required("Required"),
    conditionType: Yup.string().required("Required"),
    event: Yup.string().required("Required"),
    letter: Yup.array().of(Yup.string().required()).nullable(),
    recipient: Yup.string().when("letter", (letter: any, schema: any) => {
      return !!letter?.[0]?.length
        ? schema.required("Required")
        : schema.notRequired();
    }),
  });

  const formContext = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });

  const { watch } = formContext;
  const letter = watch("letter");

  const handleSubmit = async (values?: any) => {
    try {
      const { letter, recipient } = values;
      const emailRegex =
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|org|net|edu|gov|mil|co|io|info|biz|name|me|mobi|ly|ai|us|uk|ca|au|de|fr|jp|cn|ru|br|in|za|nl|se|no|fi|dk|it|es|pt|ch|at|be|pl|gr|cz|ro|hu|il|mx|ar|cl|nz|kr|tr|tw|hk|sg|my|id|ph|th|vn)$/;

      //if recipient is null or undefined set empty string
      if (!recipient) {
        values.recipient = "";
      }

      const isRecipientCorrect =
        recipient === "Support Contact" || emailRegex.test(recipient);

      if (!!letter?.length && !isRecipientCorrect) {
        showSnackbar(
          "Recipient must be either 'Support Contact' or a valid email address.",
          "error"
        );

        return;
      }

      const response = !!data
        ? await updateWorkflowTodo(values)
        : await addWorkflowTodo(values);

      const message = response?.message || "Success";

      showSnackbar(message, "success");
      onClose();
    } catch (err: any) {
      console.error(err?.response?.data?.message);
    }
  };

  return (
    <>
      <BasicModal
        isLoader={false}
        formContext={formContext}
        onClose={onClose}
        onSubmit={handleSubmit}
        title={!!data ? "Update Todo" : "Add Todo"}
        content={
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MuiTextField fullWidth label="Task" name="task" required />
            </Grid>
            <Grid item xs={12}>
              <MuiTextField
                fullWidth
                label="Description"
                name="description"
                required
                multiline
                rows={4}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>Due Date:</Typography>
            </Grid>
            <Grid item xs={6}>
              <MuiTextField label="" name="intervalValue" type="number" />
            </Grid>
            <Grid item xs={6}>
              <BasicAutocomplete
                label=""
                name="intervalUnit"
                options={COUNT_TYPES}
                getOptionValue={(option: any) => option?.value}
              />
            </Grid>
            <Grid item xs={6}>
              <BasicAutocomplete
                label=""
                name="conditionType"
                options={TIME_FRAME}
                getOptionValue={(option: any) => option?.value}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <BasicAutocomplete
                label="Event"
                name="event"
                options={EVENTS}
                getOptionValue={(option: any) => option?.value}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Note: Letter will be sent to recipient when todo is marked as
                complete.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <BasicAutocomplete
                label="Select Letter"
                name="letter"
                options={LETTERS}
                getOptionValue={(option: any) => option?.value}
                multiple
              />
            </Grid>
            <Grid item xs={12}>
              <BasicAutocomplete
                label="Recipient"
                name="recipient"
                freeSolo
                required={!!letter?.[0]?.length}
                options={[
                  { label: "Support Contact", value: "Support Contact" },
                ]}
                getOptionValue={(option: any) => option?.value}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Note: The recipient can either be the support contact or you can
                enter an email address manually.
              </Typography>
            </Grid>
          </Grid>
        }
        actions={
          <>
            <Button
              color="error"
              variant="outlined"
              onClick={onClose}
              sx={{ textTransform: "none" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{ textTransform: "capitalize" }}
              type="submit"
              disabled={isAddingWorkflowTodo || isUpdatingWorkflowTodo}
            >
              {!!data ? "Update" : "Save"}
            </Button>
          </>
        }
        {...props}
      />
    </>
  );
};

export default AddWorkflowTodoModal;
