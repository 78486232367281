import React, { createContext, useContext, ReactNode } from "react";
import { useSnackbar, VariantType } from "notistack";
import { Typography } from "@mui/material";

type SnackbarHelperProps = {
  children: ReactNode;
};

const SnackbarContext = createContext<
  (message: string, variant?: VariantType) => void
>(() => {});

export const SnackbarHelper: React.FC<SnackbarHelperProps> = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();

  const showSnackbar = (message: string, variant: VariantType = "default") => {
    let value = <Typography variant="subtitle1">{message}</Typography>;
    enqueueSnackbar(value, { variant, preventDuplicate: true });
  };

  return (
    <SnackbarContext.Provider value={showSnackbar}>
      {children}
    </SnackbarContext.Provider>
  );
};

export const useSnackbarHelper = () => {
  return useContext(SnackbarContext);
};
