import React, { useEffect, useMemo } from "react";
import { useFieldArray, useForm, useFormContext } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {
  Box,
  Button,
  DialogProps,
  Divider,
  Grid,
  Icon,
  IconButton,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import * as Yup from "yup";
import WarningAmber from "@mui/icons-material/WarningAmber";

//components
import { EmailType, Suffix } from "../../../constants/MenuSelection";
import useAuth from "../../../hooks/useAuth";
import useContacts from "../../../hooks/useContacts";
import useScrappingWithSid from "../../../hooks/useScrappingWithSid";
import useScrappingWithTdcj from "../../../hooks/useScrappingWithTdcj";
import { contactTypes } from "../../../types/contactTypes";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import { getUnitLocation } from "../../../utils/getUnitLocation";
import BasicAutocomplete from "../../components/inputs/BasicAutocomplete";
import MuiTextField from "../../components/inputs/MuiTextField";
import PhoneNumber from "../../components/inputs/PhoneNumber";
import BasicModal from "../../components/modal/BasicModal";
import { useSnackbarHelper } from "../../components/Snackbar";
import AdditionalContact from "./AdditionalContact";
import BasicDatePicker from "../../components/inputs/BasicDatePicker";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Checkbox from "../../components/inputs/CheckBox";
import moment from "moment";

interface Props extends DialogProps {
  onClose: () => void;
  data?: contactTypes;
}

/*
used to add Contact
*/
const AddContact = ({ onClose, data, ...props }: Props) => {
  const { AddSupport, updateContact, isLoading } = useContacts();
  const { scrapDataWithSid, isSidDataLoading } = useScrappingWithSid();
  const { scrapDataWithTdcj, isTdcjDataLoading } = useScrappingWithTdcj();
  const showSnackbar = useSnackbarHelper();
  const { userId } = useAuth();

  const initialValues = useMemo(
    () => ({
      //Support Contact
      firstName: data?.firstName || "",
      lastName: data?.lastName || "",
      address: data?.address || "",
      country: data?.country || "",
      state: data?.state || "",
      city: data?.city || "",
      zip: data?.zip || "",
      note: data?.note || "",
      mi: data?.mi || "",
      suffix: data?.suffix || "",
      nickName: data?.nickName || "",
      email: data?.email || [{ email: "", emailType: "" }],
      phone: data?.phone || [{ phone: "", phoneType: "" }],

      //Support Contact
      additional_Support_firstName:
        data?.additionalSupportContact?.firstName || "",
      additional_Support_lastName:
        data?.additionalSupportContact?.lastName || "",
      additional_Support_address: data?.additionalSupportContact?.address || "",
      additional_Support_country: data?.additionalSupportContact?.country || "",
      additional_Support_state: data?.additionalSupportContact?.state || "",
      additional_Support_city: data?.additionalSupportContact?.city || "",
      additional_Support_zip: data?.additionalSupportContact?.zip || "",
      additional_Support_note: data?.additionalSupportContact?.note || "",
      additional_Support_mi: data?.additionalSupportContact?.mi || "",
      additional_Support_suffix: data?.additionalSupportContact?.suffix || "",
      additional_Support_nickName:
        data?.additionalSupportContact?.nickName || "",
      additional_Support_email: data?.additionalSupportContact?.email || [
        { email: "", emailType: "" },
      ],
      additional_Support_phone: data?.additionalSupportContact?.phone || [
        { phone: "", phoneType: "" },
      ],

      //Inmate
      inmate_dob: data?.inmate_dob || null,
      unit: capitalizeFirstLetter(data?.unit) || "",
      unit_location: data?.unit_location || "",
      tdcj: data?.tdcj || "",
      sid: data?.sid || "",
      isSupportContactRequired: false,
      isRefatchDPS: false,
    }),
    [data]
  );

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const zipRegex = /^[a-zA-Z0-9 _-]+$/;
  const phoneRegExp = /^\+\d{1,3} \(\d{3}\) \d{3}-\d{4}$/;

  const emailSchema = Yup.object({
    email: Yup.string()
      .email("Invalid Email")
      .required("Required")
      .transform((value, originalValue) => originalValue.toLowerCase())
      .matches(emailRegex, "Must be a valid email"),
    emailType: Yup.string().required("Required"),
  });

  const phoneSchema = Yup.object({
    phone: Yup.string()
      .required("Required")
      .matches(phoneRegExp, "Phone number is not valid"),
    phoneType: Yup.string().required("Required"),
  });

  const additional_Support_EmailSchema = Yup.object({
    email: Yup.string()
      .email("Invalid Email")
      .required("Required")
      .transform((value, originalValue) => originalValue.toLowerCase())
      .matches(emailRegex, "Must be a valid email"),
    emailType: Yup.string().required("Required"),
  });

  const additional_Support_PhoneSchema = Yup.object({
    phone: Yup.string()
      .required("Required")
      .matches(phoneRegExp, "Mobile number is not valid"),
    phoneType: Yup.string().required("Required"),
  });

  const supportContactSchema: Yup.ObjectSchema<any> = Yup.object({
    firstName: Yup.string() //
      .matches(
        /^(?:[a-zA-Z]+(?:\s[a-zA-Z]+)*)?$/,
        "First name should not contain symbols/numbers or spaces"
      )
      .required("Required"),
    lastName: Yup.string() //
      .matches(
        /^(?:[a-zA-Z]+(?:\s[a-zA-Z]+)*)?$/,
        "Last name should not contain symbols/numbers or spaces"
      )
      .required("Required"),
    email: Yup.array()
      .of(emailSchema)
      .required("Required")
      .min(1, "At least one email is required"),
    phone: Yup.array()
      .of(phoneSchema)
      .required("Required")
      .min(1, "At least one email is required"),

    address: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    zip: Yup.string().nullable().matches(zipRegex, {
      message: "Special characters not allowed",
      excludeEmptyString: true, // Only apply the regex match if the field is not empty
    }),
    state: Yup.string(),
    country: Yup.string().required("Required"),
    note: Yup.string(),
    isSupportContactRequired: Yup.boolean(),
    additional_Support_firstName: Yup.string()
      .matches(
        /^(?:[a-zA-Z]+(?:\s[a-zA-Z]+)*)?$/,
        "First name should not contain symbols/numbers or spaces"
      )
      .when("isSupportContactRequired", (isSupportContactRequired, schema) => {
        return isSupportContactRequired?.[0]
          ? schema.required("Required")
          : schema.notRequired();
      }),
    additional_Support_lastName: Yup.string()
      .matches(
        /^(?:[a-zA-Z]+(?:\s[a-zA-Z]+)*)?$/,
        "Last name should not contain symbols/numbers or spaces"
      )
      .when("isSupportContactRequired", (isSupportContactRequired, schema) => {
        return isSupportContactRequired?.[0]
          ? schema.required("Required")
          : schema.notRequired();
      }),
    additional_Support_email: Yup.array()
      .when("isSupportContactRequired", (isSupportContactRequired, schema) => {
        return isSupportContactRequired?.[0]
          ? schema.of(additional_Support_EmailSchema).required("Required")
          : schema.notRequired();
      })
      .min(1, "At least one email is required"),

    additional_Support_phone: Yup.array()

      .when("isSupportContactRequired", (isSupportContactRequired, schema) => {
        return isSupportContactRequired?.[0]
          ? schema.of(additional_Support_PhoneSchema).required("Required")
          : schema.notRequired();
      })
      .min(1, "At least one email is required"),

    additional_Support_address: Yup.string().when(
      "isSupportContactRequired",
      (isSupportContactRequired, schema) => {
        return isSupportContactRequired?.[0]
          ? schema.required("Required")
          : schema.notRequired();
      }
    ),
    additional_Support_city: Yup.string().when(
      "isSupportContactRequired",
      (isSupportContactRequired, schema) => {
        return isSupportContactRequired?.[0]
          ? schema.required("Required")
          : schema.notRequired();
      }
    ),
    additional_Support_zip: Yup.string().nullable().matches(zipRegex, {
      message: "Special characters not allowed",
      excludeEmptyString: true, // Only apply the regex match if the field is not empty
    }),
    additional_Support_state: Yup.string(),
    additional_Support_country: Yup.string().when(
      "isSupportContactRequired",
      (isSupportContactRequired, schema) => {
        return isSupportContactRequired?.[0]
          ? schema.required("Required")
          : schema.notRequired();
      }
    ),
    additional_Support_note: Yup.string(),
    // inmate_dob: Yup.date().required("Required"),
    inmate_dob: Yup.date()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .when(["tdcj", "sid"], {
        is: (tdcj: string | undefined, sid: string | undefined) =>
          !!tdcj || !!sid,
        then: (schema) => schema.required("Required"),
        otherwise: (schema) => schema.nullable(),
      }),
    unit: Yup.string().nullable(),
    unit_location: Yup.string(),
    tdcj: Yup.string(),
    sid: Yup.string(),
    isRefatchDPS: Yup.boolean(),
  });

  const formContext = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(supportContactSchema),
  });

  const { watch, setValue } = formContext;

  const selectedAdditionalSupportCountry = watch("additional_Support_country");
  const selectedCountry = watch("country");
  const selectedUnit = watch("unit");
  const isSupportContactRequired = watch("isSupportContactRequired");
  const isFworkflowId = data?.case?.workflowId || false;

  let Country = require("country-state-city").Country;
  let State = require("country-state-city").State;
  const additional_Support_country = watch("additional_Support_country");
  const isRefetchDps = watch("isRefatchDPS");
  //get the countries
  const countryList = Country.getAllCountries().map((item: any) => ({
    label: item.name,
    value: item.name,
    isoCode: item.isoCode,
  }));

  const newDob = watch("inmate_dob");
  const sid = watch("sid");
  const tdcj = watch("tdcj");

  const isDOBChanged = useMemo(
    () =>
      data?.caseId &&
      isFworkflowId &&
      moment(data?.inmate_dob).format("MM-DD-YYYY") !==
        moment(newDob).format("MM-DD-YYYY"),
    [data?.caseId, data?.inmate_dob, isFworkflowId, newDob]
  );
  useEffect(() => {
    if (selectedCountry !== data?.country) {
      setValue("state", "");
    }
  }, [selectedCountry, setValue, data]);
  useEffect(() => {
    if (
      additional_Support_country !== data?.additionalSupportContact?.country
    ) {
      setValue("additional_Support_state", "");
    }
  }, [additional_Support_country, setValue, data]);

  //get state list for support according to country
  const additionalSupportStatesList = useMemo(() => {
    const countryISOCode = countryList?.find(
      (country: any) => country.value === selectedAdditionalSupportCountry
    )?.isoCode;

    if (countryISOCode) {
      return State.getStatesOfCountry(countryISOCode).map((item: any) => ({
        label: `${item.isoCode} (${item.name})`,
        value: item.isoCode,
        isoCode: item.isoCode,
      }));
    }

    return [];
    //eslint-disable-next-line
  }, [selectedAdditionalSupportCountry, countryList]);

  useEffect(() => {
    // let selectedValue =
    //   Units?.find((op) => op?.value === selectedUnit)?.address || "";

    if (data?.additionalSupportContact?.firstName) {
      setValue("isSupportContactRequired", true);
    }

    // setValue("unit_location", selectedValue);

    //eslint-disable-next-line
  }, [selectedUnit, data]);

  //get state list according to country
  const statesList = useMemo(() => {
    const countryISOCode = countryList?.find(
      (country: any) => country.value === selectedCountry
    )?.isoCode;

    if (countryISOCode) {
      return State.getStatesOfCountry(countryISOCode).map((item: any) => ({
        label: `${item.isoCode} (${item.name})`,
        value: item.isoCode,
        isoCode: item.isoCode,
      }));
    }

    return [];
    //eslint-disable-next-line
  }, [selectedCountry, countryList]);

  const getPayload = ({ inmate, sid, tdcj, values, unit }: any) => {
    const isUpdatingContact = !!data;

    const inmate_dob = values?.inmate_dob;

    const email = values?.email?.map((d: any) => {
      return {
        email: d?.email,
        emailType: d?.emailType,
      };
    });

    const phone = values?.phone?.map((d: any) => ({
      phone: d?.phone,
      phoneType: d?.phoneType,
    }));

    const additional_Support_email = values?.additional_Support_email?.map(
      (d: any) => {
        return {
          email: d?.email,
          emailType: d?.emailType,
        };
      }
    );

    const additional_Support_phone = values?.additional_Support_phone?.map(
      (d: any) => ({
        phone: d?.phone,
        phoneType: d?.phoneType,
      })
    );

    const additionalSupportContact = !isSupportContactRequired
      ? {
          suffix: "",
          firstName: "",
          mi: "",
          lastName: "",
          middleName: "",
          nickName: "",
          country: "",
          address: "",
          city: "",
          state: "",
          zip: "",
          phone: [{ phone: "", phoneType: "" }],
          email: [{ email: "", emailType: "" }],
        }
      : {
          suffix: values?.additional_Support_suffix,
          firstName: values?.additional_Support_firstName,
          mi: values?.additional_Support_mi,
          lastName: values?.additional_Support_lastName,
          middleName: values?.additional_Support_middleName,
          nickName: values?.additional_Support_nickName,
          country: values?.additional_Support_country,
          address: values?.additional_Support_address,
          city: values?.additional_Support_city,
          state: values?.additional_Support_state,
          zip: values?.additional_Support_zip,
          phone: isUpdatingContact
            ? additional_Support_phone
            : values?.additional_Support_phone,
          email: isUpdatingContact
            ? additional_Support_email
            : values?.additional_Support_email,
        };

    const payload = {
      suffix: values?.suffix,
      firstName: values?.firstName,
      mi: values?.mi,
      lastName: values?.lastName,
      middleName: values?.middleName,
      nickName: values?.nickName,
      country: values?.country,
      address: values?.address,
      city: values?.city,
      state: values?.state,
      zip: values?.zip,
      phone: isUpdatingContact ? phone : values?.phone,
      email: isUpdatingContact ? email : values?.email,
      unit: unit || "",
      tdcj: tdcj || "",
      sid: sid || "",
      note: values?.note,
      createdBy: userId,
      inmate,
      unit_location: getUnitLocation(unit),
      additionalSupportContact,
      inmate_dob,
      isRefatchDPS: values?.isRefatchDPS,
      caseId: data?.caseId, //to update DPS report url
    };
    return payload;
  };

  const handleSubmit = async (values?: any) => {
    const isUpdatingContact = !!data;
    const contactID = data?._id || "";

    const isScrapingId = data?.inmate?._id || null;
    let inmate = null;
    const sid = values?.sid || null;
    const tdcj = values?.tdcj || null;
    let responseSid = null;
    let responseTdcj = null;
    let isErrorOccurWhileScraping = false;
    let unit = values?.unit || null;

    const inmate_dob = values?.inmate_dob;

    if (!isScrapingId && sid) {
      try {
        const payload = {
          sid,
          inmate_dob,
          additionalSupportContactEmail: values?.additional_Support_email,
          email: values?.email,
          id: isUpdatingContact ? contactID : null,
        };
        const data = await scrapDataWithSid(payload);
        responseSid = data?.scrappedData?.sidNumber || null;
        responseTdcj = data?.scrappedData?.tdcjNumber || null;
        inmate = data?.scrappedData?._id || null;
        unit = data?.scrappedData?.unit || null;

        // const message = data?.message || "Success";

        // showSnackbar(message, "success");
        // onClose();
      } catch (error: any) {
        isErrorOccurWhileScraping = true;

        const message = error?.response?.data?.message || "An error occurred";
        showSnackbar(message, "error");
      }
    }

    if (!isScrapingId && !sid && tdcj) {
      try {
        const payload = {
          tdcj,
          inmate_dob,
          additionalSupportContactEmail: values?.additional_Support_email,
          email: values?.email,
          id: isUpdatingContact ? contactID : null,
        };
        const data = await scrapDataWithTdcj(payload);
        responseSid = data?.scrappedData?.sidNumber || null;
        responseTdcj = data?.scrappedData?.tdcjNumber || null;
        inmate = data?.scrappedData?._id || null;
        unit = data?.scrappedData?.unit || null;

        // const message = data?.message || "Success";

        // showSnackbar(message, "success");
        // onClose();
      } catch (error: any) {
        isErrorOccurWhileScraping = true;

        const message = error?.response?.data?.message || "An error occurred";
        showSnackbar(message, "error");
      }
    }

    if (isErrorOccurWhileScraping) {
      return;
    }

    const payload = getPayload({
      sid: responseSid || sid,
      tdcj: responseTdcj || tdcj,
      inmate: inmate || undefined,
      values,
      unit,
    });

    try {
      const data = isUpdatingContact
        ? await updateContact({ id: contactID, payload })
        : await AddSupport(payload);

      const message = data?.message || "Success";

      showSnackbar(message, "success");
      onClose();
    } catch (error: any) {
      const message = error?.response?.data?.message || "An error occurred";

      showSnackbar(message, "error");
    }
  };

  return (
    <>
      <BasicModal
        isLoader={isTdcjDataLoading || isSidDataLoading}
        formContext={formContext}
        onClose={onClose}
        onSubmit={handleSubmit}
        title={!!data ? "Update Contact" : "Add Contact"}
        height="calc(100vh - 230px)"
        content={
          <Stack spacing={3}>
            {/* Support info */}
            <Box
              sx={{
                position: "relative",
                border: "1px solid lightgrey",
                padding: 2,
                borderRadius: 2,
                "& .outlined .MuiOutlinedInput-notchedOutline": {
                  border: "2px solid #AFD2F0 !important",
                },
                "& .changeRadius .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "12px !important",
                },
                "&::before": {
                  content: '"Support Contact Info"',
                  position: "absolute",
                  top: -10,
                  left: 10,
                  backgroundColor: "white",
                  padding: "0 4px",
                  fontWeight: "600",
                },
              }}
            >
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={3}>
                  <MuiTextField fullWidth label="Suffix" name="suffix" select>
                    {Suffix.map((op) => (
                      <MenuItem key={op.value} value={op.value}>
                        {op.label}
                      </MenuItem>
                    ))}
                  </MuiTextField>
                </Grid>
                <Grid item xs={12} md={9}>
                  <MuiTextField
                    fullWidth
                    label="First Name"
                    name="firstName"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <MuiTextField fullWidth label="M.I." name="mi" />
                </Grid>
                <Grid item xs={12} sm={9}>
                  <MuiTextField
                    fullWidth
                    label="Last Name"
                    name="lastName"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <MuiTextField
                    fullWidth
                    label="Nickname/Pronounce"
                    name="nickName"
                  />
                </Grid>

                <SupportContact />

                <Grid item xs={12}>
                  <MuiTextField
                    fullWidth
                    label="Address"
                    name="address"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <BasicAutocomplete
                    name="country"
                    // additionalValue={false}
                    label="Country"
                    options={countryList}
                    required
                    // freeSolo={true}
                    getOptionValue={(option: any) => option?.value}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <BasicAutocomplete
                    name="state"
                    // additionalValue={false}
                    label="Province/State"
                    // freeSolo={true}
                    options={statesList}
                    getOptionValue={(option: any) => option?.value}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MuiTextField fullWidth label="City" name="city" required />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MuiTextField
                    fullWidth
                    label="Postal Code/Zip Code"
                    name="zip"
                  />
                </Grid>
              </Grid>
            </Box>

            {/* Additional support contact */}
            <Grid
              container
              spacing={1}
              sx={{
                border: "1px solid lightgrey",
                padding: 2,
                borderRadius: 2,
                "& .outlined .MuiOutlinedInput-notchedOutline": {
                  border: "2px solid #AFD2F0 !important",
                },
              }}
            >
              <Grid item xs={12}>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Typography variant="body1" fontWeight={"600"}>
                    Additional Support Contact
                  </Typography>
                  {isSupportContactRequired ? (
                    <>
                      <Tooltip title="Remove Additional Contact">
                        <Button
                          variant="contained"
                          color="error"
                          sx={{ textTransform: "capitalize" }}
                          onClick={() =>
                            setValue("isSupportContactRequired", false)
                          }
                        >
                          Remove
                        </Button>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <Tooltip title="Add Additional Contact">
                        <Button
                          variant="contained"
                          color="success"
                          sx={{ textTransform: "capitalize" }}
                          onClick={() =>
                            setValue("isSupportContactRequired", true)
                          }
                        >
                          Add
                        </Button>
                      </Tooltip>
                    </>
                  )}
                </Stack>
              </Grid>
              <Grid container spacing={2} mt={1}>
                {isSupportContactRequired && (
                  <AdditionalContact
                    statesList={additionalSupportStatesList}
                    countryList={countryList}
                  />
                )}
              </Grid>
            </Grid>

            {/* Inmate Info */}
            <Box
              sx={{
                position: "relative",
                border: "1px solid lightgrey",
                padding: 2,
                borderRadius: 2,
                "& .outlined .MuiOutlinedInput-notchedOutline": {
                  border: "2px solid #AFD2F0 !important",
                },
                "&::before": {
                  content: '"Inmate Info"',
                  position: "absolute",
                  top: -10,
                  left: 10,
                  backgroundColor: "white",
                  padding: "0 4px",
                  fontWeight: "600",
                },
              }}
            >
              <Grid container spacing={2} mt={1}>
                <Grid item xs={isDOBChanged ? 6 : 8}>
                  <BasicDatePicker
                    label="Inmate DOB"
                    name="inmate_dob"
                    minDate={dayjs("1900-01-01")}
                    required={sid || tdcj ? true : false}
                  />
                </Grid>
                {isDOBChanged ? (
                  <Grid item xs={4}>
                    <Checkbox name="isRefatchDPS" label="Refetch DPS" />
                  </Grid>
                ) : (
                  ""
                )}
                <Grid
                  item
                  xs={1}
                  // sx={{ display: "flex", alignItems: "center" }}
                  mt={1}
                >
                  {isRefetchDps ? (
                    <Tooltip
                      title="If you check this it will Refetch the DPS Report"
                      arrow
                    >
                      <WarningAmber
                        sx={{
                          marginLeft: 1,
                          color: "warning.main",
                          fontSize: 20,
                        }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip
                      title={
                        data
                          ? "If you change the inmate's DOB and checked, the DPS report will be refetched."
                          : "Please add the accurate Inmate DOB. The DPS report is fetched based on the provided DOB."
                      }
                    >
                      <Icon>
                        <InfoOutlinedIcon
                          sx={{ fontSize: 20, color: "#008EBB" }}
                        />
                      </Icon>
                    </Tooltip>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MuiTextField
                    fullWidth
                    type="number"
                    label="TDCJ"
                    name="tdcj"
                    disabled={!!data?.tdcj}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MuiTextField
                    type="number"
                    fullWidth
                    label="SID"
                    name="sid"
                    disabled={!!data?.sid}
                  />
                </Grid>

                {/* {isRefetchDps && (
                  <Grid xs={12}>
                    <Typography>ttttttttttt</Typography>
                  </Grid>
                )} */}
                {data && (
                  <>
                    <Grid item xs={12}>
                      {/* <BasicAutocomplete
                    name="unit"
                    // additionalValue={false}
                    label="Unit"
                    options={Units}
                    disabled={!!data?.unit}
                    // isUnitData={true}
                    getOptionValue={(option: any) => option?.value}
                  /> */}
                      <MuiTextField
                        fullWidth
                        label="Unit"
                        name="unit"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MuiTextField
                        fullWidth
                        label="Unit Location"
                        name="unit_location"
                        disabled
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <MuiTextField
                    fullWidth
                    sx={{ height: "80px" }}
                    multiline
                    rows={2}
                    label="Note/Other Detail"
                    name="note"
                  />
                </Grid>
              </Grid>
            </Box>
          </Stack>
        }
        actions={
          <>
            <Button
              color="error"
              variant="outlined"
              onClick={onClose}
              sx={{ textTransform: "none" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{ textTransform: "capitalize" }}
              type="submit"
              disabled={isLoading || isTdcjDataLoading || isSidDataLoading}
            >
              {!!data ? "Update" : "Save"}
            </Button>
          </>
        }
        {...props}
      />
    </>
  );
};

export default AddContact;

const SupportContact: React.FC = () => {
  const { control } = useFormContext();

  const {
    fields: emailFields,
    append: appendEmail,
    remove: removeEmail,
  } = useFieldArray({
    control,
    name: "email",
  });

  const {
    fields: phoneFields,
    append: appendPhone,
    remove: removePhone,
  } = useFieldArray({
    control,
    name: "phone",
  });

  const RenderPhonesItem = ({ i, arrayLength }: any) => {
    return (
      <>
        <Grid item xs={3.5}>
          <MuiTextField
            fullWidth
            label="Phone Type"
            name={`phone.${i}.phoneType`}
            select
            required
          >
            {EmailType.map((op) => (
              <MenuItem key={op.value} value={op.value}>
                {op.label}
              </MenuItem>
            ))}
          </MuiTextField>
        </Grid>
        <Grid
          item
          xs={7.5}
          sx={{
            "& .changeRadius .MuiOutlinedInput-notchedOutline": {
              borderRadius: "12px !important",
            },
          }}
        >
          {/* <MuiTextField
            fullWidth
            type="number"
            label="Cell Phone Number"
            name={`phone.${i}.phone`}
            required
          /> */}
          <PhoneNumber
            fullWidth
            label="Cell Phone Number"
            name={`phone.${i}.phone`}
            required
            className="changeRadius"
          />
        </Grid>

        <Grid item xs={1}>
          {i > 0 ? (
            <Tooltip title="Remove">
              <IconButton onClick={() => removePhone(i)}>
                <RemoveCircleOutlineIcon style={{ color: "red" }} />
              </IconButton>
            </Tooltip>
          ) : i === 0 ? (
            <Tooltip title="Add Phone">
              <IconButton
                onClick={() => appendPhone({ phone: "", phoneType: "" })}
              >
                <AddCircleOutlineIcon
                  sx={{ color: "green", cursor: "pointer" }}
                />
              </IconButton>
            </Tooltip>
          ) : (
            ""
          )}
        </Grid>
      </>
    );
  };
  const RenderEmailsItem = ({ i, arrayLength }: any) => {
    return (
      <>
        <Grid item xs={3.5}>
          <MuiTextField
            fullWidth
            label="Email Type"
            name={`email.${i}.emailType`}
            select
            required
          >
            {EmailType.map((op, index) => (
              <MenuItem key={index} value={op.value}>
                {op.label}
              </MenuItem>
            ))}
          </MuiTextField>
        </Grid>
        <Grid item xs={7.5}>
          <MuiTextField
            fullWidth
            label="Email Address"
            name={`email.${i}.email`}
            required
          />
        </Grid>

        <Grid item xs={1}>
          {i > 0 ? (
            <Tooltip title="Remove">
              <IconButton onClick={() => removeEmail(i)}>
                <RemoveCircleOutlineIcon style={{ color: "red" }} />
              </IconButton>
            </Tooltip>
          ) : i === 0 ? (
            <Tooltip title="Add Email">
              <IconButton
                onClick={() => appendEmail({ email: "", emailType: "" })}
              >
                <AddCircleOutlineIcon
                  sx={{ color: "green", cursor: "pointer" }}
                />
              </IconButton>
            </Tooltip>
          ) : (
            ""
          )}
        </Grid>
      </>
    );
  };

  return (
    <>
      {emailFields.map((item: any, i, arr) => (
        <RenderEmailsItem key={item.id} i={i} arrayLength={arr.length} />
      ))}

      <Grid item xs={12}>
        <Divider sx={{ my: 0.5 }} />
      </Grid>

      {phoneFields.map((item: any, i, arr) => (
        <RenderPhonesItem key={item.id} i={i} arrayLength={arr.length} />
      ))}
      <Grid item xs={12}>
        <Divider sx={{ my: 0.5 }} />
      </Grid>
    </>
  );
};
