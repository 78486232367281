import EditIcon from "@mui/icons-material/Edit";
import {
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import MoveEligibilityDialog from "../components/calender/MoveEligibilityDialog";

import { useModal } from "mui-modal-provider";
import useParoleEligibility from "../../hooks/useParoleEligibility";
import TableSkeleton from "../components/skeleton/TableSkeleton";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const Calendar = ({ currentYear, setOpenDrawer }: any) => {
  const { cases, isLoadingCases } = useParoleEligibility();
  const { showModal } = useModal();
  const [allCases, setAllCases] = useState(cases || []);

  useEffect(() => {
    if (cases) {
      const result = [];

      for (const year of [currentYear - 1, currentYear, currentYear + 1]) {
        const yearData = cases?.find((item: any) => item.year === year);
        if (yearData) {
          result.push(yearData);
        } else {
          result.push({ year: year, months: [] });
        }
      }

      setAllCases(result || []);
    }
  }, [cases, currentYear]);

  const handleCalenderDialog = useCallback(
    (record?: any) => {
      const modal: any = showModal(MoveEligibilityDialog, {
        onClose: () => modal.hide(),
        record,
        setOpenDrawer,
      });
    },
    [showModal, setOpenDrawer]
  );

  const generateEmptyCells = (
    currentMonthIndex: any,
    monthsWithRecords: any
  ) => {
    // Ensure that monthsWithRecords is defined and is an array
    if (!Array.isArray(monthsWithRecords)) {
      return [];
    }

    // Find the records for the specified month
    const monthRecords = monthsWithRecords.find(
      (monthData) => monthData.month === currentMonthIndex + 1
    );

    const recordsCount = monthRecords ? monthRecords.records.length : 0;
    const emptyCellsCount = Math.max(0, 31 - recordsCount); // Assuming a maximum of 31 days per month

    // Create an array with the number of empty cells needed
    return Array.from({ length: emptyCellsCount }, (_, index) => (
      <TableRow key={`empty-${index}`}>
        <TableCell
          sx={{
            border: "2px solid #FFF5F5",
            height: 22,
            bgcolor: "#D6D6D6",
          }}
        ></TableCell>
      </TableRow>
    ));
  };

  if (isLoadingCases) {
    return <TableSkeleton />;
  }

  return (
    <Stack spacing={1} p={1}>
      <Stack
        spacing={1}
        sx={{
          height: "calc(100vh - 200px)",
          overflowY: "auto",
          px: 2,
        }}
      >
        {allCases?.map((data: any, yearIndex: any) => {
          return (
            <Stack key={data?.year}>
              <Typography textAlign={"center"} variant="h4">
                {data?.year}
              </Typography>
              <Stack
                direction={"row"}
                sx={{ height: "220px", overflowY: "auto" }}
              >
                {months.map((month, i) => (
                  <Table stickyHeader size="small" key={i}>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          key={month}
                          align="center"
                          sx={{
                            fontWeight: "bold",
                            backgroundColor: "#000",
                            color: "#fff",
                            border: "2px solid white",
                            position: "sticky",
                            zIndex: 15,
                            borderRadius: "5px",
                          }}
                        >
                          {month}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.months?.map((monthData: any, index: any) => {
                        const isMonthMatched =
                          Number(monthData.month) === Number(i + 1);

                        return isMonthMatched
                          ? monthData.records.map((record: any) => {
                              return (
                                <TableRow
                                  key={record._id}
                                  sx={{
                                    border: "2px solid #FFF5F5",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                    bgcolor: record?.isOldParoleDate
                                      ? "grey"
                                      : "#D6D6D6",
                                  }}
                                >
                                  <TableCell
                                    sx={{
                                      border: "2px solid #FFF5F5",
                                      borderRadius: "5px",
                                      height: 26,
                                      cursor: "pointer",
                                      padding: 0.5,
                                    }}
                                  >
                                    <Tooltip
                                      title={
                                        record?.isOldParoleDate
                                          ? "Parole eligibility has been moved."
                                          : ""
                                      }
                                    >
                                      <Stack
                                        direction={"row"}
                                        alignItems={"center"}
                                        spacing={0.5}
                                      >
                                        <IconButton
                                          disabled={record?.isOldParoleDate}
                                          onClick={() =>
                                            handleCalenderDialog(record)
                                          }
                                          sx={{ margin: 0, padding: 0 }}
                                        >
                                          <EditIcon
                                            color={
                                              record?.isOldParoleDate
                                                ? "disabled"
                                                : "error"
                                            }
                                            sx={{ fontSize: 15 }}
                                          />
                                        </IconButton>
                                        <Typography
                                          variant="subtitle2"
                                          onClick={
                                            record?.isOldParoleDate
                                              ? () => {}
                                              : () =>
                                                  handleCalenderDialog(record)
                                          }
                                          sx={{
                                            textTransform: "capitalize",
                                            "&:hover": {
                                              color: record?.isOldParoleDate
                                                ? "none"
                                                : "#d32f2f",
                                            },
                                          }}
                                        >
                                          {record.name}
                                        </Typography>
                                      </Stack>
                                    </Tooltip>
                                  </TableCell>
                                </TableRow>
                              );
                            })
                          : null;
                      })}
                      {generateEmptyCells(
                        i,
                        allCases?.[yearIndex]?.months || []
                      )}
                    </TableBody>
                  </Table>
                ))}
              </Stack>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default Calendar;
