import { Box, Stack, Tooltip, Typography } from "@mui/material";

import { IconEdit } from "@tabler/icons-react";
import { IconTrash } from "@tabler/icons-react";
import { useModal } from "mui-modal-provider";

import {
  GridActionsCellItem,
  GridColDef,
  GridPaginationModel,
  GridSortModel,
} from "@mui/x-data-grid";
import { useCallback, useRef, useState } from "react";
import useContacts from "../../../hooks/useContacts";
import AddContact from "./AddContactModal";
import DataGridTable from "../../components/DataGridTable";
import MainCard from "../../components/cards/MainCard";
import DeleteContactModal from "./DeleteContactModal";
import { debounce } from "lodash";
import moment from "moment";
import SearchField from "../../components/inputs/SearchField";
import BasicButton from "../../components/buttons/BasicButton";
import { Link } from "react-router-dom";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import useAuth from "../../../hooks/useAuth";
import useUser from "../../../hooks/useUser";
// import moment from "moment";

export default function Contacts() {
  const { showModal } = useModal();
  const { userId } = useAuth();
  const { user, isLoadingUser } = useUser(userId);
  const [search, setSearch] = useState("");
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "createdAt", sort: "desc" },
  ]);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 12,
  });

  const { contacts, deleteContact, isLoading, totalUsers } = useContacts(
    search,
    paginationModel,
    sortModel
  );

  const handleAdd = useCallback(() => {
    const modal: any = showModal(AddContact, {
      onClose: () => modal.hide(),
    });
  }, [showModal]);

  const handleEdit = useCallback(
    (data: any) => {
      const modal: any = showModal(AddContact, {
        onClose: () => modal.hide(),
        data,
      });
    },
    [showModal]
  );

  const columns: GridColDef[] = [
    {
      field: "case",
      headerName: "Case ID#",
      minWidth: 120,
      renderCell: (params: any) => {
        const isUserIncluded =
          params?.row?.case?.createdBy === user._id ||
          params?.row?.case?.lawyer === user._id ||
          params?.row?.case?.secondaryLawyer === user._id ||
          params?.row?.case?.staff === user._id ||
          user?.role === "ADMIN";
        return params?.row?.case ? (
          !isUserIncluded ? (
            <Tooltip title="You are not part of this case.">
              {params?.row?.case?.caseId}
            </Tooltip>
          ) : (
            <Link
              to={`/cases/${params?.row?.case?._id}`}
              style={{ color: "#008EBB", textDecoration: "none" }}
            >
              {params?.row?.case?.caseId}
            </Link>
          )
        ) : (
          // </Typography>
          "-"
        );
      },
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      minWidth: 160,
      renderCell: (params: any) => capitalizeFirstLetter(params?.row?.lastName),
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    {
      field: `firstName`,
      headerName: "First Name",
      minWidth: 160,
      renderCell: (params: any) =>
        capitalizeFirstLetter(params?.row?.firstName),
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 250,
      flex: 1,
      renderCell: (params: any) => params?.row?.email?.[0]?.email,
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    {
      field: "tdcj",
      headerName: "TDCJ",
      minWidth: 100,
      renderCell: (params: any) => params?.row?.tdcj || "-",
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    {
      field: "sid",
      headerName: "SID",
      minWidth: 100,
      renderCell: (params: any) => params?.row?.sid || "-",
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    {
      field: "unit",
      headerName: "Unit",
      minWidth: 180,
      renderCell: (params: any) => params?.row?.unit || "-",
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    // {
    //   field: "unit_location",
    //   headerName: "Unit Location",
    //   minWidth: 350,
    //   flex: 1,
    //   renderCell: (params: any) => params?.row?.unit_location || "-",
    //   renderHeader: (params: any) => params?.colDef?.headerName,
    // },
    {
      field: "phone",
      headerName: "Phone",
      minWidth: 160,
      renderCell: (params: any) => params?.row?.phone?.[0]?.phone,
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    {
      field: "createdBY",
      headerName: "Created By",
      minWidth: 160,
      renderCell: (params: any) =>
        `${params?.row?.createdBy?.lastName} ${params?.row?.createdBy?.firstName}`,
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      minWidth: 150,
      renderCell: (params: any) => (
        <Typography variant={window.innerWidth < 1420 ? "body2" : "body1"}>
          {moment(params?.row?.createdAt).format("MM/DD/YYYY, h:mm a")}
        </Typography>
      ),
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    // {
    //   field: "updatedAt",
    //   headerName: "Updated At",
    //   minWidth: 180,
    //   renderCell: (params: any) => (
    //     <Typography variant={window.innerWidth < 1420 ? "body2" : "body1"}>
    //       {moment(params?.row?.updatedAt).format("MM/DD/YYYY, h:mm a")}
    //     </Typography>
    //   ),
    //   renderHeader: (params: any) => params?.colDef?.headerName,
    // },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      minWidth: 160,
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            // onClick={handleEdit()}
            icon={<IconEdit />}
            label="view"
            onClick={() => handleEdit(row)}
          />,

          <GridActionsCellItem
            icon={<IconTrash />}
            label="view"
            color="error"
            onClick={async () => {
              const modal: any = showModal(DeleteContactModal, {
                onClose: () => modal.hide(),
                id: row?._id,
                deleteContact,
                isLoading,
              });
            }}
          />,
        ];
      },
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
  ];

  // Add debounce to hold render events
  const debouncedSetSearch = useRef(
    debounce((value) => setSearch(value), 400)
  ).current;

  const handleSearchChange = useCallback(
    (event: any) => {
      debouncedSetSearch(event.target.value);
    },
    [debouncedSetSearch]
  );

  const handleSortModelChange = (newModel: any[]) => {
    setSortModel(newModel);
  };

  return (
    <Box>
      <MainCard
        title={
          <Typography variant={window.innerWidth < 1420 ? "h2" : "h1"}>
            Support Contacts
          </Typography>
        }
        actions={
          <Stack direction={"row"} alignItems={"center"} spacing={2}>
            <SearchField
              onChange={handleSearchChange}
              size="small"
              placeholder={"Search contact"}
            />

            <BasicButton
              label={"Add Contact"}
              variant="contained"
              color="success"
              onClick={handleAdd}
              size={window.innerWidth < 1420 ? "small" : "medium"}
            />
          </Stack>
        }
        content={
          <DataGridTable
            onPaginationModelChange={setPaginationModel}
            paginationModel={paginationModel}
            column={columns}
            rows={contacts}
            isLoading={isLoading}
            totalRows={totalUsers}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
          />
        }
      />
    </Box>
  );
}
