import { Box, Button, DialogProps } from "@mui/material";
import BasicModal from "../../components/modal/BasicModal";
import { useSnackbarHelper } from "../../components/Snackbar";
import useTimeEntry from "../../../hooks/useTimeEntry";

interface Props extends DialogProps {
  onClose: () => void;
  id?: any;
}

const DeleteTimeEntryModal = ({ onClose, id, ...props }: Props) => {
  const showSnackbar = useSnackbarHelper();
  const { deleteTimeEntry, isLoading } = useTimeEntry({});
  const onSubmit = async () => {
    try {
      const response = await deleteTimeEntry(id);
      if (response?.status === 200) {
        const message = "Time Entry deleted successfully";
        showSnackbar(message, "success");
      }
      onClose();
    } catch (error: any) {
      const message = error?.response?.data?.message || "An error occurred";
      showSnackbar(message, "error");
    }
  };

  return (
    <Box>
      <BasicModal
        onClose={onClose}
        title="Delete Time Entry"
        content="Do you really want to delete this Time Entry?"
        actions={
          <>
            <Button
              variant="contained"
              color="error"
              sx={{ textTransform: "capitalize" }}
              onClick={onSubmit}
              disabled={isLoading}
            >
              Delete
            </Button>
            <Button
              variant="outlined"
              color="error"
              sx={{ textTransform: "none" }}
              onClick={() => onClose()}
            >
              Cancel
            </Button>
          </>
        }
        {...props}
      />
    </Box>
  );
};

export default DeleteTimeEntryModal;
