import { yupResolver } from "@hookform/resolvers/yup";
import { Button, DialogProps, Grid, Typography } from "@mui/material";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import {
  COUNT_TYPES,
  EVENTS,
  TIME_FRAME,
} from "../../../constants/MenuSelection";
import useWorkflows from "../../../hooks/useWorkflows";
import { useSnackbarHelper } from "../../components/Snackbar";
import BasicAutocomplete from "../../components/inputs/BasicAutocomplete";
import MuiTextField from "../../components/inputs/MuiTextField";
import BasicModal from "../../components/modal/BasicModal";

interface Props extends DialogProps {
  onClose: () => void;
  data?: any;
}

const AddWorkFlowModal = ({ onClose, data, ...props }: Props) => {
  const showSnackbar = useSnackbarHelper();
  const { addWorkflow, isAddingWorkflow, updateWorkflow, isUpdatingWorkflow } =
    useWorkflows(data?._id);

  const initialValues = useMemo(
    () => ({
      name: data?.name || "",
      description: data?.description || "",
      intervalValue: data?.triggerOn?.intervalValue || "0",
      intervalUnit: data?.triggerOn?.intervalUnit || "days",
      conditionType: data?.triggerOn?.conditionType || "after",
      event: data?.triggerOn?.event || "",
    }),
    [data]
  );

  const schema: any = Yup.object({
    name: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    intervalValue: Yup.string()
      .matches(/^\d+$/, "Must be a positive number")
      .required("Required"),
    intervalUnit: Yup.string().required("Required"),
    conditionType: Yup.string().required("Required"),
    event: Yup.string().required("Required"),
  });

  const formContext = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });

  const handleSubmit = async (values?: any) => {
    try {
      const response = !!data
        ? await updateWorkflow(values)
        : await addWorkflow(values);

      const message = response?.message || "Success";

      showSnackbar(message, "success");
      onClose();
    } catch (error: any) {
      showSnackbar(error?.response?.data?.message, "error");
    }
  };

  return (
    <>
      <BasicModal
        isLoader={false}
        formContext={formContext}
        onClose={onClose}
        onSubmit={handleSubmit}
        title={!!data ? "Update Workflow" : "Add Workflow"}
        content={
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MuiTextField
                fullWidth
                label="Workflow Name"
                name="name"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <MuiTextField
                fullWidth
                label="Workflow Description"
                name="description"
                required
                multiline
                rows={4}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>Trigger on:</Typography>
            </Grid>
            <Grid item xs={6}>
              <MuiTextField
                label=""
                name="intervalValue"
                type="number"
                inputProps={{ min: 0 }}
              />
            </Grid>
            <Grid item xs={6}>
              <BasicAutocomplete
                label=""
                name="intervalUnit"
                options={COUNT_TYPES}
                getOptionValue={(option: any) => option?.value}
              />
            </Grid>
            <Grid item xs={6}>
              <BasicAutocomplete
                label=""
                name="conditionType"
                options={TIME_FRAME}
                getOptionValue={(option: any) => option?.value}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <BasicAutocomplete
                label="Event"
                name="event"
                options={EVENTS}
                getOptionValue={(option: any) => option?.value}
                disabled={!!data}
              />
            </Grid>
          </Grid>
        }
        actions={
          <>
            <Button
              color="error"
              variant="outlined"
              onClick={onClose}
              sx={{ textTransform: "none" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{ textTransform: "capitalize" }}
              type="submit"
              disabled={isAddingWorkflow || isUpdatingWorkflow}
            >
              {!!data ? "Update" : "Save"}
            </Button>
          </>
        }
        {...props}
      />
    </>
  );
};

export default AddWorkFlowModal;
