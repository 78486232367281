import { Box, Stack } from "@mui/material";

import * as Yup from "yup";
import FormContainer from "../FormContainer";
import MuiTextField from "../inputs/MuiTextField";
import AnimateButton from "../extended/AnimateButton";
import Password from "../Password";
import useUser from "../../../hooks/useUser";
import SubmitButton from "../buttons/SubmitButton";
import { useSnackbarHelper } from "../Snackbar";

// ===============================|| JWT LOGIN ||=============================== //

const JWTLogin = () => {
  const { userLogin, isLoggingIn } = useUser();
  const showSnackbar = useSnackbarHelper();

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Must be a valid email")
      .max(255)
      .required("Required")
      .matches(emailRegex, "Must be a valid email"),
    password: Yup.string().max(255).required("Required"),
  });

  type FormValues = Yup.InferType<typeof validationSchema>;

  const initialValues = {
    email: "",
    password: "",
  };

  const onFormSubmit = async (values: FormValues) => {
    try {
      const payload = { ...values, isSite: true };
      const data = await userLogin(payload);

      const message = data?.message || "Success";

      showSnackbar(message, "success");
    } catch (error: any) {
      const message = error?.response?.data?.message || "An error occurred";

      showSnackbar(message, "error");
    }
  };

  return (
    <FormContainer
      validation={validationSchema}
      defaultValues={initialValues}
      onSuccess={onFormSubmit}
    >
      <Stack sx={{ mb: 1 }} spacing={2}>
        <Box>
          <MuiTextField
            name="email"
            type="text"
            placeholder="Enter your email"
            required
            label="Email"
            InputLabelProps={{
              shrink: true, // This makes the label shrink
            }}
          />
        </Box>

        <Box>
          {/* <InputLabel >Password</InputLabel> */}
          <Password
            name="password"
            placeholder="Enter your password"
            label="Password"
            required
            InputLabelProps={{
              shrink: true, // This makes the label shrink
            }}
          />
        </Box>
      </Stack>

      <Box sx={{ mt: 2 }}>
        <AnimateButton>
          <SubmitButton
            type="submit"
            variant="contained"
            fullWidth
            size="large"
            disabled={isLoggingIn}
          >
            Login
          </SubmitButton>
          {/* <Button  
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            sx={{
              borderRadius: "10px",
              backgroundColor:color.dark,
              fontFamily:"Poppins"
            }}
            disabled={isLoggingIn}
          >
            Login
          </Button> */}
        </AnimateButton>
      </Box>
    </FormContainer>
  );
};

export default JWTLogin;
