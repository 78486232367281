import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, DialogProps, Grid } from "@mui/material";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import usePayment from "../../../../hooks/usePayment";
import BasicDatePicker from "../../../components/inputs/BasicDatePicker";
import MuiTextField from "../../../components/inputs/MuiTextField";
import NumberField from "../../../components/inputs/NumberField";
import Select from "../../../components/inputs/Select";
import BasicModal from "../../../components/modal/BasicModal";
import { useSnackbarHelper } from "../../../components/Snackbar";

interface Props extends DialogProps {
  onClose: () => void;
  caseData?: any;
  caseId?: any;
  value?: any;
}
function AddScheduledPaymentsModal({
  onClose,
  caseData,
  caseId,
  value,

  ...props
}: Props) {
  const { addPaymentScheduling, updateScheduling, isPaymentLoading } =
    usePayment(caseId?.id);
  const [paymentSpread, setPaymentSpread] = useState<any>([]);
  const showSnackbar = useSnackbarHelper();
  const initialValues = useMemo(
    () => ({
      startDate:
        value?.startDate || caseData?.paroleHiringDateTime
          ? new Date(caseData?.paroleHiringDateTime)
          : new Date(),
      paymentInterval: value?.paymentInterval || "",
      type: value?.type || "",
      amount: value?.amount || "",
      noOfPayments: value?.noOfPayments || "",
    }),
    [value, caseData]
  );
  const validationSchema = Yup.object({
    startDate: Yup.date().required("Required"),
    paymentInterval: Yup.string().required("Required"),
    type: Yup.string().required("Required"),
    amount: Yup.string().required("Required"),
    noOfPayments: Yup.string().required("Required"),
  });
  const formContext = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });
  const { watch, setValue } = formContext;

  const startDate = watch("startDate");
  useEffect(() => {
    if (startDate) {
      // Reset other values when `startDate` changes
      setValue("paymentInterval", "");
      setValue("type", "");
      setValue("amount", "");
      setValue("noOfPayments", "");
    }
  }, [startDate, setValue]);

  const start_date = moment(startDate);

  const handleSubmit = async (values?: any) => {
    try {
      const paymentScheduling = {
        CASE_ID: caseData?.caseId,
        caseId: caseId?.id || "",
        clientId: caseData?.contactId?._id || value?.clientId?._id,
        startDate: values?.startDate || new Date(),
        paymentInterval: values?.paymentInterval,
        type: values?.type,
        amount: values?.amount,
        noOfPayments: values?.noOfPayments,
        feeQuoted: caseData?.feeQuoted || value?.feeQuoted || "",
        hiringDate: caseData?.paroleHiringDateTime || value?.hiringDate,
        paroleEligibilityDate:
          caseData?.paroleEligibilityDate || value?.paroleEligibilityDate,
        payment_spreads: paymentSpread,
        isPaymentScheduled: true,
      };
      let response;
      if (value) {
        response = await updateScheduling({
          schedulingId: value?._id,
          payload: paymentScheduling,
        });
      } else {
        response = await addPaymentScheduling(paymentScheduling);
      }
      const message = response?.data?.message || "Success";
      showSnackbar(message, "success");
      onClose();
    } catch (error: any) {
      showSnackbar(error?.response?.data?.message, "error");
    }
  };

  const paroleEligibilityDate = new Date(
    caseData?.paroleEligibilityDate || value?.paroleEligibilityDate
  );
  // const diffInMonths =
  //   (paroleEligibilityDate.getFullYear() - startDate.getFullYear()) * 12 +
  //   paroleEligibilityDate.getMonth() -
  //   startDate.getMonth();

  const diffInMonths = moment(paroleEligibilityDate).diff(
    startDate,
    "months",
    true
  );

  const roundedMonths = Math.floor(diffInMonths);

  const isLessThanOneMonth = roundedMonths <= 1;

  const onChange = (event: any) => {
    const intervalValue = event.target.value;
    // console.log("onChange", intervalValue);
    setValue("paymentInterval", intervalValue);

    if (!start_date.isBefore(paroleEligibilityDate)) {
      showSnackbar(
        "Eligibility date or hiring date is not valid. Please check.",
        "error"
      );
      return;
    }

    const oneMonthBeforeEligibility = new Date(paroleEligibilityDate);
    oneMonthBeforeEligibility.setMonth(
      oneMonthBeforeEligibility.getMonth() - 1
    );

    const feeAmount = Number(caseData?.feeQuoted || value?.feeQuoted);
    let payments = [];
    if (intervalValue === "full" || isLessThanOneMonth) {
      setValue("amount", feeAmount);
      setValue("noOfPayments", 1);
      const dueDate = new Date(startDate);
      // console.log("dueDate", dueDate.getMonth());
      dueDate.setMonth(dueDate.getMonth());

      payments.push({
        payment: feeAmount,
        isPaid: false,
        dueDate: dueDate,
      });

      setPaymentSpread(payments);
      return;
    }

    let totalIntervalDifference = 0;

    if (intervalValue === "monthly") {
      // Calculate the difference in months
      const yearsDifference =
        oneMonthBeforeEligibility.getFullYear() - startDate.getFullYear();
      const monthsDifference =
        oneMonthBeforeEligibility.getMonth() - startDate.getMonth();
      totalIntervalDifference = yearsDifference * 12 + monthsDifference || 0;
    } else if (intervalValue === "weekly") {
      // Calculate the difference in weeks
      const millisecondsPerWeek = 7 * 24 * 60 * 60 * 1000; // Number of milliseconds in a week
      const timeDifference =
        oneMonthBeforeEligibility.getTime() - startDate.getTime(); // Convert dates to timestamps
      totalIntervalDifference = Math.floor(
        timeDifference / millisecondsPerWeek || 0
      );
    }

    const paymentSpread =
      totalIntervalDifference > 0 ? feeAmount / totalIntervalDifference : 0;
    const roundedPaymentSpread = Math.ceil(Number(paymentSpread));

    const checkPayment = roundedPaymentSpread * totalIntervalDifference;
    const findDifference = feeAmount - checkPayment;
    // const payments = [];
    for (let i = 0; i < totalIntervalDifference; i++) {
      const dueDate = new Date(startDate);
      dueDate.setMonth(dueDate.getMonth() + 1 + i);

      payments.push({
        payment: roundedPaymentSpread,
        isPaid: false,
        dueDate: dueDate,
      });
    }

    // Add the difference to the last payment
    if (totalIntervalDifference > 0) {
      payments[totalIntervalDifference - 1].payment += findDifference;
    }

    setPaymentSpread(payments);

    setValue("amount", roundedPaymentSpread);
    setValue("noOfPayments", totalIntervalDifference);
  };

  return (
    <BasicModal
      formContext={formContext}
      onClose={onClose}
      onSubmit={handleSubmit}
      title={"Scheduled Payment(s)"}
      content={
        <Box>
          <Grid
            container
            spacing={2}
            sx={{
              "& .outlined .MuiOutlinedInput-notchedOutline": {
                border: "2px solid #AFD2F0 !important",
              },
            }}
          >
            <Grid item xs={6}>
              <BasicDatePicker
                name="startDate"
                label="Start Date"
                required
                // required={true}
                // helperText={"Date cannot be null"}
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                required
                label="Payment Interval"
                name="paymentInterval"
                onChange={(event) => onChange(event)}
                options={
                  isLessThanOneMonth
                    ? [{ label: "Full", value: "full" }]
                    : [
                        { label: "Monthly", value: "monthly" },
                        { label: "Weekly", value: "weekly" },
                      ]
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                label="Type"
                name="type"
                required
                options={[
                  // { label: "Credit Card", value: "creditcard" },
                  { label: "Cheque", value: "cheque" },
                  { label: "Cash", value: "cash" },
                  // { label: "Money Order", value: "moneyOrder" },
                  // { label: "Paypal", value: "paypal" },
                  // { label: "Venmo", value: "venmo" },
                  { label: "Law pay", value: "lawpay" },
                  // { label: "Cash App", value: "cashapp" },
                  // { label: "Refund", value: "refund" },
                  // { label: "Other", value: "other" },
                  // { label: "Edit", value: "edit" },
                ]}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <NumberField
                label="Amount"
                name="amount"
                disabled
                required
                prefix="$ "
                decimalScale={2}
                fixedDecimalScale={true}
                // options={Task}
              />
            </Grid>
            <Grid item xs={6}>
              {/* <TextField multiline name="notes" label="Notes" rows={2} /> */}
              <MuiTextField
                name="noOfPayments"
                required
                label="No. Of Payments"
                disabled
              />
            </Grid>
          </Grid>
        </Box>
      }
      actions={
        <>
          <Button
            color="error"
            variant="outlined"
            onClick={onClose}
            disabled={isPaymentLoading}
            sx={{ textTransform: "none" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            sx={{ textTransform: "capitalize" }}
            type="submit"
            disabled={isPaymentLoading}
          >
            {!!value ? "Update" : "Add"}
          </Button>
        </>
      }
      {...props}
    />
  );
}

export default AddScheduledPaymentsModal;
