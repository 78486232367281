import { useCallback, useEffect, useMemo, useRef, useState } from "react";

//Components
import * as Yup from "yup";
import { useSnackbarHelper } from "../../components/Snackbar";
import { uploadImageToS3 } from "../../components/common/uploadImages";
import BasicModal from "../../components/modal/BasicModal";
import TableSkeleton from "../../components/skeleton/TableSkeleton";
import CropImageModal from "../accountSetting/CropImageModal";

//MUI
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  DialogProps,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { useModal } from "mui-modal-provider";

//Tabler
import { yupResolver } from "@hookform/resolvers/yup";
import { IconCamera } from "@tabler/icons-react";
import { debounce } from "lodash";
import { useForm } from "react-hook-form";
import { ROLE } from "../../../constants/MenuSelection";
import useChat from "../../../hooks/useChat";
import MuiTextField from "../../components/inputs/MuiTextField";
import SearchField from "../../components/inputs/SearchField";

/**
 * To update Group profile (Name and Profile picture)
 * @component UpdateGroupModal
 * @Author Sanjay
 */

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));
interface Props extends DialogProps {
  onClose: () => void;
  id?: string;
  isLoading?: boolean;
  chatId?: string;
  onlineUsers?: any;
  user?: any;
}

const UpdateGroupModal = ({
  onClose,
  chatId,
  user,
  onlineUsers,
  ...props
}: Props) => {
  const [isMembersPage, setMembersPage] = useState(false);
  const [members, setMembers] = useState<any>([]);
  const [deletedMembers, setDeletedMembers] = useState<any>([]);
  const { updateGroup, isUpdatingGroup, chat, isLoadingChat } = useChat(chatId);

  const [isUploadingImage, setIsUploadingImage] = useState<boolean>(false);

  const showSnackbar = useSnackbarHelper();
  const { showModal } = useModal();

  const [image, setImage] = useState<any>("");
  const fileInputRef = useRef<any>(null);

  useEffect(() => {
    if (chat?.groupImageUrl) {
      setImage(chat?.groupImageUrl);
    }
  }, [chat]);

  const initialValues = useMemo(
    () => ({
      isGroupChat: chat?.isGroupChat || false,
      members: chat?.members,
      groupName: chat?.groupName || "",
      groupImageUrl: chat?.groupImageUrl || "",
    }),
    [chat]
  );

  const schema: any = Yup.object({
    groupName: Yup.string().required("Required"),
    groupImageUrl: Yup.string(),
    isGroupChat: Yup.boolean(),
    members: Yup.array(),
  });

  const formContext = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });

  const { setValue } = formContext;

  const handleSubmit = async (values?: any) => {
    try {
      const memberIds = values?.members
        ?.map((member: any) => member?._id)
        ?.concat(members)
        ?.filter((id: string) => !deletedMembers.includes(id));
      const response = await updateGroup({ ...values, members: memberIds });

      const message = isMembersPage
        ? "Members added successfully"
        : response?.message || "Success";

      showSnackbar(message, "success");

      if (isMembersPage) {
        setMembersPage(false);
      } else {
        onClose();
      }
    } catch (error: any) {
      showSnackbar(error?.response?.data?.message, "error");
    }
  };

  const handleRemove = async (memberId: string) => {
    try {
      const memberIds = initialValues?.members
        ?.map((member: any) => member?._id)
        ?.filter((Id: string) => Id !== memberId);
      setDeletedMembers((prev: any) => [...prev, memberId]);
      const response = await updateGroup({
        ...initialValues,
        members: memberIds,
      });

      const message = "Member removed successfully";

      showSnackbar(message, "success");
    } catch (error: any) {
      showSnackbar(error?.response?.data?.message, "error");
    }
  };

  //handle group image start-------------------

  const handleCrop = useCallback(
    (preview: any, handleCroppedFileChange: any) => {
      const modal: any = showModal(CropImageModal, {
        onClose: () => modal.hide(),
        preview,
        handleCroppedFileChange,
      });
    },
    [showModal]
  );

  const handleAvatarClick = () => {
    fileInputRef.current.click();
  };

  const handleCroppedFileChange = async (croppedFile: any) => {
    const folderName = "groupChatIcon";
    const data = {
      file: croppedFile,
      userId: chat._id,
      folderName: folderName,
    };
    setIsUploadingImage(true);
    const res = await uploadImageToS3(data);
    setIsUploadingImage(false);
    if (!res?.Location) {
      return showSnackbar("Error while uploading profile image", "error");
    }
    try {
      setImage(res?.Location);
      setValue("groupImageUrl", res?.Location);
    } catch (error: any) {
      const message = error?.response?.data?.message || "An error occurred";
      showSnackbar(message, "error");
    }
  };

  const handleFileChange = async (event: any) => {
    const file = event?.target?.files[0];
    if (file?.size > 5 * 1024 * 1024) {
      return showSnackbar("File size should be less than 5MB", "error");
    }
    if (file) {
      const preview = URL.createObjectURL(file);
      handleCrop(preview, handleCroppedFileChange);
    }
    event.target.value = "";
  };

  const handleCancel = () => {
    if (isMembersPage) {
      setMembersPage(false);
    } else {
      onClose();
    }
  };

  return (
    // <Box>
    <BasicModal
      onClose={onClose}
      formContext={formContext}
      onSubmit={handleSubmit}
      title={
        user?.role === "ADMIN"
          ? isMembersPage
            ? "Add Members"
            : "Edit Group"
          : "View Group"
      }
      content={
        isLoadingChat ? (
          <TableSkeleton />
        ) : (
          <>
            {isMembersPage ? (
              <Members
                chatId={chat?._id}
                checked={members}
                setChecked={setMembers}
              />
            ) : (
              <>
                <Grid
                  container
                  spacing={1}
                  sx={{
                    "& .changeRadius .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "12px !important",
                      // "&.Mui-focused fieldset": {
                      //   borderColor: "#6F7E8C",
                      // },
                    },
                  }}
                >
                  <Grid item xs={12}>
                    <Stack
                      direction="column"
                      spacing={2}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Box
                        sx={{
                          position: "relative",
                          display: "inline-block",
                        }}
                      >
                        {isUploadingImage ? (
                          <Box
                            sx={{
                              width: 120,
                              height: 120,
                              border: "2px solid  #008EBB",
                              cursor: "pointer",
                              borderRadius: 15,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CircularProgress />
                          </Box>
                        ) : (
                          <Avatar
                            sx={{
                              width: 120,
                              height: 120,
                              border: "2px solid  #008EBB",
                              cursor:
                                user?.role === "ADMIN" ? "pointer" : "default",
                            }}
                            src={image}
                            onClick={
                              user?.role === "ADMIN"
                                ? handleAvatarClick
                                : () => {}
                            }
                          />
                        )}

                        {user?.role === "ADMIN" && (
                          <IconButton
                            color="primary"
                            onClick={handleAvatarClick}
                            disableRipple
                            sx={{
                              position: "absolute",
                              bottom: 0,
                              right: 10,
                              backgroundColor: "#008EBB",
                              border: "1px solid #008EBB",
                              color: "#fff",
                              padding: "2px",
                            }}
                          >
                            <IconCamera stroke={2} size={18} />
                          </IconButton>
                        )}
                      </Box>
                      <input
                        type="file"
                        name="groupImageUrl"
                        accept="image/*"
                        style={{ display: "none" }}
                        ref={fileInputRef}
                        onChange={(event) => handleFileChange(event)}
                      />
                    </Stack>
                  </Grid>
                  {user?.role !== "ADMIN" ? (
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        fontWeight={"bold"}
                        textAlign={"center"}
                      >
                        {chat?.groupName}
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={12}
                      p={1}
                      display="flex"
                      justifyContent="flex-end"
                      columnGap={user?.role !== "ADMIN" ? 22 : 12}
                      mt={2}
                    >
                      <MuiTextField
                        placeholder="Group Name"
                        variant="standard"
                        name="groupName"
                        width="200"
                        inputProps={{ maxLength: 20 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <EditIcon />
                            </InputAdornment>
                          ),
                        }}
                      />

                      {user?.role === "ADMIN" && (
                        <Tooltip
                          title="Add members"
                          onClick={() => setMembersPage(true)}
                        >
                          <IconButton>
                            <GroupAddIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Box maxHeight={200} sx={{ padding: 1, overflowY: "auto" }}>
                      {chat?.members?.length &&
                        chat?.members?.map((member?: any) => {
                          const isOnline = onlineUsers?.includes(member?._id);
                          return (
                            <Card
                              variant="outlined"
                              key={member?._id}
                              sx={{
                                padding: 1,
                                display: "flex",
                                alignItems: "center",
                                border: "1px solid lightgray",
                                borderRadius: "10px",
                                mb: 1,
                              }}
                            >
                              <Stack
                                direction={"row"}
                                alignItems="center"
                                sx={{ width: "100%" }}
                              >
                                {isOnline ? (
                                  <StyledBadge
                                    overlap="circular"
                                    anchorOrigin={{
                                      vertical: "top",
                                      horizontal: "right",
                                    }}
                                    variant="dot"
                                  >
                                    <Avatar src={member?.profileImg} />
                                  </StyledBadge>
                                ) : (
                                  <Avatar src={member?.profileImg} />
                                )}

                                <Stack
                                  direction={"row"}
                                  justifyContent="space-between"
                                  alignItems={"center"}
                                  px={1}
                                  sx={{ width: "100%" }}
                                >
                                  <Stack>
                                    <Stack direction={"row"} spacing={1}>
                                      <Typography
                                        variant="body1"
                                        fontWeight="bold"
                                      >
                                        {member?._id === user?._id
                                          ? "You"
                                          : `${member?.firstName} ${member?.lastName}`}
                                      </Typography>
                                      {!member?.isActive && (
                                        <Typography color={"error"}>
                                          (Inactive)
                                        </Typography>
                                      )}
                                    </Stack>
                                    <Typography variant="caption">
                                      {member?.role}
                                    </Typography>
                                  </Stack>
                                  <Stack>
                                    {isOnline && (
                                      <Typography
                                        variant="body2"
                                        color={"green"}
                                      >
                                        Online
                                      </Typography>
                                    )}

                                    {user?.role === "ADMIN" &&
                                      member?._id !== user?._id && (
                                        <Tooltip title="Remove member">
                                          <IconButton
                                            onClick={() =>
                                              handleRemove(member?._id)
                                            }
                                          >
                                            <DeleteOutlineIcon color="error" />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                  </Stack>
                                </Stack>
                              </Stack>
                            </Card>
                          );
                        })}
                    </Box>
                  </Grid>
                </Grid>
              </>
            )}
          </>
        )
      }
      actions={
        <>
          <Button
            variant="outlined"
            color="error"
            sx={{ textTransform: "capitalize" }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          {user?.role === "ADMIN" && (
            <Button
              color="success"
              type="submit"
              variant="contained"
              sx={{ textTransform: "capitalize" }}
              disabled={isUpdatingGroup || (isMembersPage && !members?.length)}
            >
              {isMembersPage ? "Add" : "Update"}
            </Button>
          )}
        </>
      }
      {...props}
    />
    // </Box>
  );
};

const Members = ({ chatId, checked, setChecked }: any) => {
  const [searchMembers, setSearchMembers] = useState("");
  const { members, isLoadingMembers } = useChat(
    chatId,
    undefined,
    undefined,
    undefined,
    searchMembers
  );

  const handleSelectedMembers = (value: string) => () => {
    const isDuplicateId = checked.includes(value);

    if (isDuplicateId) {
      const filteredArray = checked.filter((Id: string) => Id !== value);
      setChecked(filteredArray);
    } else {
      setChecked((prev: any) => [...prev, value]);
    }
  };

  // Add debounce to hold render events
  const debouncedSetSearch = useRef(
    debounce((value) => setSearchMembers(value), 400)
  ).current;

  const handleSearchChange = useCallback(
    (event: any) => {
      debouncedSetSearch(event.target.value);
    },
    [debouncedSetSearch]
  );

  return (
    <Box
      height={400}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Stack spacing={1} width={"100%"}>
        <SearchField
          fullWidth
          onChange={handleSearchChange}
          size="small"
          placeholder="Search members"
        />

        {isLoadingMembers ? (
          <TableSkeleton />
        ) : (
          <List
            dense
            sx={{
              maxHeight: 350,
              overflowY: "auto",
            }}
          >
            {members?.map((member: any) => {
              const labelId = `checkbox-${member?._id}`;
              return (
                <ListItem
                  key={member?._id}
                  secondaryAction={
                    <Checkbox
                      edge="end"
                      onChange={handleSelectedMembers(member?._id)}
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  }
                  disablePadding
                >
                  <ListItemButton>
                    <ListItemAvatar>
                      <Avatar
                        src={member?.profileImg || "/default-avatar.png"}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      id={labelId}
                      primary={member?.fullName || "-"}
                      secondary={
                        ROLE?.find((role: any) => role?.value === member?.role)
                          ?.label
                      }
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        )}
      </Stack>
    </Box>
  );
};

export default UpdateGroupModal;
