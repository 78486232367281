import { useMutation } from "react-query";
import useAuth from "./useAuth";

export default function useOtp() {
  const { request } = useAuth();

  const { mutateAsync: sendOtp, isLoading: isSendingOtp } = useMutation(
    "/send-otp",
    (payload: any) =>
      request
        .post("/send-otp", payload)
        .then((response) => response?.data as any)
  );

  const { mutateAsync: verifyOtp, isLoading: isVerifyingOtp } = useMutation(
    "/verify-otp",
    (payload: any) =>
      request
        .post("/verify-otp", payload)
        .then((response) => response?.data as any)
  );

  const { mutateAsync: resendOtp, isLoading: isResendingOtp } = useMutation(
    "/resend-otp",
    (payload: any) =>
      request
        .post("/resend-otp", payload)
        .then((response) => response?.data as any)
  );

  return {
    sendOtp,
    isSendingOtp,
    verifyOtp,
    isVerifyingOtp,
    resendOtp,
    isResendingOtp,
  };
}
