import FormContainer from "../FormContainer";
import MuiTextField from "../inputs/MuiTextField";
// material-ui
import { Box, Stack } from "@mui/material";

// project imports
import AnimateButton from "../extended/AnimateButton";
// third party
import * as Yup from "yup";
import usePasswordReset from "../../../hooks/usePasswordReset";
import SubmitButton from "../buttons/SubmitButton";
import { useSnackbarHelper } from "../Snackbar";

// ========================|| FIREBASE - FORGOT PASSWORD ||======================== //

/**
 * This component provides a UI for users to request a password reset.
 * @component ForgotPassword
 * @author Sanjay
 *
 */

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const JWTForgotPassword = () => {
  const { passwordReset, isLoading } = usePasswordReset();
  const showSnackbar = useSnackbarHelper();
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Must be a valid email")
      .max(255)
      .required("Required")
      .matches(emailRegex, "Must be a valid email"),
  });

  type FormValues = Yup.InferType<typeof validationSchema>;

  const initialValues = {
    email: "",
  };

  const onFormSubmit = async (values: FormValues) => {
    const { email } = values;
    try {
      const data = await passwordReset({ email });
      const message = data?.message || "Success";
      showSnackbar(message, "success");
    } catch (error: any) {
      const message = error?.response?.data?.message || "An error occurred";
      showSnackbar(message, "error");
    }
  };

  return (
    <FormContainer
      validation={validationSchema}
      defaultValues={initialValues}
      onSuccess={onFormSubmit}
    >
      <Stack sx={{ mb: 2 }} spacing={1}>
        <MuiTextField
          name="email"
          //   onKeyUp={checkEmailFirst}
          type="email"
          label="Email"
          required
        />
      </Stack>

      <Box sx={{ mt: 2 }}>
        <AnimateButton>
          <SubmitButton
            disableElevation
            fullWidth
            disabled={isLoading}
            size="large"
            type="submit"
            variant="contained"
          >
            Send Mail
          </SubmitButton>
          {/* <Button
            disableElevation
            fullWidth
            disabled={isLoading}
            color="error"
            size="large"
            type="submit"
            variant="contained"
            sx={{
              borderRadius: "10px",
            }}
          >
            Send Mail
          </Button> */}
        </AnimateButton>
      </Box>
    </FormContainer>
  );
};

export default JWTForgotPassword;
