import { Box, Button, DialogProps, Stack } from "@mui/material";
import { useState } from "react";
import Select from "../../components/inputs/Select";
import BasicModal from "../../components/modal/BasicModal";
// import { Textarea } from "@mui/joy";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { useSnackbarHelper } from "../../components/Snackbar";
import MuiTextField from "../../components/inputs/MuiTextField";
interface Props extends DialogProps {
  onClose: () => void;
  AddDetailOfInteraction?: any;
  isAddingDetail?: any;
  currentValue?: any;
  userId?: string;
}

const DetailOfInteractionModal = ({
  onClose,
  currentValue,
  userId,
  AddDetailOfInteraction,
  isAddingDetail,
  ...props
}: Props) => {
  const showSnackbar = useSnackbarHelper();
  const [isAddingDetails, setIsAddingDetails] = useState(false);

  const initialValues = {
    contactMethod: "",
    detailsOfInteraction: "",
  };
  const validationSchema = Yup.object({
    contactMethod: Yup.string().required("Required"),
    detailsOfInteraction: Yup.string().required("Required"),
  });

  const formContext = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const handleSubmit = async (data: any) => {
    try {
      setIsAddingDetails(true);
      const payload = {
        leadId: currentValue?.row?.leadId,
        contactMethod: data.contactMethod,
        details: data?.detailsOfInteraction,
        user: userId,
      };
      const response = await AddDetailOfInteraction(payload);
      const message = response?.message || "Success";
      showSnackbar(message, "success");
      setIsAddingDetails(false);
      onClose();
    } catch (error: any) {
      const message = error?.response?.data?.message || "An error occurred";
      showSnackbar(message, "error");
    }
  };

  return (
    <Box>
      <BasicModal
        onSubmit={handleSubmit}
        formContext={formContext}
        onClose={onClose}
        title="Enter Details of Interaction"
        content={
          <Stack spacing={2}>
            {/* <Typography>Contact Method</Typography> */}
            <Select
              label="Contact Method"
              name="contactMethod"
              options={[
                { label: "Phone", value: "phone" },
                { label: "Email", value: "email" },
              ]}
              fullWidth
              // sx={{ width: 200 }}
            />
            {/* <InputLabel>Details of Interaction</InputLabel> */}
            <MuiTextField
              name="detailsOfInteraction"
              multiline
              minRows={2}
              label="Details of Interaction"
              placeholder="e.g. I called/emailed and..."
              // {...register("detailsOfInteraction")}
            />
          </Stack>
        }
        actions={
          <>
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{ textTransform: "capitalize" }}
              disabled={isAddingDetail || isAddingDetails}
            >
              Submit
            </Button>
            <Button
              color="error"
              variant="outlined"
              sx={{ textTransform: "capitalize" }}
              onClick={() => onClose()}
              disabled={isAddingDetail || isAddingDetails}
            >
              Cancel
            </Button>
          </>
        }
        {...props}
      />
    </Box>
  );
};

export default DetailOfInteractionModal;
