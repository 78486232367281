import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Grid, IconButton, MenuItem, Tooltip } from "@mui/material";
import { useFieldArray, useFormContext } from "react-hook-form";
import { EmailType, Suffix } from "../../../constants/MenuSelection";
import BasicAutocomplete from "../../components/inputs/BasicAutocomplete";
import MuiTextField from "../../components/inputs/MuiTextField";
import PhoneNumber from "../../components/inputs/PhoneNumber";

const AdditionalContact = ({ statesList, countryList }: any) => {
  return (
    <>
      <Grid item xs={12} sm={3} mt={2}>
        <MuiTextField
          fullWidth
          label="Suffix"
          name="additional_Support_suffix"
          select
        >
          {Suffix.map((op) => (
            <MenuItem key={op.value} value={op.value}>
              {op.label}
            </MenuItem>
          ))}
        </MuiTextField>
      </Grid>

      <Grid item xs={12} sm={9} mt={2}>
        <MuiTextField
          fullWidth
          label="First Name"
          name="additional_Support_firstName"
          required
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <MuiTextField fullWidth label="M.I." name="additional_Support_mi" />
      </Grid>
      <Grid item xs={12} sm={9}>
        <MuiTextField
          fullWidth
          label="Last Name"
          name="additional_Support_lastName"
          required
        />
      </Grid>
      <Grid item xs={12}>
        <MuiTextField
          fullWidth
          label="Nickname/Pronounce"
          name="additional_Support_nickName"
        />
      </Grid>

      <SupportContact />

      <Grid item xs={12}>
        <MuiTextField
          fullWidth
          label="Address"
          name="additional_Support_address"
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <BasicAutocomplete
          name="additional_Support_country"
          // additionalValue={false}
          label="Country"
          options={countryList}
          required
          getOptionValue={(option: any) => option?.value}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <BasicAutocomplete
          name="additional_Support_state"
          // additionalValue={false}
          label="Province/State"
          options={statesList}
          // required
          getOptionValue={(option: any) => option?.value}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MuiTextField
          fullWidth
          label="City"
          required
          name="additional_Support_city"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MuiTextField
          fullWidth
          label="Postal Code/Zip Code"
          name="additional_Support_zip"
        />
      </Grid>
    </>
  );
};

export default AdditionalContact;

const SupportContact = () => {
  const { control } = useFormContext();

  const {
    fields: emailFields,
    append: appendEmail,
    remove: removeEmail,
  } = useFieldArray({
    control,
    name: "additional_Support_email",
  });

  const {
    fields: phoneFields,
    append: appendPhone,
    remove: removePhone,
  } = useFieldArray({
    control,
    name: "additional_Support_phone",
  });

  const RenderPhonesItem = ({ i, arrayLength }: any) => {
    return (
      <>
        <Grid item xs={3.5}>
          <MuiTextField
            fullWidth
            label="Phone Type"
            name={`additional_Support_phone.${i}.phoneType`}
            select
            required
          >
            {EmailType.map((op) => (
              <MenuItem key={op.value} value={op.value}>
                {op.label}
              </MenuItem>
            ))}
          </MuiTextField>
        </Grid>
        <Grid
          item
          xs={7.5}
          sx={{
            "& .changeRadius .MuiOutlinedInput-notchedOutline": {
              borderRadius: "12px !important",
            },
          }}
        >
          {/* <MuiTextField
              fullWidth
              type="number"
              label="Cell Phone Number"
              name={`phone.${i}.phone`}
              required
            /> */}
          <PhoneNumber
            fullWidth
            label="Cell Phone Number"
            name={`additional_Support_phone.${i}.phone`}
            required
            className="changeRadius"
          />
        </Grid>

        <Grid item xs={1} sm={1}>
          {/* {arrayLength > 1 ? (
            <Tooltip title="Remove">
              <IconButton onClick={() => removePhone(i)}>
                <RemoveCircleOutlineIcon style={{ color: "red" }} />
              </IconButton>
            </Tooltip>
          ) : (
            ""
          )} */}
          {i > 0 ? (
            <Tooltip title="Remove">
              <IconButton onClick={() => removePhone(i)}>
                <RemoveCircleOutlineIcon style={{ color: "red" }} />
              </IconButton>
            </Tooltip>
          ) : i === 0 ? (
            <Tooltip title="Add Phone">
              <IconButton
                onClick={() => appendPhone({ phone: "", phoneType: "" })}
              >
                <AddCircleOutlineIcon
                  sx={{ color: "green", cursor: "pointer" }}
                />
              </IconButton>
            </Tooltip>
          ) : (
            ""
          )}
        </Grid>
      </>
    );
  };
  const RenderEmailsItem = ({ i, arrayLength }: any) => {
    return (
      <>
        <Grid item xs={3.5}>
          <MuiTextField
            fullWidth
            label="Email Type"
            name={`additional_Support_email.${i}.emailType`}
            select
            required
          >
            {EmailType.map((op, index) => (
              <MenuItem key={index} value={op.value}>
                {op.label}
              </MenuItem>
            ))}
          </MuiTextField>
        </Grid>
        <Grid item xs={7.5}>
          <MuiTextField
            fullWidth
            label="Email Address"
            name={`additional_Support_email.${i}.email`}
            required
          />
        </Grid>

        <Grid item xs={1} sm={1}>
          {/* {arrayLength > 1 ? (
            <Tooltip title="Remove">
              <IconButton onClick={() => removeEmail(i)}>
                <RemoveCircleOutlineIcon style={{ color: "red" }} />
              </IconButton>
            </Tooltip>
          ) : (
            ""
          )} */}
          {i > 0 ? (
            <Tooltip title="Remove">
              <IconButton onClick={() => removeEmail(i)}>
                <RemoveCircleOutlineIcon style={{ color: "red" }} />
              </IconButton>
            </Tooltip>
          ) : i === 0 ? (
            <Tooltip title="Add Email">
              <IconButton
                onClick={() => appendEmail({ email: "", emailType: "" })}
              >
                <AddCircleOutlineIcon
                  sx={{ color: "green", cursor: "pointer" }}
                />
              </IconButton>
            </Tooltip>
          ) : (
            ""
          )}
        </Grid>
      </>
    );
  };

  return (
    <>
      {emailFields.map((item: any, i, arr) => (
        <RenderEmailsItem key={item.id} i={i} arrayLength={arr.length} />
      ))}

      {phoneFields.map((item: any, i, arr) => (
        <RenderPhonesItem key={item.id} i={i} arrayLength={arr.length} />
      ))}
    </>
  );
};
