// Array of Suffix options.
export const Suffix = [
  { label: "Mr", value: "mr" },
  { label: "Mrs", value: "mrs" },
  { label: "Ms", value: "ms" },
];

// Array of EmailType options.
export const EmailType = [
  { label: "Home", value: "home" },
  { label: "Office", value: "office" },
  { label: "Other", value: "other" },
];

// Array of Gender options.
export const Gender = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Other", value: "other" },
];

// Array of ToDo options.
export const ToDo = [
  { label: "Ed", value: "ed" },
  { label: "Tate", value: "tate" },
  { label: "Rykar", value: "ryker" },
  { label: "Bryace", value: "bryace" },
  { label: "Jack", value: "jack" },
  { label: "Zayda", value: "zayda" },
  { label: "All", value: "all" },
];

// Array of Priority options.
export const Priority = [
  { label: "Low", value: "LOW" },
  { label: "Medium", value: "MEDIUM" },
  { label: "High", value: "HIGH" },
];

// Array of RefferalTypes options.
export const RefferalTypes = [
  { label: "Postcard", value: "Postcard" },
  { label: "Website", value: "Website" },
  { label: "Client Support", value: "client_support" },
  { label: "Attorney", value: "Attorney" },
  { label: "Client", value: "Client" },
  { label: "Others", value: "Others" },
];

// Array of LeadTemperatures options.
export const LeadTemperatures = [
  {
    label: "Hot",
    value: "hot",
  },
  {
    label: "Warm",
    value: "warm",
  },
  {
    label: "Cold",
    value: "cold",
  },
];

// Array of RepType options.
export const RepType = [
  {
    label: "InPerson",
    value: "inPerson",
  },
  {
    label: "Telephonic",
    value: "telephonic",
  },
];

// Array of AddCaseFileStatus options.
export const AddCaseFileStatus = [
  { label: "Active", value: "active" },

  { label: "Prospect", value: "prospect" },
  { label: "Retained", value: "retained" },
  { label: "InReview", value: "inreview" },
  { label: "Unretained", value: "unretained" },
];

// Array of CaseInfoFileStatus options.
export const CaseInfoFileStatus = [
  { label: "Active", value: "active" },
  { label: "Closed", value: "closed" },
  { label: "Prospect", value: "prospect" },
  { label: "Retained", value: "retained" },
  { label: "InReview", value: "inreview" },
  { label: "Unretained", value: "unretained" },
];

// Array of ParoleStatus options.
export const ParoleStatus = [
  { label: "In Review", value: "inReview" },
  { label: "Not In Review - First Time", value: "notInReview_FirstTime" },
  { label: "Not In Review - Subsequent", value: "notInReview_Subsequent" },
];

// Array of COUNT_TYPES options.
export const COUNT_TYPES = [
  { label: "Days", value: "days" },
  { label: "Business Days", value: "businessDays" },
  { label: "Weeks", value: "weeks" },
  { label: "Months", value: "months" },
  { label: "Years", value: "years" },
];

// Array of TIME_FRAME options.
export const TIME_FRAME = [
  { label: "Before", value: "before" },
  { label: "After", value: "after" },
];

// Array of EVENTS options.
export const EVENTS = [
  { label: "Rep Agreement", value: "repAgreement" },
  { label: "First Payment", value: "firstPayment" },
  { label: "Parole Review", value: "paroleReview" },
  { label: "Parole Eligibility Date", value: "paroleEligibilityDate" },
  { label: "Parole Decision", value: "paroleDecision" },
];

export const ROLE = [
  { label: "Lawyer", value: "LAWYER" },
  { label: "Staff", value: "STAFF" },
  { label: "Support Contact", value: "SUPPORT_CONTACT" },
  { label: "Additional Contact", value: "ADDITIONAL_CONTACT" },
  {
    label: "Admin",
    value: "ADMIN",
  },
];

// Array of LETTERS options.
export const LETTERS = [
  {
    label: "Attorney Fee Affidavit",
    value: "letter-AttorneyFeeAffidavit",
  },
  {
    label: "BJT Phone Register with Phone Bill",
    value: "letter-BJTPhoneRegisterwithPhoneBill",
  },
  {
    label: "Client Questionnaire",
    value: "letter-ClientQuestionnaire",
  },
  {
    label: "Hearing Binder Cover & Index",
    value: "letter-HearingBinderCover&Index",
  },
  {
    label: "Representation Agreement",
    value: "letter-InPersonRepAgreement",
  },
  {
    label: "Intro To Inmate Representation",
    value: "letter-IntroToInmateRepresentation",
  },
  {
    label: "Rep To Parole Office",
    value: "letter-RepToParoleOffice",
  },
  {
    label: "Response To Inmate",
    value: "letter-ResponseToInmate",
  },
  {
    label: "Support Advice Doc",
    value: "letter-SupportAdviceDoc",
  },
  {
    label: "Attorney Phone Registration",
    value: "letter-Attorney-Phone-Registration",
  },
];

// Array of Progress options.
export const Progress = [
  {
    label: "(1) Receipt of Payment and Rep Agreement",
    value: "receiptOfPaymentAndRepAgreement",
  },
  {
    label: "(2) Inmate’s placement in parole review",
    value: "inmatePlacementInParoleReview",
  },
  {
    label: "(3) Interview with the Institutional Parole Officer (IPO)",
    value: "interviewWithTheInstitutionalProleOfficer",
  },
  { label: "(4) Parole Vote", value: "paroleVote" },
  { value: "paroleVote" },
];

// Array of PayroleVoteResult options.
export const PayroleVoteResult = [
  { label: "FI-1", value: "FI-1" },
  { label: "FI-2", value: "FI-2" },
  { label: "FI-3", value: "FI-3_changes" },
  { label: "FI-4", value: "FI-4_soep" },
  { label: "FI-5", value: "FI-5_iptc" },
  {
    label: "FI-6 Substance Use/DWI Reoffender Program",
    value: "FI-6_substance_use/DWI_reoffender_program",
  },
  { label: "FI-9 SOTP", value: "FI-9_sOTP" },
  { label: "FI-18 SOTP", value: "FI-18 SOTP" },
  { label: "RMS", value: "RMS" },
  { label: "1D-Criminal History", value: "1D_criminal_history" },
  { label: "2D-Nature of Offense", value: "2D-Nature_of_offense" },
  {
    label: "3D-Drug or Alcohol Involvement",
    value: "3D-Drug_or_alcohol_involvement",
  },
  {
    label: "4D-Institutional Adjustment",
    value: "4D-Institutional_adjustment",
  },
  {
    label: "5D-Adjustment During Periods of Supervision",
    value: "5D-Adjustment_during_periods_of_supervision",
  },
  {
    label: "6D-Participation in TDCJ Program",
    value: "6D-Participation_in_TDCJ_program",
  },
  { label: "7D-Time Served", value: "7D-Time_Served" },
  { label: "8D-Felony Offense", value: "8D-Felony_offense" },
  {
    label: "9D1-(DMS) Accrual of Good Conduct Time",
    value: "9D1-(DMS)_Accrual_of_Good_conduct_time",
  },
  {
    label: "9D2-(DMS) Endanger the Public",
    value: "9D2-(DMS)_Endanger_the_Public",
  },
  { label: "10D-Gang Affiliation", value: "10D-Gang_Affiliation" },
  { label: "11D-Other", value: "11D-Other" },
];

// Array of Units options.
export const Units = [
  {
    label: "Allred",
    value: "Allred",
    address: "2101 FM 369 North; Iowa Park, TX 76367",
    phone: "(940) 855-7477",
  },
  {
    label: "Baten",
    value: "Baten",
    address: "1995 Helton Road; Pampa, TX 79065",
    phone: "(806) 665-7070",
  },
  {
    label: "Bell",
    value: "Bell",
    address: "PO Box 1678; 901 East Fifth Street; Cleveland, TX 77328",
    phone: "(281) 592-9559",
  },
  {
    label: "Beto",
    value: "Beto",
    address: "1391 FM 3328, Tennessee Colony, TX 75880",
    phone: "(903) 928-2217",
  },
  {
    label: "Boyd",
    value: "Boyd",
    address: "200 Spur 113; Teague, TX 75860-2007",
    phone: "(254) 739-5555",
  },
  {
    label: "Bradshaw",
    value: "Bradshaw",
    address: "PO Box 9000; 3900 West Loop 571 North; Henderson, TX 75653",
    phone: "(903) 655-0880",
  },
  {
    label: "Bridgeport",
    value: "Bridgeport",
    address: "4000 North 10th Street; Bridgeport, TX 76426",
    phone: "(940) 683-3010",
  },
  {
    label: "Briscoe",
    value: "Briscoe",
    address: "1459 West Highway 85; Dilley, TX 78017-4601",
    phone: "(830) 965-4444",
  },
  {
    label: "Byrd",
    value: "Byrd",
    address: "21 FM 247; Huntsville, TX 77320",
    phone: "(936) 295-5768",
  },
  {
    label: "Chase Field Work Camp",
    value: "Chase Field Work Camp",
    address: "4304 Highway 202; Beeville, TX 78102-8981",
    phone: "(361) 358-9880",
  },
  {
    label: "Clemens",
    value: "Clemens",
    address: "11034 Hwy 36; Brazoria, TX 77422",
    phone: "(979) 798-2188",
  },
  {
    label: "Clements",
    value: "Clements",
    address: "9601 Spur 591; Amarillo, TX 79107-9606",
    phone: "(806) 381-7080",
  },
  {
    label: "Coffield",
    value: "Coffield",
    address: "2661 FM 2054; Tennessee Colony, TX 75884",
    phone: "(903) 928-2211",
  },
  {
    label: "Cole",
    value: "Cole",
    address: "3801 Silo Road; Bonham, TX 75418",
    phone: "(903) 583-1100",
  },
  {
    label: "Coleman",
    value: "Coleman",
    address: "1400 Industrial Boulevard; Lockhart, TX 78644",
    phone: "(512) 398-3480",
  },
  {
    label: "Connally",
    value: "Connally",
    address: "899 FM 632; Kenedy, TX 78119",
    phone: "(830) 583-4003",
  },
  {
    label: "Cotulla",
    value: "Cotulla",
    address: "610 FM 624; Cotulla, TX 78014",
    phone: "(830) 879-3077",
  },
  {
    label: "Crain",
    value: "Crain",
    address: "1401 State School Road; Gatesville, TX 76528",
    phone: "(254) 865-8431",
  },
  {
    label: "Dalhart",
    value: "Dalhart",
    address: "11950 FM 998; Dalhart, TX 79022",
    phone: "(806) 249-8655",
  },
  {
    label: "Daniel",
    value: "Daniel",
    address: "938 South FM 1673; Snyder, TX 79549",
    phone: "(325) 573-1114",
  },
  {
    label: "Diboll",
    value: "Diboll",
    address: "1604 South First Street; Diboll, TX 75941",
    phone: "(936) 829-2295",
  },
  {
    label: "Dominguez",
    value: "Dominguez",
    address: "6535 Cagnon Road; San Antonio, TX 78252-2202",
    phone: "(210) 675-6620",
  },
  {
    label: "Duncan",
    value: "Duncan",
    address: "1502 South First St.; Diboll, TX 75941",
    phone: "(936) 829-2616",
  },
  {
    label: "East Texas Treatment Facility",
    value: "East Texas Treatment Facility",
    address: "900 Industrial Drive; Henderson, TX 75652",
    phone: "(903) 655-3300",
  },
  {
    label: "Ellis",
    value: "Ellis",
    address: "1697 FM 980; Huntsville, TX 77343",
    phone: "(936) 295-5756",
  },
  {
    label: "Estelle",
    value: "Estelle",
    address: "264 FM 3478; Huntsville, TX 77320-3320",
    phone: "(936) 291-4200",
  },
  {
    label: "Estes",
    value: "Estes",
    address: "1100 Highway 1807, Venus, TX 76084",
    phone: "(972) 366-3334",
  },
  {
    label: "Ferguson",
    value: "Ferguson",
    address: "12120 Savage Drive; Midway, TX 75852-3654",
    phone: "(936) 348-3751",
  },
  {
    label: "Formby",
    value: "Formby",
    address: "998 County Road AA; Plainview, TX 79072-9641",
    phone: "(806) 296-2448",
  },
  {
    label: "Fort Stockton",
    value: "Fort Stockton",
    address: "1536 IH- 10 East; Fort Stockton, TX 79735",
    phone: "(432) 336-7676",
  },
  {
    label: "Garza East",
    value: "Garza East",
    address: "4304 Highway 202; Beeville, TX 78102",
    phone: "(361) 358-9880",
  },
  {
    label: "Garza West",
    value: "Garza West",
    address: "4250 Highway 202; Beeville, TX 78102-8982",
    phone: "(361) 358-9890",
  },
  {
    label: "Gist",
    value: "Gist",
    address: "3295 FM 3514; Beaumont, TX 77705",
    phone: "(409) 727-8400",
  },
  {
    label: "Glossbrenner",
    value: "Glossbrenner",
    address: "5100 South FM 1329; San Diego, TX 78384",
    phone: "(361) 279-2705",
  },
  {
    label: "Goodman",
    value: "Goodman",
    address: "349 Private Road 8430; Jasper, TX 75951",
    phone: "(409) 383-0012",
  },
  {
    label: "Goree",
    value: "Goree",
    address: "7405 Highway 75 South; Huntsville, TX 77344",
    phone: "(936) 295-6331",
  },
  {
    label: "Halbert",
    value: "Halbert",
    address: "800 Ellen Halbert Drive; Burnet, TX 78611",
    phone: "(512) 756-6171",
  },
  {
    label: "Hamilton",
    value: "Hamilton",
    address: "200 Lee Morrison Lane; Bryan, TX 77807",
    phone: "(979) 779-1633",
  },
  {
    label: "Havins",
    value: "Havins",
    address: "500 FM 45 East; Brownwood, TX 76801",
    phone: "(325) 643-5575",
  },
  {
    label: "Henley",
    value: "Henley",
    address: "7581 Highway 321; Dayton, TX 77535",
    phone: "(936) 258-2476",
  },
  {
    label: "Hightower",
    value: "Hightower",
    address: "902 FM 686; Dayton, TX 77535",
    phone: "(936) 258-8013",
  },
  {
    label: "Hilltop",
    value: "Hilltop",
    address: "1500 State School Road; Gatesville, TX 76598-2996",
    phone: "(254) 865-8901",
  },
  {
    label: "Hobby",
    value: "Hobby",
    address: "742 FM 712; Marlin, TX 76661-4685",
    phone: "(254) 883-5561",
  },
  {
    label: "Hodge",
    value: "Hodge",
    address: "379 FM 2972 West; Rusk, TX 75785",
    phone: "(903) 683-5781",
  },
  {
    label: "Holliday",
    value: "Holliday",
    address: "295 IH- 45 North; Huntsville, TX 77320-8443",
    phone: "(936) 295-8200",
  },
  {
    label: "Hospital Galveston",
    value: "Hospital Galveston",
    address:
      "PO Box 48, Substation# 1; 809 Harborside Drive; Galveston, TX 77555",
    phone: "(409) 772-2875",
  },
  {
    label: "Hughes",
    value: "Hughes",
    address: "Rt. 2 Box 4400; Gatesville, TX 76597",
    phone: "(254) 865-6663",
  },
  {
    label: "Huntsville",
    value: "Huntsville",
    address: "815 12th Street; Huntsville, TX 77348",
    phone: "(936) 437-1555",
  },
  {
    label: "Hutchins",
    value: "Hutchins",
    address: "1500 East Langdon Road; Dallas, TX 75241",
    phone: "(972) 225-1304",
  },
  {
    label: "Jester III",
    value: "Jester III",
    address: "3 Jester Road; Richmond, TX 77406",
    phone: "(281) 277-7000",
  },
  {
    label: "Johnston",
    value: "Johnston",
    address: "703 Airport Road; Winnsboro, TX 75494",
    phone: "(903) 342-6166",
  },
  {
    label: "Jordan",
    value: "Jordan",
    address: "1992 Helton Road; Pampa, TX 79065",
    phone: "(806) 665-7070",
  },
  {
    label: "Kegans",
    value: "Kegans",
    address: "707 Top Street; Houston, TX 77002",
    phone: "(713) 224-6584",
  },
  {
    label: "Kyle",
    value: "Kyle",
    address: "23001 IH- 35; Kyle, TX 78640",
    phone: "(512) 268-0079",
  },
  {
    label: "LeBlanc",
    value: "LeBlanc",
    address: "3695 FM 3514; Beaumont, TX 77705",
    phone: "(409) 724-1515",
  },
  {
    label: "Lewis",
    value: "Lewis",
    address: "777 FM 3497; Woodville, TX 75990",
    phone: "(409) 283-8181",
  },
  {
    label: "Lindsey",
    value: "Lindsey",
    address: "1620 FM 3344; Jacksboro, TX 76458",
    phone: "(940) 567-2272",
  },
  {
    label: "Lopez",
    value: "Lopez",
    address: "1203 El Cibolo Road; Edinburg, TX 78542",
    phone: "(956) 316-3810",
  },
  {
    label: "Luther",
    value: "Luther",
    address: "1800 Luther Drive; Navasota, TX 77868",
    phone: "(936) 825-7547",
  },
  {
    label: "Lychner",
    value: "Lychner",
    address: "2350 Atascocita Road; Humble, TX 77396",
    phone: "(281) 454-5036",
  },
  {
    label: "Lynaugh",
    value: "Lynaugh",
    address: "1098 South Highway 2037; Fort Stockton, TX 79735",
    phone: "(432) 395-2938",
  },
  {
    label: "Marlin",
    value: "Marlin",
    address: "2893 State Highway 6; Marlin, TX 76661-6588",
    phone: "(254) 883-3858",
  },
  {
    label: "McConnell",
    value: "McConnell",
    address: "3001 South Emily Drive; Beeville, TX 78102",
    phone: "(361) 362-2300",
  },
  {
    label: "Mechler",
    value: "Mechler",
    address: "4000 Hwy 86 West; Tulia, TX 79088",
    phone: "(806) 995-4109",
  },
  {
    label: "Memorial",
    value: "Memorial",
    address: "59 Darrington Road; Rosharon, TX 77583",
    phone: "(281) 595-3465",
  },
  {
    label: "Michael",
    value: "Michael",
    address: "2664 FM 2054; Tennessee Colony, TX 75886",
    phone: "(903) 928-2311",
  },
  {
    label: "Middleton",
    value: "Middleton",
    address: "13055 FM 3522; Abilene, TX 79601",
    phone: "(325) 548-9075",
  },
  {
    label: "Montford",
    value: "Montford",
    address: "8602 Peach Street; Lubbock, TX 79404",
    phone: "(806) 745-1021",
  },
  {
    label: "Moore, B.",
    value: "Moore, B.",
    address: "8500 North FM 3053; Overton, TX 75684",
    phone: "(903) 834-6186",
  },
  {
    label: "Moore, C.",
    value: "Moore, C.",
    address: "1700 North FM 87; Bonham, TX 75418",
    phone: "(903) 583-4464",
  },
  {
    label: "Murray",
    value: "Murray",
    address: "1916 North Hwy. 36 Bypass; Gatesville, TX 76596",
    phone: "(254) 865-2000",
  },
  {
    label: "Ney",
    value: "Ney",
    address: "114 Private Road 4303; Hondo, TX 78861-3812",
    phone: "(830) 426-8030",
  },
  {
    label: "O'Daniel",
    value: "O'Daniel",
    address: "2305 Ransom Road; Gatesville, TX 76528",
    phone: "(254) 865-7226",
  },
  {
    label: "Pack",
    value: "Pack",
    address: "2400 Wallace Pack Road; Navasota, TX 77868",
    phone: "(936) 825-3728",
  },
  {
    label: "Plane",
    value: "Plane",
    address: "904 FM 686; Dayton, TX 77535",
    phone: "(936) 258-2476",
  },
  {
    label: "Polunsky",
    value: "Polunsky",
    address: "3872 FM 350 South; Livingston, TX 77351",
    phone: "(936) 967-8082",
  },
  {
    label: "Powledge",
    value: "Powledge",
    address: "1400 FM 3452; Palestine, TX 75803",
    phone: "(903) 723-5074",
  },
  {
    label: "Ramsey",
    value: "Ramsey",
    address: "1100 FM 655; Rosharon, TX 77583",
    phone: "(281) 595-3491",
  },
  {
    label: "Roach",
    value: "Roach",
    address: "15845 FM 164; Childress, TX 79201",
    phone: "(940) 937-6364",
  },
  {
    label: "Robertson",
    value: "Robertson",
    address: "12071 FM 3522; Abilene, TX 79601",
    phone: "(325) 548-9035",
  },
  {
    label: "Rudd",
    value: "Rudd",
    address: "2004 Lamesa Hwy.; Brownfield, TX 79316",
    phone: "(806) 637-4470",
  },
  {
    label: "San Angelo Work Camp",
    value: "San Angelo Work Camp",
    address: "1675 South FM 3525; Colorado City, TX 79512",
    phone: "(325) 728-2162",
  },
  {
    label: "San Saba",
    value: "San Saba",
    address: "206 South Wallace Creek Road; San Saba, TX 76877",
    phone: "(325) 372-4255",
  },
  {
    label: "Sanchez",
    value: "Sanchez",
    address: "3901 State Jail Road; El Paso, TX 79938-8456",
    phone: "(915) 856-0046",
  },
  {
    label: "Santa Maria Baby Bonding",
    value: "Santa Maria Baby Bonding",
    address: "904 FM 686; Dayton, TX 77535",
    phone: "(936) 258-2476",
  },
  {
    label: "Sayle",
    value: "Sayle",
    address: "4176 FM 1800; Breckenridge, TX 76424-7301",
    phone: "(254) 559-1581",
  },
  {
    label: "Scott",
    value: "Scott",
    address: "4 Jester Road; Richmond, TX 77406",
    phone: "(281) 277-3700",
  },
  {
    label: "Segovia",
    value: "Segovia",
    address: "1201 East El Cibolo Road; Edinburg, TX 78542",
    phone: "(956) 316-2400",
  },
  {
    label: "Skyview",
    value: "Skyview",
    address: "379 FM 2972 West; Rusk, TX 75785",
    phone: "(903) 683-5781",
  },
  {
    label: "Smith",
    value: "Smith",
    address: "1313 County Road 19; Lamesa, TX 79331-1898",
    phone: "(806) 872-6741",
  },
  {
    label: "Stevenson",
    value: "Stevenson",
    address: "1525 FM 766; Cuero, TX 77954",
    phone: "(361) 275-2075",
  },
  {
    label: "Stiles",
    value: "Stiles",
    address: "3060 FM 3514; Beaumont, TX 77705",
    phone: "(409) 722-5255",
  },
  {
    label: "Stringfellow",
    value: "Stringfellow",
    address: "1200 FM 655; Rosharon, TX 77583",
    phone: "(281) 595-3413",
  },
  {
    label: "Telford",
    value: "Telford",
    address: "3899 Hwy 98; New Boston, TX 75570",
    phone: "(903) 628-3171",
  },
  {
    label: "Terrell",
    value: "Terrell",
    address: "1300 FM 655; Rosharon, TX 77583",
    phone: "(281) 595-3481",
  },
  {
    label: "Torres",
    value: "Torres",
    address: "125 Private Road 4303; Hondo, TX 78861",
    phone: "(830) 426-5325",
  },
  {
    label: "Travis County",
    value: "Travis County",
    address: "8101 FM 969; Austin, TX 78724",
    phone: "(512) 926-4482",
  },
  {
    label: "Vance",
    value: "Vance",
    address: "2 Jester Road; Richmond, TX 77406",
    phone: "(281) 277-3030",
  },
  {
    label: "Wainwright",
    value: "Wainwright",
    address: "2665 Prison Road# 1; Lovelady, TX 75851",
    phone: "(936) 636-7321",
  },
  {
    label: "Wallace",
    value: "Wallace",
    address: "1675 South FM 3525; Colorado City, TX 79512",
    phone: "(325) 728-2162",
  },
  {
    label: "West Texas Hospital",
    value: "West Texas Hospital",
    address: "8602 Peach Street; Lubbock, TX 79404",
    phone: "(806) 745-1021",
  },
  {
    label: "Wheeler",
    value: "Wheeler",
    address: "986 County Road AA; Plainview, TX 79072-9682",
    phone: "(806) 293-1081",
  },
  {
    label: "Willacy County",
    value: "Willacy County",
    address: "1695 South Buffalo Drive; Raymondville, TX 78580",
    phone: "(956) 689-4900",
  },
  {
    label: "Woodman",
    value: "Woodman",
    address: "1210 Coryell City Road; Gatesville, TX 76528",
    phone: "(254) 865-9398",
  },
  {
    label: "Wynne",
    value: "Wynne",
    address: "810 FM 2821; Huntsville, TX 77349",
    phone: "(936) 295-9126",
  },
  {
    label: "Young",
    value: "Young",
    address: "5509 Attwater Ave.; Dickinson, TX 77539",
    phone: "(409) 948-0001",
  },
];

// Array of Task options.
export const Task = [
  {
    label: "Telephone Message, Contact Number",
    value: "Telephone Message, Contact Number",
  },
  { label: "Send: AE Applied", value: "Send: AE Applied" },
  {
    label: "Send: AE Granted With VIP",
    value: "Send: AE Granted With VIP",
  },
  {
    label: "Send: AE Granted No VIP",
    value: "Send: AE Granted No VIP",
  },
  {
    label: "Send: Community Service Reminder",
    value: "Send: Community Service Reminder",
  },
  {
    label: "Send: Options",
    value: "Send: Options",
  },
  {
    label: "Send: Offer Letter",
    value: "Send: Offer Letter",
  },
  {
    label: "Send: Trial Letter",
    value: "Send: Trial Letter",
  },
  {
    label: "Send: Police Dept. Representation",
    value: "Send: Police Dept. Representation",
  },
  {
    label: "Send: Police Report To Client",
    value: "Send: Police Report To Client",
  },
  {
    label: "Send:PoliceReportToClient",
    value: "Send:PoliceReportToClient",
  },
  {
    label: "Send:Restitution Letter To Victim",
    value: "Send:Restitution Letter To Victim",
  },
  {
    label: "Send: AE Notice To Victim",
    value: "Send: AE Notice To Victim",
  },
  {
    label: "Send: AR Notice To Victim",
    value: "Send: AR Notice To Victim",
  },
  {
    label: "Get Proof Of Insurance",
    value: "Get Proof Of Insurance",
  },
  {
    label: "Get Unpaid Balance",
    value: "Get Unpaid Balance",
  },
  {
    label: "Get Olayos Report",
    value: "Get Olayos Report",
  },
  {
    label: "Get Proof Of Program Completion",
    value: "Get Proof Of Program Completion",
  },
  {
    label: "Get Proof Of VIP",
    value: "Get Proof Of VIP",
  },
  {
    label: "Get Video Of Arrest",
    value: "Get Video Of Arrest",
  },
  {
    label: "Get Checkpoint Info",
    value: "Get Checkpoint Info",
  },
  {
    label: "Schedule:In Office Conference",
    value: "Schedule:In Office Conference",
  },
  {
    label: "Schedule:Phone Conference",
    value: "Schedule:Phone Conference",
  },
  {
    label: "Schedule:Police Report Review",
    value: "Schedule:Police Report Review",
  },
  {
    label: "Prepare:Disclosure Request For Checkpoint Info",
    value: "Prepare:Disclosure Request For Checkpoint Info",
  },
  {
    label: "Prepare:Disclosure Request For Police Report",
    value: "Prepare:Disclosure Request For Police Report",
  },
  {
    label: "Prepare:Trial Motions",
    value: "Prepare:Trial Motions",
  },
  {
    label: "Subpoena Video to DMV",
    value: "Subpoena Video to DMV",
  },
  {
    label: "Subpoena Arresting Officer to DMV",
    value: "Subpoena Arresting Officer to DMV",
  },
  {
    label: "Close File",
    value: "Close File",
  },
];

// Array of Type options.
export const Type = [
  {
    label: "Meeting With Client-Jail",
    value: "meetingWithClient-Jail",
  },
  { label: "Travel", value: "travel" },
  {
    label: "Telephone with Client",
    value: "telephonewithClient",
  },
  { label: "Document Review", value: "documentReview" },
  { label: "Transcript Review", value: "transcriptReview" },
  {
    label: "Digesting Transcripts",
    value: "digestingTranscripts",
  },
  { label: "Review File", value: "reviewFile" },
  { label: "FOIA Request", value: "fOIARequest" },
  { label: "Investigation Plan", value: "investigationPlan" },
  { label: "Prepare Petition", value: "preparePetition" },
  { label: "Prepare Reply", value: "  prepareReply" },

  { label: "Pretrial Brief ", value: " pretrialBrief" },
  {
    label: "Review State's Brief",
    value: "reviewState'sBrief",
  },
  { label: "Trial Preparation", value: "trialPreparation" },
  { label: "Prepare Witness", value: "prepareWitness" },
  { label: "Prepare Petitioner", value: "preparePetitioner" },

  { label: "Trial", value: "trial" },
  {
    label: "Consultion With Expert",
    value: "ConsultionWithExpert",
  },
  { label: "Conference/Meeting", value: "conference/Meeting" },

  {
    label: "Draft Correspondence",
    value: "draftCorrespondence",
  },
  {
    label: "Telephone Conference",
    value: "telephoneConference",
  },
  { label: "Legal Research", value: "legalResearch" },
  { label: "Referral Lunch", value: "referralLunch" },
  { label: "Status Conference", value: "statusConference" },

  { label: "Pretrial Conference", value: "pretrialConference" },
  {
    label: "Court Appearance",
    value: "courtAppearance",
  },
  { label: "Investigation", value: "investigation" },
  { label: "Letter to Client", value: "lettertoClient" },
  { label: "Organize File", value: "organizeFile" },

  {
    label: "Draft/Prepare Motion",
    value: "draft/PrepareMotion",
  },
  {
    label: "Networking Event",
    value: "networkingEvent",
  },
  { label: "Scanning Records", value: "scanningRecords" },
];

// Array of Reasons options.
export const Reasons = [
  {
    label: "Too Expensive",
    value: "Too Expensive",
  },
  {
    label: "Went with another firm",
    value: "Went with another firm",
  },
  {
    label: "Bad Lead",
    value: "Bad Lead",
  },
];
