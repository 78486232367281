import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import {
  Badge,
  Box,
  CircularProgress,
  IconButton,
  ListItem,
  ListItemButton,
  Menu,
  Stack,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useState } from "react";
import useNotifications from "../../../../hooks/useNotifications";
import { useModal } from "mui-modal-provider";
import NotificationModal from "./NotificationModal";
import moment from "moment";
import useUser from "../../../../hooks/useUser";
import useAuth from "../../../../hooks/useAuth";

const Notification = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { userId } = useAuth();
  const { isAdmin } = useUser(userId);
  const open = Boolean(anchorEl);
  const { notifications, readCount, isLoadingNotifications, readNotification } =
    useNotifications();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (isLoadingNotifications) {
    return <CircularProgress />;
  }

  return (
    <Box m={-2.5}>
      <Badge badgeContent={readCount} color="primary">
        <IconButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          sx={{
            backgroundColor: "#cce8f1",
            borderRadius: 2,
            color: "#008ebb",
            "&:hover": {
              backgroundColor: "#008ebb",
              color: "white",
            },
          }}
        >
          <NotificationsNoneIcon />
        </IconButton>
      </Badge>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        sx={{ mt: 1 }}
      >
        <NotificationList
          isAdmin={isAdmin}
          notifications={notifications}
          readNotification={readNotification}
        />
      </Menu>
    </Box>
  );
};

function NotificationList({ isAdmin, notifications, readNotification }: any) {
  const { showModal } = useModal();

  const handleNotification = React.useCallback(
    async (notification?: any) => {
      const isRead = notification?.isRead;

      if (!isRead) {
        await readNotification({
          notificationId: notification?._id,
        });
      }

      const modal: any = showModal(NotificationModal, {
        onClose: () => modal.hide(),
        notification,
      });
    },
    [showModal, readNotification]
  );

  return (
    <List sx={{ width: 400, bgcolor: "background.paper" }}>
      {!!notifications?.length ? (
        notifications?.map((notification: any) => {
          const isReminder = notification?.type?.includes("Reminder") || false;
          const date = notification?.createdAt;
          const isRead = isAdmin
            ? notification?.isReadByAdmin
            : notification?.isRead;

          let bgColor = "none";

          if (!isRead) {
            if (isReminder) {
              bgColor = "#f1d5cc";
            } else {
              bgColor = "#cce8f1";
            }
          }

          return (
            <React.Fragment key={notification?._id}>
              <ListItemButton
                sx={{
                  backgroundColor: bgColor,
                  "&:hover": {
                    backgroundColor: bgColor,
                  },
                }}
                onClick={() => handleNotification(notification)}
                key={notification?._id}
              >
                <ListItemText
                  primary={
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Typography variant="h5">
                        {isReminder
                          ? `${notification?.type?.substring(0, 25)}.....`
                          : notification?.type}
                      </Typography>{" "}
                      <Typography variant="h6">
                        {moment(date).format("MM/DD/YYYY, h:mm a")}
                      </Typography>
                    </Stack>
                  }
                  secondary={
                    <Typography variant="body1">
                      {notification?.message?.substring(0, 55)}......
                    </Typography>
                  }
                />
              </ListItemButton>
              {notifications?.length > 1 && (
                <Divider variant="middle" component="li" sx={{ m: 0.5 }} />
              )}
            </React.Fragment>
          );
        })
      ) : (
        <ListItem>
          <Typography
            variant="body1"
            sx={{ width: "100%", textAlign: "center" }}
          >
            No Notifications found
          </Typography>
        </ListItem>
      )}
    </List>
  );
}

export default Notification;
