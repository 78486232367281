import { IconEdit, IconEye, IconRestore, IconTrash } from "@tabler/icons-react";
import { useModal } from "mui-modal-provider";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Autocomplete,
  Box,
  Checkbox,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  GridActionsCellItem,
  GridColDef,
  GridPaginationModel,
  GridSortModel,
} from "@mui/x-data-grid";
import { debounce } from "lodash";
import moment from "moment";
import { CaseInfoFileStatus } from "../../../constants/MenuSelection";

//components
import useAuth from "../../../hooks/useAuth";
import useCases from "../../../hooks/useCases";
import useUser from "../../../hooks/useUser";
import DataGridTable from "../../components/DataGridTable";
import BasicButton from "../../components/buttons/BasicButton";
import MainCard from "../../components/cards/MainCard";
import SearchField from "../../components/inputs/SearchField";
import CreateUserModal from "../contacts/CreateUserModal";
import DeleteCaseModal from "./DeleteCaseModal";
import RestoreCasesModal from "./RestoreCasesModal";

const caseStatus = [
  { title: "Active" },
  { title: "Closed" },
  { title: "Prospect" },
  { title: "InReview" },
  { title: "Retained" },
  { title: "UnRetained" },
];

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
function Cases() {
  const navigate = useNavigate();

  const innerWidth = window?.innerWidth;

  const [openInfoModal, setInfoModal] = useState(false);
  const [search, setSearch] = useState("");

  const { userId } = useAuth();
  const { isStaff } = useUser(userId);
  const [showDeletedCases, setShowDeletedCases] = useState(false);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "createdAt", sort: "desc" },
  ]);

  const [filterData, setFilterData] = useState<any>(null);

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 12,
  });

  const { cases, totalCases, isLoading } = useCases(
    undefined,
    search,
    paginationModel,
    sortModel,
    showDeletedCases,
    filterData
  );
  const { showModal } = useModal();

  const handleAddNewCase = () => {
    setInfoModal(true);
  };

  const handleDeleteCase = useCallback(
    (id: string) => {
      const modal: any = showModal(DeleteCaseModal, {
        onClose: () => modal.hide(),
        id,
      });
    },
    [showModal]
  );

  useEffect(() => {
    if (filterData?.length === 0) {
      setFilterData(null);
    } else if (
      filterData?.length === 1 &&
      filterData?.[0]?.title === "InReview"
    ) {
      setSortModel([{ field: "paroleEligibilityDate", sort: "asc" }]);
    }
  }, [filterData]);

  const handleRestoreCases = useCallback(
    (data: any) => {
      const modal: any = showModal(RestoreCasesModal, {
        onClose: () => modal.hide(),
        data: data?.row,
        id: data?.id,
      });
    },
    [showModal]
  );

  const columns: GridColDef[] = [
    {
      field: "caseId",
      headerName: "Case ID#",
      headerClassName: "MuiDataGrid-cell",
      minWidth: 120,
      renderCell: (params: any) => params?.row?.caseId || "-",
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    {
      field: "inmate",
      headerName: "Inmate",
      headerClassName: "MuiDataGrid-cell",
      minWidth: 240,
      renderCell: (params: any) => params?.row?.contact?.inmate?.name,
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    {
      field: "caseStatus",
      headerName: "Case Status",
      headerClassName: "MuiDataGrid-cell",
      minWidth: 150,
      renderCell: (params: any) =>
        CaseInfoFileStatus?.find(
          (status: any) => status?.value === params?.row?.caseStatus
        )?.label || "-",
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    // {
    //   field: "repType",
    //   headerName: "Rep Type",
    //   headerClassName: "MuiDataGrid-cell",
    //   minWidth: 150,
    //   renderCell: (params: any) =>
    //     RepType?.find((type: any) => type?.value === params?.row?.repType)
    //       ?.label || "-",
    //   renderHeader: (params: any) => params?.colDef?.headerName,
    // },
    {
      field: "lawyer",
      headerName: "Lawyer",
      minWidth: 200,
      renderCell: (params: any) =>
        params?.row?.lawyer
          ? `${params?.row?.lawyer?.firstName} ${params?.row?.lawyer?.lastName}`
          : "-",
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    // {
    //   field: "secondaryLawyer",
    //   headerName: "Secondary Lawyer",
    //   minWidth: 220,
    //   renderCell: (params: any) =>
    //     params?.row?.secondaryLawyer
    //       ? `${params?.row?.secondaryLawyer?.firstName} ${params?.row?.secondaryLawyer?.lastName}`
    //       : "-",
    //   renderHeader: (params: any) => params?.colDef?.headerName,
    // },
    // {
    //   field: "otherStaff",
    //   headerName: "Other Staff",
    //   minWidth: 220,
    //   renderCell: (params: any) =>
    //     params?.row?.staff
    //       ? `${params?.row?.staff?.firstName} ${params?.row?.staff?.lastName}`
    //       : "-",
    //   renderHeader: (params: any) => params?.colDef?.headerName,
    // },
    {
      field: "progress",
      headerName: "Progress",
      flex: 1,
      minWidth: 320,
      renderCell: (params: any) => params?.row?.workflowName || "-",
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    // {
    //   field: "paroleStatus",
    //   headerName: "Parole Status",
    //   minWidth: 200,
    //   renderCell: (params: any) =>
    //     params?.row?.contact?.inmate?.paroleReviewInfo?.paroleReviewStatus ||
    //     "-",
    //   renderHeader: (params: any) => params?.colDef?.headerName,
    // },
    {
      field: "paroleEligibilityDate",
      headerName: "Parole Eligibility Date",
      minWidth: 200,
      renderCell: (params: any) =>
        moment(params?.row?.paroleEligibilityDate).format("MM/DD/YYYY") || "-",
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      minWidth: 200,
      renderCell: (params: any) => (
        <Typography variant={innerWidth < 1420 ? "body2" : "body1"}>
          {moment(params?.row?.createdAt).format("MM/DD/YYYY, h:mm a")}
        </Typography>
      ),
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    {
      field: "createdBY",
      headerName: "Created By",
      minWidth: 200,
      renderCell: (params: any) =>
        `${params?.row?.createdBy?.firstName} ${params?.row?.createdBy?.lastName}` ||
        "-",
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    // {
    //   field: "updatedAt",
    //   headerName: "Updated At",
    //   minWidth: 200,
    //   renderCell: (params: any) => (
    //     <Typography variant={window.innerWidth < 1420 ? "body2" : "body1"}>
    //       {moment(params?.row?.updatedAt).format("MM/DD/YYYY, h:mm a")}
    //     </Typography>
    //   ),
    //   renderHeader: (params: any) => params?.colDef?.headerName,
    // },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerClassName: "MuiDataGrid-cell",
      minWidth: 100,
      cellClassName: "actions",
      getActions: (value: any) => {
        return showDeletedCases !== true
          ? [
              <GridActionsCellItem
                icon={isStaff ? <IconEye /> : <IconEdit />}
                label="view"
                onClick={() => {
                  navigate(`/cases/${value?.id}`);
                }}
              />,
              <GridActionsCellItem
                color="error"
                disabled={isStaff}
                icon={<IconTrash />}
                label={showDeletedCases ? "Restore" : "Delete"}
                onClick={() => handleDeleteCase(value?.id)}
              />,
            ]
          : [
              <Tooltip title="Restore Case">
                <GridActionsCellItem
                  disabled={isStaff}
                  color="error"
                  icon={<IconRestore fontSize="large" />}
                  label={showDeletedCases ? "Restore" : "Delete"}
                  onClick={() => handleRestoreCases(value)}
                />
              </Tooltip>,
            ];
      },
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
  ];

  // Add debounce to hold render events
  const debouncedSetSearch = useRef(
    debounce((value) => setSearch(value), 400)
  ).current;

  const handleSearchChange = useCallback(
    (event: any) => {
      debouncedSetSearch(event.target.value);
    },
    [debouncedSetSearch]
  );

  const handleSortModelChange = (newModel: any[]) => {
    setSortModel(newModel);
  };

  const handleChangeCases = () => {
    setShowDeletedCases(!showDeletedCases);
  };

  return (
    <Box>
      <MainCard
        title={
          <Typography variant={innerWidth < 1420 ? "h2" : "h1"}>
            Cases
          </Typography>
        }
        actions={
          <Stack direction={"row"} alignItems={"center"} spacing={2}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography sx={{ fontSize: innerWidth < 1420 ? 14 : 16 }}>
                Show Deleted Cases
              </Typography>
              <Switch
                inputProps={{ "aria-label": "ant design" }}
                size="small"
                onChange={() => {
                  handleChangeCases();
                }}
              />
            </Stack>

            <Stack
              direction={"row"}
              alignItems={"center"}
              flexWrap={"nowrap"}
              spacing={2}
            >
              <Autocomplete
                multiple
                limitTags={1}
                size="small"
                id="checkboxes-tags-demo"
                options={caseStatus}
                disableCloseOnSelect
                getOptionLabel={(option) => option.title}
                renderOption={(props, option, { selected }) => {
                  const { ...optionProps } = props;

                  return (
                    <li {...optionProps} key={option?.title}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.title}
                    </li>
                  );
                }}
                style={{ width: 250 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Filter"
                    placeholder="Case Status"
                  />
                )}
                onChange={(e, data: any) => setFilterData(data)}
              />
              <SearchField
                onChange={handleSearchChange}
                size="small"
                placeholder="Search case"
              />

              <BasicButton
                disabled={isStaff}
                label={"Add Case"}
                variant="contained"
                color="success"
                size={innerWidth < 1420 ? "small" : "medium"}
                onClick={handleAddNewCase}
              />
            </Stack>
          </Stack>
        }
        content={
          <DataGridTable
            onPaginationModelChange={setPaginationModel}
            paginationModel={paginationModel}
            column={columns}
            rows={cases || []}
            isLoading={isLoading}
            totalRows={totalCases}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
          />
        }
      />
      <CreateUserModal open={openInfoModal} setOpen={setInfoModal} />
    </Box>
  );
}

export default Cases;
