import {
  Box,
  Button,
  DialogProps,
  Grid,
  Stack,
  Typography,
} from "@mui/material";

import { useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import useAuth from "../../../hooks/useAuth";
import useDocuments from "../../../hooks/useDocuments";
import useUser from "../../../hooks/useUser";
import { uploadImageToS3 } from "../../components/common/uploadImages";
import Checkbox from "../../components/inputs/CheckBox";
import MuiTextField from "../../components/inputs/MuiTextField";
import BasicModal from "../../components/modal/BasicModal";
import { useSnackbarHelper } from "../../components/Snackbar";

interface Props extends DialogProps {
  onClose: () => void;
  caseId: string;
  data?: any;
}

function DigitalDocumentModal({ onClose, data, caseId, ...props }: Props) {
  const { userId } = useAuth();
  const { user } = useUser(userId);
  const showSnackbar = useSnackbarHelper();
  const { addCaseDocument, updateDocument } = useDocuments({ caseId: caseId });

  const fileInputRef = useRef<HTMLInputElement | null>(null); // Ref for the hidden file input
  const [file, setFile] = useState<File | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [removeFile, setRemoveFile] = useState<boolean>(data ? false : true);
  const initialValues = useMemo(
    () => ({
      title: data?.title || "",
      signed: data?.isSigned || false,
    }),
    [data]
  );

  const formContext = useForm({
    defaultValues: initialValues,
    //  resolver: yupResolver(validationSchema),
  });
  useEffect(() => {}, [removeFile]);
  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleSubmit = async (values?: any) => {
    try {
      setLoading(true);
      const folderName = "document";
      const fileData = {
        file: file,
        userId: user._id,
        folderName: folderName,
      };
      let res: any;
      if (file && removeFile) {
        res = await uploadImageToS3(fileData);
      } else {
        res = { Location: data?.document[0]?.url };
      }

      let payload = {};
      if (data) {
        payload = {
          title: values?.title || file?.name,
          isSigned: values?.signed,
          type: file?.type || "text/plain",
          document: {
            url: res?.Location,
            name: file?.name || data?.document[0]?.name,
          },
          uploadedBy: {
            userId: user._id,
            userName: `${user?.firstName} ${user?.lastName}`,
          },
          isFileChanged: removeFile,
          caseId: caseId,
        };
      } else {
        payload = {
          title: values?.title || file?.name,
          isSigned: values?.signed,
          type: file?.type || "text/plain",
          document: {
            url: res?.Location,
            name: file?.name || data?.document[0]?.name,
          },
          uploadedBy: {
            userId: user._id,
            userName: `${user?.firstName} ${user?.lastName}`,
          },
        };
      }
      //cases.payload
      const response = !data
        ? await addCaseDocument(payload)
        : await updateDocument({ documentId: data?._id, payload });
      const message = response?.message || "Success";
      showSnackbar(message, "success");
      onClose();
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  function shortenFileName(fileName: string, maxLength: number = 20): string {
    const extensionIndex = fileName.lastIndexOf(".");

    // Handle files without extensions
    if (extensionIndex === -1 || fileName.length <= maxLength) {
      return fileName; // Return original if it's shorter than maxLength
    }

    const name = fileName.substring(0, extensionIndex); // File name without extension
    const extension = fileName.substring(extensionIndex); // File extension

    if (fileName.length <= maxLength) {
      return fileName;
    }

    // Start part (first N characters) and end part (last N characters of name)
    const startPart = name.substring(0, 15);
    const endPart = name.substring(name.length - 5);

    return `${startPart}..${endPart}${extension}`;
  }

  return (
    <BasicModal
      formContext={formContext}
      onSubmit={handleSubmit}
      onClose={onClose}
      title={data ? "Update Document" : "Add Document To Case"}
      content={
        <Box>
          <Grid
            container
            spacing={2}
            sx={{
              "& .outlined .MuiOutlinedInput-notchedOutline": {
                border: "2px solid #AFD2F0 !important",
              },
            }}
          >
            <Grid item sm={12}>
              <MuiTextField fullWidth label="Title" name="title" />
            </Grid>

            <Grid item md={6} sm={6} justifyItems={"center"}>
              {/* <Stack
                direction={"row"}
                alignItems={"center"}
                textAlign={"center"}
              >
                <Typography>Signed ?</Typography> <Checkbox name="signed" />
              </Stack> */}
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" flexDirection="column" alignItems="flex-end">
                {/* Align button at the end */}
                <Controller
                  name="document"
                  //control={formContext.control}
                  render={({ field }) => (
                    <>
                      <Button
                        variant="contained"
                        component="span"
                        sx={{ textTransform: "none", mb: 1 }}
                        onClick={() => {
                          fileInputRef.current?.click();
                          !file && !removeFile && setRemoveFile(true);
                        }} // Trigger the file input
                      >
                        {file
                          ? "Upload Document"
                          : data
                            ? "Change Document"
                            : "Upload Document"}
                      </Button>
                      <input
                        {...field}
                        type="file"
                        ref={fileInputRef} // Hidden file input
                        style={{ display: "none" }} // Hide the file input element
                        onChange={handleFileSelect}
                      />
                      {/* {file && <Typography>{file.name}</Typography>}{" "} */}
                      {file ? (
                        <Typography>{shortenFileName(file.name)}</Typography>
                      ) : data ? (
                        shortenFileName(
                          data?.document[data?.document.length - 1]?.name
                        )
                      ) : null}
                      {/* Show the selected file name */}
                    </>
                  )}
                />
              </Box>
            </Grid>
            {/* <Grid item xs={6}>
              <Controller
                name="document"
                // control={formContext.control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type="file"
                    label="Upload Document"
                    onChange={(event) => {
                      const target = event.target as HTMLInputElement;
                      const file = target.files?.[0];

                      if (file) {
                        setFile(file);
                      }
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid> */}
            {/* <Grid item xs={12}>
              {" "}
              <Controller
                name="document"
                control={formContext.control}
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    name="note"
                    label="Note"
                    fullWidth
                    multiline
                    rows={2}
                    InputProps={{
                      style: {
                        borderRadius: "12px",
                      },
                      classes: {
                        input: "custom-scrollbar", // Apply the custom scrollbar class here
                      },
                    }}
                  />
                )}
              />
            </Grid> */}
          </Grid>
        </Box>
      }
      actions={
        <>
          <Button
            color="error"
            variant="outlined"
            onClick={onClose}
            disabled={loading}
            sx={{ textTransform: "none" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            sx={{ textTransform: "capitalize" }}
            type="submit"
            disabled={loading || (!file && removeFile)}
          >
            {data ? "Update" : "Add"}
          </Button>
        </>
      }
      {...props}
    />
  );
}

export default DigitalDocumentModal;
