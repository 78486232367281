import { Box, Chip, CircularProgress, Stack, Typography } from "@mui/material";
import { IconFile } from "@tabler/icons-react";
import moment from "moment";
import { useModal } from "mui-modal-provider";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import useAuth from "../../../hooks/useAuth";
import DelayedRender from "../../components/DelayedRender";
import TableSkeleton from "../../components/skeleton/TableSkeleton";
import PreviewChatMediaFile from "../cases/PreviewChatMediaFile";
import _ from "lodash";

export const formatDate = (date: string | Date, showToday?: boolean) => {
  const inputDate = moment(date);
  const today = moment();
  const yesterday = today.clone().subtract(1, "days");

  if (inputDate.isSame(today, "day")) {
    return showToday ? "Today" : inputDate.format("hh:mm A"); // Return time if it's today
  }

  if (inputDate.isSame(yesterday, "day")) {
    return "Yesterday"; // Return 'Yesterday' if it's yesterday
  }

  return inputDate.format("MM/DD/YYYY"); // Return date in MM/DD/YYYY format
};

const Conversations = ({
  selectedContact,
  messages,
  setMessages,
  setPaginationModel,
  getMessages,
  totalMessages,
  isLoadingMessages,
  chatEndRef,
  isScroll,
  setScroll,
}: any) => {
  const chatContainerRef: any = useRef<HTMLDivElement | null>(null);
  const [prevSelectedContact, setPrevSelectedContact] = useState<any>(null);

  const { userId } = useAuth();
  const { showModal } = useModal();

  // Trigger scroll whenever the chat history changes (new messages)
  useLayoutEffect(() => {
    // Scroll to the bottom of the chat automatically or adjust scroll position
    const scrollToBottom = () => {
      chatEndRef?.current?.scrollIntoView({ behavior: "instant" });
    };

    // Function to adjust scroll position if at the top
    const adjustScroll = () => {
      const chatContainer = chatContainerRef.current; // Reference your scroll container
      if (chatContainer) {
        console.log("ScrollTop Before Adjustment:", chatContainer.scrollTop);
        if (chatContainer.scrollTop === 0) {
          chatContainer.scrollTop = 500; // Set the scroll position
          console.log("ScrollTop After Adjustment:", chatContainer.scrollTop);
        }
      }
    };

    if (!isScroll) {
      scrollToBottom();
    } else {
      adjustScroll();
    }
  }, [messages, isScroll, chatEndRef]);

  //set conversations when we select a contact.
  useEffect(() => {
    if (selectedContact?._id !== prevSelectedContact?._id) {
      setMessages(getMessages?.messages || {});
      setPaginationModel({ page: 0, pageSize: 10 });
      setPrevSelectedContact(selectedContact);
    }
  }, [
    selectedContact,
    prevSelectedContact,
    setMessages,
    getMessages?.messages,
    setPaginationModel,
  ]);

  //set conversations when we pagination occur.
  useEffect(() => {
    if (getMessages?.messages) {
      setMessages((prev: any) => ({
        ...prev,
        ...(getMessages?.messages || {}),
      }));
    }
  }, [getMessages, setMessages]);

  const handleScroll = (event: any) => {
    const isFetchedAllUsers =
      Object.keys(messages || {})?.length === totalMessages;
    const container = event.currentTarget;

    // Check if scrollbar exists
    const isScrollable = container.scrollHeight > container.clientHeight;
    const isAtTop = container.scrollTop === 0;

    if (isScrollable && !isFetchedAllUsers && isAtTop) {
      setScroll(true);
      setPaginationModel((prev: any) => ({
        ...prev,
        page: prev.page + 1,
      }));
    }
  };

  const handleShowLetterPreview = (
    fileUrl: any,
    fileName?: any,
    mimeType?: any
  ) => {
    const modal: any = showModal(PreviewChatMediaFile, {
      onClose: () => modal.hide(),
      fileUrl: fileUrl,
      title: fileName,
      mimetype: mimeType,
    });
  };

  return (
    <Box
      flex={1}
      p={2}
      bgcolor="grey.50"
      overflow="auto" // Enable scroll bar
      onScroll={handleScroll}
      ref={chatContainerRef}
      sx={{
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: !!Object.keys(messages || {})?.length
          ? "normal"
          : "center",
        alignItems: !!Object.keys(messages || {})?.length ? "normal" : "center",
      }}
    >
      {!isScroll && isLoadingMessages ? (
        <TableSkeleton />
      ) : (
        <DelayedRender
          delay={500}
          fallback={<TableSkeleton />}
          chatEndRef={chatEndRef}
        >
          <Box>
            <Stack mb={2} alignItems={"center"}>
              {isLoadingMessages && <CircularProgress />}
            </Stack>
            {!!Object.keys(messages)?.length ? (
              _.sortBy(Object.keys(messages), (date) => new Date(date))?.map(
                (data: any, index: number) => {
                  return (
                    <Stack key={index}>
                      <Box alignSelf={"center"} mb={2}>
                        <Chip
                          label={formatDate(data, true)}
                          variant="filled"
                          color="default"
                        />
                      </Box>
                      {messages[data]?.map((msg: any, index: number) => {
                        const name =
                          msg?.sender?.firstName + " " + msg?.sender?.lastName;

                        return (
                          <Box
                            key={index}
                            display="flex"
                            justifyContent={
                              msg?.sender?._id === userId
                                ? "flex-end"
                                : "flex-start"
                            }
                            mb={2}
                            alignItems={"flex-end"}
                          >
                            <Box
                              bgcolor={
                                msg?.sender?._id === userId
                                  ? "lightblue"
                                  : "lightpink"
                              }
                              p={1}
                              borderRadius="10px"
                              maxWidth={"50%"}
                              sx={{ wordWrap: "break-word" }}
                            >
                              {selectedContact?.isGroupChat &&
                                msg?.sender?._id !== userId && (
                                  <Typography fontWeight={"bold"} mb={1}>
                                    {name}
                                  </Typography>
                                )}
                              {msg?.message && (
                                <Box>
                                  <Typography>{msg?.message || ""}</Typography>
                                </Box>
                              )}
                              {msg?.attachments?.map(
                                (attachment: any, index: number) => (
                                  <Box mt={1} key={index}>
                                    {/* Check if the file is an image by the file extension */}
                                    <Chip
                                      label={
                                        attachment?.name ||
                                        "Download Attachment"
                                      }
                                      onClick={() =>
                                        handleShowLetterPreview(
                                          attachment?.url,
                                          attachment?.name,
                                          attachment?.mimetype
                                        )
                                      }
                                      style={{
                                        backgroundColor: "white", // Soft, neutral background
                                        color: "#003366", // Dark blue text for contrast
                                      }}
                                      variant="outlined"
                                      icon={
                                        <IconFile style={{ width: "20px" }} />
                                      }
                                    />

                                    {/* {/\.(jpeg|jpg|gif|png|bmp)$/i.test(attachment?.url) ? (
                  <a
                    href={attachment?.url}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={attachment?.url}
                      alt={attachment?.name || "Attachment"}
                      style={{
                        maxWidth: "100%",
                        maxHeight: "50px", // Set a maximum height for the image
                        objectFit: "contain", // Maintain aspect ratio
                        borderRadius: "8px", // Optional: adds rounded corners
                      }}
                    />
                  </a>
                ) : (
                  <a
                    href={attachment?.url}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Chip
                      label={attachment?.name || "Download Attachment"}
                      style={{
                        backgroundColor: "white", // Soft, neutral background
                        color: "#003366", // Dark blue text for contrast
                      }}
                      variant="outlined"
                      icon={<IconFile style={{ width: "20px" }} />}
                    />
                  </a>
                )} */}
                                  </Box>
                                )
                              )}
                            </Box>
                            <Typography
                              variant="caption"
                              align="right"
                              mt={2}
                              ml={0.5}
                            >
                              {moment(msg?.createdAt).format("LT")}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Stack>
                  );
                }
              )
            ) : (
              <Typography textAlign={"center"}>
                No messages available.
              </Typography>
            )}
            <Box ref={chatEndRef} />
          </Box>
        </DelayedRender>
      )}
    </Box>
  );
};

export default Conversations;
