import { Box, ThemeProvider } from "@mui/material";
import ModalProvider from "mui-modal-provider";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./App.css";
import useAuth from "./hooks/useAuth";
import { loginUser } from "./redux/user/userSlice";
import Routes from "./routes";
import themes from "./themes";
// import io from "socket.io-client";
import { onMessageListener } from "./utils/fireBaseUtils";
import { useSnackbarHelper } from "./views/components/Snackbar";
// import { useSocket } from "./hooks/useSocket";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router-dom";
import CustomSnackbar from "./CustomSnackbar";
import defaultPic from "./assets/images/profilepic.avif";
import useUser from "./hooks/useUser";

const useCustomSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const showSnackbar = (
    imagePath: string,
    title: string,
    body: string,
    bgcolor: string
  ) => {
    enqueueSnackbar("Custom Snackbar", {
      content: (key) => (
        <CustomSnackbar
          avatarSrc={imagePath || defaultPic}
          title={title}
          body={body}
          bgcolor={bgcolor}
          onClose={() => closeSnackbar(key)}
        />
      ),
    });
  };

  return showSnackbar;
};

function App() {
  const { request } = useAuth();
  const dispatch = useDispatch();
  const showSnackbar = useSnackbarHelper();
  const location = useLocation();
  const { userLogout } = useUser();

  // const socket = useSocket();
  const sessionExpiredRef = useRef(false); // Track if session expired (useRef)
  const customization = useSelector((state: any) => state.customization);
  const { user } = useSelector((state: any) => state.user);

  const [notification, setNotification] = useState<any>([]);

  const showSnackbar2 = useCustomSnackbar();

  useEffect(() => {
    const unsubscribe = onMessageListener((payload: any) => {
      const messageId = payload?.data?.messageId || "";

      // Show Snackbar notification for each message.
      if (
        location?.pathname !== "/sms" &&
        location.search !== "?tab=Messages"
      ) {
        const isMessageAlreadyExist = notification?.includes(messageId);

        if (!isMessageAlreadyExist) {
          showSnackbar2(
            `${payload?.data?.profileImg}`,
            `${payload?.data?.title?.replace("New message from ", "")}`,
            `${payload?.data?.body?.length > 30 ? payload?.data?.body?.slice(0, 40) + "..." : payload?.data?.body}`,
            "#2d3e50"
          );
          setNotification((prev: any) => [...prev, messageId]);
        }
      }
    });

    // Cleanup the listener on component unmount
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line
  }, [location, notification]);

  // Handle token expiration and log out user if token is expired
  // useEffect(() => {
  //   if (user?.token) {
  //     const decodedToken: any = jwtDecode(user.token);
  //     const isExpired = decodedToken.exp * 1000 < Date.now();

  //     if (isExpired) {
  //       dispatch(loginUser(null));
  //       showSnackbar("Your session has expired. Please log in again.", "error");
  //     }
  //   }
  // }, [user?.token, dispatch, showSnackbar]);

  // Check if user exists in the database
  useEffect(() => {
    checkUser();

    // eslint-disable-next-line
  }, []);

  const checkUser = async () => {
    if (user?.userId) {
      try {
        const response = await request.get(`/users/${user.userId}`);
        if (!response?.data) {
          dispatch(loginUser(null));
          // showSnackbar("User does not exist.", "error");
        }
      } catch (error: any) {
        const statusCode = error?.response?.status || 500;
        const message = error?.response?.data?.message || "An error occurred";
        if (statusCode === 401 && message === "Invalid or expired token") {
          await userLogout();
          dispatch(loginUser(null));

          if (!sessionExpiredRef.current) {
            sessionExpiredRef.current = true; // Mark session as expired
            showSnackbar(
              "Your session has expired. Please sign in again to continue.",
              "error"
            );
          }
        } else if (statusCode === 401 || statusCode === 404) {
          // await userLogout();
          dispatch(loginUser(null));

          // showSnackbar("User does not exist.", "error");
        }
      }
    }
  };

  // Handle socket connection and logout event
  // useEffect(() => {
  //   const handleLogOutUser = (user1: any) => {
  //     if (user1?.userId === user?.userId) {
  //       dispatch(logoutUser());
  //       localStorage.clear();
  //       navigate("/login");
  //       showSnackbar(user1?.message, "error");
  //     }
  //   };

  //   socket.on("logOut_user", handleLogOutUser);

  //   return () => {
  //     socket.off("logOut_user", handleLogOutUser);
  //   };
  // }, [user?.userId, dispatch, navigate, showSnackbar]);

  return (
    <ModalProvider>
      <ThemeProvider theme={themes(customization)}>
        <Box sx={{ backgroundColor: "#F3F6FE", height: "100vh" }}>
          <Routes />
        </Box>
      </ThemeProvider>
    </ModalProvider>
  );
}

export default App;
