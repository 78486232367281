import { Box, Button, DialogProps, Typography } from "@mui/material";

import { contactTypes } from "../../../types/contactTypes";
import BasicModal from "../../components/modal/BasicModal";
import useTodo from "../../../hooks/useTodo";
import useLetters from "../../../hooks/useLetters";
import { useSnackbarHelper } from "../../components/Snackbar";
import useCases from "../../../hooks/useCases";
interface Props extends DialogProps {
  onClose: () => void;
  todoId?: string;
  data?: contactTypes;
  caseId?: string;
  title?: string;
  template: string;
  isMasterTodoList?: boolean;
  recipient?: string;
  isWorkflowTask?: boolean;
  supportContactMail?: string;
  refetchProgress?: any;
}

function ConfirmationModal({
  onClose,
  onSubmit,
  template,
  caseId,
  todoId,
  isMasterTodoList,
  recipient,
  isWorkflowTask = false,
  supportContactMail,
  refetchProgress,
  ...props
}: Props) {
  const { markTodoAsComplete, isMarkingAsCompleteTodo } = useTodo(
    caseId,
    undefined,
    undefined,
    undefined,
    true,
    isMasterTodoList
  );
  const { caseData } = useCases(caseId);
  const { getLetterData, isLetterLoading } = useLetters({ caseId });

  const showSnackbar = useSnackbarHelper();

  const sentLetterToClient = async (template: any) => {
    try {
      const response: any = await getLetterData({
        template: template,
        sendMail: true,
        recipient,
      });

      const data = response?.data;

      const message = data?.message || "Success";

      showSnackbar(message, "success");
    } catch (error: any) {
      const message = error?.response?.data?.message || "An error occurred";
      showSnackbar(message, "error");
    }
  };
  const handleOnSubmit = async () => {
    const payload = {
      isEmailSentToClient: false,
      isWorkflowTask,
      lawyer: caseData?.result?.lawyer?.email,
    };

    if (!!template?.length) {
      //Sent letter to client
      await sentLetterToClient(template);
      payload.isEmailSentToClient = true;
    }

    //Mark todo as complete
    const response = await markTodoAsComplete({ todoId, payload });

    const isWorkflowCompleted = response?.data?.isWorkflowCompleted;

    if (isWorkflowCompleted && !!refetchProgress) {
      refetchProgress();
    }

    showSnackbar("Todo marked as completed", "success");
    onClose();
  };

  const isValidEmail = (email?: string) => {
    // Regex for basic email validation
    const emailRegex =
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|org|net|edu|gov|mil|co|io|info|biz|name|me|mobi|ly|ai|us|uk|ca|au|de|fr|jp|cn|ru|br|in|za|nl|se|no|fi|dk|it|es|pt|ch|at|be|pl|gr|cz|ro|hu|il|mx|ar|cl|nz|kr|tr|tw|hk|sg|my|id|ph|th|vn)$/;

    return email ? emailRegex.test(email) : false;
  };

  return (
    <BasicModal
      onClose={onClose}
      onSubmit={handleOnSubmit}
      title={"Complete To Do"}
      content={
        <Box>
          Are you sure you want to mark this todo as completed?
          {isWorkflowTask && !!recipient && (
            <Typography color={"primary"} mt={1}>
              Any attached letter will be automatically sent to{" "}
              {isValidEmail(recipient)
                ? recipient
                : `the support contact (${supportContactMail}).`}
            </Typography>
          )}
        </Box>
      }
      actions={
        <>
          <Button
            color="error"
            variant="outlined"
            onClick={onClose}
            disabled={isMarkingAsCompleteTodo || isLetterLoading}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ textTransform: "capitalize" }}
            type="submit"
            color="success"
            disabled={isMarkingAsCompleteTodo || isLetterLoading}
          >
            Complete
          </Button>
        </>
      }
      {...props}
    />
  );
}

export default ConfirmationModal;
