import { Box, DialogProps } from "@mui/material";
import BasicModal from "../../components/modal/BasicModal";

interface Props extends DialogProps {
  onClose: () => void;
  buffer?: any; // Buffer of the PDF data to preview.
  title?: any;
}

const PreviewPdfModal = ({ onClose, buffer, title, ...props }: Props) => {
  const dataUrl = `data:application/pdf;base64,${buffer}`;

  return (
    <BasicModal
      onClose={onClose}
      title={title ? title : "Preview Letter"}
      size="lg"
      height={800}
      content={
        <Box>
          {buffer && (
            <iframe
              title="PDF Viewer"
              src={dataUrl + "#zoom=100"
              }
              style={{ width: "100%", height: "calc(100vh - 250px)" }}
            />
          )}
        </Box>
      }
      actions={<></>}
      {...props}
    />
  );
};

export default PreviewPdfModal;