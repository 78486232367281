import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
} from "@mui/material";
import { useModal } from "mui-modal-provider";
import AddNewCaseModal from "../cases/AddNewCaseModal";
import AddSupportContactModal from "../cases/AddSupportContactModal";
import DialogClose from "../../components/buttons/DialogClose";

interface Props extends DialogProps {
  data?: any;
  open: boolean;
  setOpen: any;
}
const CreateUserModal = ({ open, setOpen, data }: Props) => {
  const { showModal } = useModal();

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    if (!!data) {
      const modal: any = showModal(AddSupportContactModal, {
        onClose: () => {
          modal.hide();
        },
        data: data?.data,
        type: data?.type,
        contactId: data?.contactId,
        caseId: data?.caseId,
      });
    } else {
      const modal: any = showModal(AddNewCaseModal, {
        onClose: () => {
          modal.hide();
        },
      });
    }
    handleClose();
  };

  return (
    <Box>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "15px",
            p: 0,
          },
        }}
      >
        <DialogTitle
          sx={{
            fontSize: "24px",
            color: "#364152",
            fontWeight: 600,
            fontFamily: "'Noto Sans Arabic', sans-serif",
            zIndex: 1050,
          }}
        >
          Info <DialogClose onClose={handleClose} />
        </DialogTitle>
        <Divider />
        <DialogContent
          sx={{
            color: "#364152",
            maxHeight: 450,
            fontSize: "16px",
            fontWeight: 500,
            fontFamily: "'Poppins', sans-serif",
          }}
        >
          {!!data
            ? "Adding an additional contact will create a user account for the additional contact. Login credentials will be send via email."
            : "Adding a new case will create separate user accounts for the support and additional contact. Login credentials will be sent via email."}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            color="error"
            variant="outlined"
            sx={{ textTransform: "capitalize" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            sx={{ textTransform: "capitalize" }}
            onClick={onSubmit}
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CreateUserModal;
