// material-ui
import { Box, Stack } from "@mui/material";

// project imports

// third party
import { useNavigate } from "react-router";
import * as Yup from "yup";
import useOtp from "../../../hooks/useOtp";
import FormContainer from "../../components/FormContainer";
import { useSnackbarHelper } from "../../components/Snackbar";
import SubmitButton from "../../components/buttons/SubmitButton";
import AnimateButton from "../../components/extended/AnimateButton";
import MuiTextField from "../../components/inputs/MuiTextField";

// ========================|| FIREBASE - FORGOT PASSWORD ||======================== //

/**
 * This component provides a UI for users to request a password reset.
 * @component ForgotPassword
 * @author Sanjay
 *
 */

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const JWTDeleteAccount = () => {
  const navigate = useNavigate();
  const { sendOtp, isSendingOtp } = useOtp();
  const showSnackbar = useSnackbarHelper();
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Must be a valid email")
      .max(255)
      .required("Required")
      .matches(emailRegex, "Must be a valid email"),
  });

  type FormValues = Yup.InferType<typeof validationSchema>;

  const initialValues = {
    email: "",
  };

  const onFormSubmit = async (values: FormValues) => {
    const { email } = values;
    try {
      const data = await sendOtp({ email });

      const message = data?.message || "Success";
      showSnackbar(message, "success");

      navigate("/delete-account-verification");

      localStorage.setItem("deleteEmail", email);
    } catch (error: any) {
      const message = error?.response?.data?.message || "An error occurred";
      const statusCode = error?.response?.status;

      if (statusCode === 400) {
        navigate("/login");
      }

      showSnackbar(message, "error");
    }
  };

  return (
    <FormContainer
      validation={validationSchema}
      defaultValues={initialValues}
      onSuccess={onFormSubmit}
    >
      <Stack sx={{ mb: 2 }} spacing={1}>
        <MuiTextField
          name="email"
          //   onKeyUp={checkEmailFirst}
          type="email"
          label="Email"
          required
        />
      </Stack>

      <Box sx={{ mt: 2 }}>
        <AnimateButton>
          <SubmitButton
            disableElevation
            fullWidth
            disabled={isSendingOtp}
            size="large"
            type="submit"
            variant="contained"
          >
            Send OTP
          </SubmitButton>
        </AnimateButton>
      </Box>
    </FormContainer>
  );
};

export default JWTDeleteAccount;
