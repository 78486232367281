import { Box, Button, DialogProps } from "@mui/material";
import BasicModal from "../../components/modal/BasicModal";
import { useSnackbarHelper } from "../../components/Snackbar";
import { useEffect, useState } from "react";

interface Props extends DialogProps {
  onClose: () => void;
  fileUrl?: any; // Buffer of the PDF data to preview.
  title?: any;
  mimetype?: any;
}

const PreviewChatMediaFile = ({
  onClose,
  fileUrl,
  title,
  mimetype,
  ...props
}: Props) => {
  const showSnackbar = useSnackbarHelper();
  const [loadError, setLoadError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isLoaded) {
        setLoadError(true);
      }
    }, 5000); // 5 seconds timeout for loading
    return () => clearTimeout(timer);
  }, [isLoaded]);

  const handleDownload = async () => {
    try {
      setIsDownloading(true);
      await fetch(fileUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.download = title || "downloaded-file";
          document.body.appendChild(link);

          link.click();

          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        });
      setIsDownloading(false);
    } catch (error) {
      setIsDownloading(false);
      console.log(error);
    }
  };

  // const handleDownload = async () => {
  //   try {
  //     setIsDownloading(true);
  //     const response = await fetch(fileUrl, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": mimetype,
  //         Origin: "http://localhost:3000",
  //       },
  //     });
  //     console.log(response);
  //     if (!response.ok) {
  //       setIsDownloading(false);
  //       throw new Error(`HTTP error! Status: ${response.status}`);
  //     }

  //     const blob = await response.blob();
  //     const url = window.URL.createObjectURL(blob);

  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.download = title || "downloaded-file";
  //     document.body.appendChild(link);

  //     link.click();

  //     document.body.removeChild(link);
  //     window.URL.revokeObjectURL(url);
  //     setIsDownloading(false);
  //   } catch (error) {
  //     console.error("Error downloading the file:", error);
  //     setIsDownloading(false);
  //   }
  // };

  return (
    <BasicModal
      onClose={onClose}
      title={title ? title : "Preview Media File"}
      size="lg"
      height={800}
      isDownloading={isDownloading}
      content={
        <Box>
          {(fileUrl &&
            mimetype ===
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document") ||
          mimetype ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          mimetype === "image/tiff" ? (
            <iframe
              title="PDF Viewer"
              src={
                `https://docs.google.com/gview?url=${fileUrl}&embedded=true` +
                "#zoom=100"
              }
              style={{ width: "100%", height: "calc(100vh - 250px)" }}
              loading="lazy"
            />
          ) : fileUrl && mimetype.startsWith("image/") ? (
            // <Box
            //   style={{
            //     display: "flex",
            //     justifyContent: "center",
            //     alignItems: "center",
            //     height: "calc(100vh - 400px)"// Ensure the parent container has a height
            //   }}
            // >
            //    <iframe
            //     title="PDF Viewer"
            //     src={fileUrl + "#zoom=100"}
            //     style={{ width: "100%", height: "calc(100vh - 250px)" }}
            //   />
            // </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "calc(100vh - 400px)",
                overflow: "hidden",
              }}
            >
              <img
                src={fileUrl}
                alt={title || "Preview"}
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  border: "none",
                }}
              />
            </Box>
          ) : fileUrl && mimetype.startsWith("audio/") ? (
            <audio controls style={{ width: "100%" }}>
              <source src={fileUrl} type={mimetype} />
              Your browser does not support the audio element.
            </audio>
          ) : (
            fileUrl && (
              <Box>
                {!loadError ? (
                  <iframe
                    title="PDF Viewer"
                    src={fileUrl + "#zoom=100"}
                    style={{ width: "100%", height: "calc(95vh - 250px)" }}
                    onLoad={() => setIsLoaded(true)}
                  >
                    {" "}
                    {title}
                  </iframe>
                ) : (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      width: "100%",
                      height: "calc(70vh - 150px)",
                      fontSize: "30px",
                    }}
                  >
                    {title}
                  </Box>
                )}
              </Box>
            )
          )}
        </Box>
      }
      actions={
        // !mimetype.startsWith("audio/") && (
        <Button
          variant="contained"
          sx={{ m: 1, textTransform: "none" }}
          onClick={handleDownload}
          disabled={isDownloading}
        >
          Download
        </Button>
        // )
      }
      {...props}
    />
  );
};

export default PreviewChatMediaFile;
