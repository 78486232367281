import { useMutation, useQuery } from "react-query";
import useAuth from "./useAuth";

/**
 * custom hook for caseNotes
 * @hook
 * @author Tushar Sharma
 */

export default function useCaseNotes({search,caseId}:any) {
  const { request } = useAuth();
  const { mutateAsync: addCaseNotes,isLoading:isAddingNotes } = useMutation(
    "/caseNotes",
    (payload: any) =>
      request
        .post("/caseNotes", payload)
        .then((response) => response?.data as any),
        {
          onSuccess: () => refetchCaseNotes(),
        }
  );
  // Fetch contacts with the specified parameters
  const {
    data,
    refetch: refetchCaseNotes,
    isLoading: isContactLoading,
    isRefetching,
  } = useQuery(
    ["/caseNotes", search,caseId],
    () =>
      request
        .get("/caseNotes", { params: { search,caseId } })
        .then((response) => response?.data?.notes),
  );

  // Fetch all case notes
  const { data: allCaseNotes, isLoading: isAllCaseNotesLoading,refetch:refetchAllCaseNotes } = useQuery(
    ["/allcasenotes",caseId],
    () => request.get("/caseNotes", { params: { caseId } }).then((response) => response?.data?.notes)
  );

  const { mutateAsync: editCaseNotes } = useMutation(
    "/caseNotes:id",
    ({id,payload}:{id:string,payload: any}) =>
      request
        .put(`/caseNotes/${id}`, payload)
        .then((response) => response?.data as any),
        {
          onSuccess: () => refetchCaseNotes(),
        }
  );

  const {mutateAsync: deleteCaseNote,isLoading :deleteLoading} = useMutation("caseNotes/delete",
    (id:string) => request.delete(`caseNotes/${id}`).then((response) => response?.data) ,
    {
      onSuccess: () => refetchCaseNotes(),
    }
  );
  return {
    addCaseNotes,
    isAddingNotes,
    caseNotes:data,
    allCaseNotes,
    isAllCaseNotesLoading,
    editCaseNotes,
    deleteCaseNote,
    refetchCaseNotes
  };
}
