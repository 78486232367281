import React, { useEffect, useState } from "react";

interface DelayedRenderProps {
  delay: number;
  fallback: React.ReactNode;
  children: React.ReactNode;
  chatEndRef?: any;
}

const DelayedRender = ({
  delay,
  fallback,
  children,
  chatEndRef,
}: DelayedRenderProps) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setIsVisible(true), delay);
    return () => clearTimeout(timer); // Clean up on unmount
  }, [delay]);

  useEffect(() => {
    if (isVisible) {
      chatEndRef?.current?.scrollIntoView({ behavior: "instant" });
    }
  }, [isVisible, chatEndRef]);

  return isVisible ? <>{children}</> : <>{fallback}</>;
};

export default DelayedRender;
