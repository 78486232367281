import AttachFileIcon from "@mui/icons-material/AttachFile";
import MessageIcon from "@mui/icons-material/Message";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SendIcon from "@mui/icons-material/Send";
import {
  Avatar,
  Box,
  Chip,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import Badge from "@mui/material/Badge";
import { GridPaginationModel } from "@mui/x-data-grid";
import { IconMessage } from "@tabler/icons-react";
import { debounce } from "lodash";
import moment from "moment";
import { useModal } from "mui-modal-provider";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useAuth from "../../../hooks/useAuth";
import useChat from "../../../hooks/useChat";
import useUser from "../../../hooks/useUser";
import { useSnackbarHelper } from "../../components/Snackbar";
import SearchField from "../../components/inputs/SearchField";
import ContactList from "./ContactList";
import Conversations from "./Conversations";
import CreateChatModal from "./CreateChatModal";
import UpdateGroupModal from "./UpdateGroupModal";

// Style to show online users
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

export const formatDate = (date: string | Date, showToday?: boolean) => {
  const inputDate = moment(date);
  const today = moment();
  const yesterday = today.clone().subtract(1, "days");

  if (inputDate.isSame(today, "day")) {
    return showToday ? "Today" : inputDate.format("hh:mm A"); // Return time if it's today
  }

  if (inputDate.isSame(yesterday, "day")) {
    return "Yesterday"; // Return 'Yesterday' if it's yesterday
  }

  return inputDate.format("MM/DD/YYYY"); // Return date in MM/DD/YYYY format
};

function shortenFileName(fileName: string, maxLength: number = 20): string {
  const extensionIndex = fileName.lastIndexOf(".");

  // Handle files without extensions
  if (extensionIndex === -1 || fileName.length <= maxLength) {
    return fileName; // Return original if it's shorter than maxLength
  }

  const name = fileName.substring(0, extensionIndex); // File name without extension
  const extension = fileName.substring(extensionIndex); // File extension

  if (fileName.length <= maxLength) {
    return fileName;
  }

  // Start part (first N characters) and end part (last N characters of name)
  const startPart = name.substring(0, 15);
  const endPart = name.substring(name.length - 5);

  return `${startPart}..${endPart}${extension}`;
}

const Messages = ({
  socket,
  isCase,
  caseData,
  selectedContact,
  setSelectedContact,
}: any) => {
  const { userId } = useAuth();
  const { showModal } = useModal();

  const [isScroll, setScroll] = useState(false);

  const { user } = useUser(userId);
  const showSnackbar = useSnackbarHelper();

  const [messages, setMessages] = useState<any>({});
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  });

  const [search, setSearch] = useState<string>("");

  const {
    getMessages,
    totalMessages,
    isLoadingMessages,
    sendMessages,
    isSendingMessage,
    readChat,
    refetchById,
    refetchChats,
    chat,
  } = useChat(selectedContact?._id, paginationModel, search, caseData?._id);

  const [message, setMessage] = useState("");
  const [attachedFiles, setAttachedFiles] = useState<any>([]);

  const chatEndRef: any = useRef<HTMLDivElement | null>(null);

  const [onlineUsers, setOnlineUsers] = useState<any>([]);
  const [chatColor, setChatColor] = useState("");

  const loggedUser = useMemo(() => {
    if (!user) {
      return { name: "Loading...", avatar: "/path/to/default-avatar.png" };
    }

    return {
      name: `${
        user?.firstName.length > 10
          ? `${user?.firstName.slice(0, 10)}..`
          : user?.firstName
      } ${
        user?.lastName.length > 10
          ? `${user?.lastName.slice(0, 10)}..`
          : user?.lastName
      }`,
      avatar: user?.profileImg || "/path/to/admin-avatar.png",
    };
  }, [user]);

  const chatStatus = useMemo(() => {
    const isGroupChat = selectedContact?.isGroupChat;
    const receiver = isGroupChat
      ? selectedContact?.receiver
      : selectedContact?.receiver?.[0];

    if (isGroupChat) {
      const isActiveReceiverFound = selectedContact?.receiver?.find(
        (r: any) => r?.isActive === true && r?.isDeleted !== true
      );

      const isReceiverFound = selectedContact?.receiver?.find(
        (r: any) => r?.isDeleted !== true
      );

      const onlineUserSet = new Set(onlineUsers);
      const isAnyUserOnline =
        selectedContact?.receiver?.some((r: any) =>
          onlineUserSet.has(r?._id)
        ) ?? false;

      if (isAnyUserOnline) {
        setChatColor("green");
        return "Online";
      }

      if (!isActiveReceiverFound) {
        setChatColor("error");
        return "Inactive Members";
      }

      // if (isDeletedUser) {
      //   setChatColor("error");
      //   return "Case Closed";
      // }

      if (!isReceiverFound) {
        setChatColor("error");
        return "No Members";
      }
    } else {
      if (onlineUsers?.includes(receiver?._id)) {
        setChatColor("green");
        return "Online";
      }

      if (!receiver?.isActive) {
        setChatColor("error");
        return "Inactive";
      }

      if (receiver?.isDeleted) {
        setChatColor("error");
        return "Case Closed";
      }
    }

    return "";
  }, [onlineUsers, selectedContact]);

  const resetConversation = () => {
    setPaginationModel({
      page: 0,
      pageSize: 10,
    });
  };

  useEffect(() => {
    // Listener for online users update
    const handleOnlineUsers = (users: any[]) => {
      setOnlineUsers(users);
    };

    const handleMessage = (message: any) => {
      if (!message) return;

      // Reset conversation and scroll state
      resetConversation();
      setScroll(false);

      if (message.chat === selectedContact?._id) {
        // Add the new message to state
        readChat({ chatId: selectedContact?._id });
        refetchById();
      }

      refetchChats();
    };

    // Add event listeners
    socket?.on("onlineUsers", handleOnlineUsers);
    socket?.on("message", handleMessage);

    // Cleanup on component unmount
    return () => {
      socket?.off("onlineUsers", handleOnlineUsers);
      socket?.off("message", handleMessage);
    };
  }, [
    socket,
    refetchById,
    readChat,
    refetchChats,
    selectedContact,
    paginationModel,
  ]);

  // Add debounce to hold render events
  const debouncedSetSearch = useRef(
    debounce((value) => setSearch(value), 400)
  ).current;

  const handleSearchChange = useCallback(
    (event: any) => {
      debouncedSetSearch(event.target.value.trim());
    },
    [debouncedSetSearch]
  );

  const isGroupChat = useMemo(
    () => !!selectedContact?.isGroupChat,
    [selectedContact]
  );

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(e.target.files || []);
    const maxFileSize = 50 * 1024 * 1024; // 50 MB in bytes
    const maxFileCount = 10; // Maximum number of files allowed

    if (selectedFiles?.length) {
      const filterDuplicateFiles = selectedFiles.filter(
        (file) =>
          !attachedFiles?.some(
            (attachedFile: any) => attachedFile.name === file.name
          )
      );

      // Check for file count limit
      if (attachedFiles?.length + filterDuplicateFiles?.length > maxFileCount) {
        showSnackbar(
          `You can only upload a maximum of ${maxFileCount} files.`,
          "error"
        );
        e.target.value = ""; // Clear the file input
        return;
      }

      //get total file sizes
      const totalOversizedFileSize = filterDuplicateFiles.reduce(
        (total, file) => total + file.size,
        0
      );

      if (totalOversizedFileSize > maxFileSize) {
        showSnackbar("The file size exceeds the 50 MB limit.", "error");
        return;
      }

      // // Check for file size limit
      // const oversizedFiles = filterDuplicateFiles.filter(
      //   (file) => file.size > maxFileSize
      // );

      // if (oversizedFiles?.length > 0) {
      //   showSnackbar(
      //     oversizedFiles?.length === 1
      //       ? `The file size exceeds the 50 MB limit for ${shortenFileName(oversizedFiles[0]?.name)}.`
      //       : "Some files exceed the maximum size of 50 MB.",
      //     "error"
      //   );
      //   e.target.value = ""; // Clear the file input

      //   const underSizedFiles = filterDuplicateFiles.filter(
      //     (file) => file.size <= maxFileSize
      //   );

      //   setAttachedFiles((prevFiles: any[]) => [
      //     ...prevFiles,
      //     ...underSizedFiles,
      //   ]);

      //   return;
      // }

      // Append new files to the existing files
      setAttachedFiles((prevFiles: any[]) => [
        ...prevFiles,
        ...filterDuplicateFiles,
      ]);

      // Reset the file input to allow selecting the same file again
      e.target.value = "";
    }
  };

  const handleSendMessage = async () => {
    setScroll(false);

    const { receiver } = selectedContact;

    const receiverIds = receiver?.map((r: any) => r?._id);

    const formData = new FormData();

    // Append each property of newMessage individually to formData
    formData.append("sender", user?._id || "");
    formData.append("message", message.trim());
    formData.append("receiver", JSON.stringify(receiverIds));
    formData.append("chat", selectedContact?._id);

    // Convert FileList to an array and append each file
    attachedFiles.forEach((file: any) => {
      formData.append("files", file);
    });

    try {
      const data = await sendMessages(formData);
      const msgId = data?.newMessage?._id;

      setAttachedFiles([]);

      //emit with socket
      const trimRole = user?.role.split("_")[0];
      const formattedRole =
        trimRole.charAt(0).toUpperCase() + trimRole.slice(1).toLowerCase();
      const newMessage = {
        supportId: ["SUPPORT_CONTACT", "ADDITIONAL_CONTACT"].includes(
          user?.role
        )
          ? `${formattedRole}-${user?.caseId?.caseId}`
          : user?.role,
        name: `${user?.firstName} ${user?.lastName}`,
        sender: user?._id,
        message: message.trim(),
        receiver: receiverIds,
        chat: selectedContact?._id,
        fileName: attachedFiles[attachedFiles.length - 1]?.name,
        msgId,
      };
      socket?.emit("sendMessage", newMessage);

      resetConversation();
    } catch (err: any) {
      const errorMessage =
        err?.response?.data?.message ||
        err?.response?.data?.details ||
        "An error occurred";
      showSnackbar(errorMessage, "error");
    }

    setMessage(""); // clear the input
  };

  const handleSelectItem = useCallback(
    async (contact: any) => {
      setSelectedContact(contact);
      resetConversation();
      setMessage(""); // clear message
      setAttachedFiles([]); //clear attachments

      if (!!contact) {
        socket?.emit("join chat", contact?._id);
        readChat({ chatId: contact?._id });
      }

      // await readMessage({ sentTo: contact?.phone });
    },
    [readChat, socket, setSelectedContact]
  );

  const handleCreateChat = useCallback(() => {
    const modal: any = showModal(CreateChatModal, {
      onClose: () => {
        modal.hide();
      },
      caseData: caseData,
      setSelectedContact,
    });

    //eslint-disable-next-line
  }, []);

  const handleUpdateGroup = useCallback(
    (selectedContact?: any, onlineUsers?: any, user?: any) => {
      const modal: any = showModal(UpdateGroupModal, {
        onClose: () => {
          modal.hide();
        },
        chatId: selectedContact?._id,
        onlineUsers,
        user,
      });
    },
    //eslint-disable-next-line
    []
  );

  return (
    <Stack
      direction={"row"}
      justifyContent={"center"}
      sx={{
        backgroundColor: "grey.100",
        height: isCase ? "calc(100vh - 285px)" : "calc(100vh - 220px)",
      }}
    >
      {/* Sidebar */}
      <Box
        margin={1}
        width={isCase ? "35%" : window.innerWidth <= 1440 ? "30%" : "25%"}
        borderRadius="10px"
        sx={{
          backgroundColor: "white",
          padding: 1,
        }}
      >
        {/* Logged User Info */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          m={2}
          spacing={2}
        >
          <Box display="flex" alignItems="center" gap={2}>
            <Avatar
              src={loggedUser?.avatar}
              sx={{ border: "1px solid lightgrey" }}
            />
            <Typography variant="h4">{loggedUser?.name}</Typography>
          </Box>

          {!!caseData && (
            <Tooltip title="New Chat">
              <IconButton
                style={{ marginRight: 10 }}
                onClick={handleCreateChat}
                //  disabled={isChatCreating}
              >
                <MessageIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          )}
        </Stack>

        {/* Search Bar */}
        <Box m={2}>
          <SearchField
            fullWidth
            onChange={handleSearchChange}
            size="small"
            placeholder="Search by name, role or case Id"
          />
        </Box>

        {/* Contact List */}
        <ContactList
          caseId={caseData?._id}
          search={search}
          handleSelectItem={handleSelectItem}
          selectedContact={selectedContact}
          onlineUsers={onlineUsers}
        />
      </Box>

      {/* Chat Section */}
      <Box
        width={isCase ? "65%" : "70%"}
        display="flex"
        flexDirection="column"
        margin={1}
        borderRadius="10px"
        sx={{ backgroundColor: "white" }}
      >
        {/* Header */}

        {!!chat && (
          <Paper
            elevation={1}
            square
            sx={{
              padding: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              border: "none",
              borderRadius: "10px 10px 0px 0px",
            }}
          >
            <Stack direction={"row"}>
              {chatStatus === "Online" ? (
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  variant="dot"
                >
                  <Avatar
                    src={
                      isGroupChat
                        ? chat?.groupImageUrl
                        : selectedContact?.receiver?.[0]?.profileImg
                    }
                  />
                </StyledBadge>
              ) : (
                <Avatar
                  src={
                    isGroupChat
                      ? chat?.groupImageUrl
                      : selectedContact?.receiver?.[0]?.profileImg
                  }
                />
              )}

              <Box ml={2}>
                <Stack spacing={1} direction="row" alignItems="center">
                  <Typography variant="h4">
                    {isGroupChat
                      ? chat?.groupName
                      : selectedContact?.receiver?.[0]?.firstName +
                        " " +
                        selectedContact?.receiver?.[0]?.lastName}
                  </Typography>

                  <Typography variant="body2" color={chatColor}>
                    {chatStatus && `(${chatStatus})`}
                  </Typography>
                </Stack>

                <Typography variant="caption">
                  {isGroupChat
                    ? selectedContact?.caseId?.caseId
                    : [
                        { label: "Lawyer", value: "LAWYER" },
                        { label: "Staff", value: "STAFF" },
                        {
                          label: `Support-${selectedContact?.receiver?.[0]?.caseId?.caseId}`,
                          value: "SUPPORT_CONTACT",
                        },
                        {
                          label: `Additional-${selectedContact?.receiver?.[0]?.caseId?.caseId}`,
                          value: "ADDITIONAL_CONTACT",
                        },
                        {
                          label: "Admin",
                          value: "ADMIN",
                        },
                      ]?.find(
                        (role: any) =>
                          role?.value === selectedContact?.receiver?.[0]?.role
                      )?.label}
                </Typography>
              </Box>
            </Stack>
            {isGroupChat && (
              <Tooltip title={"Edit Group"}>
                <IconButton
                  onClick={() => {
                    handleUpdateGroup(chat, onlineUsers, user);
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
              </Tooltip>
            )}
          </Paper>
        )}

        {/* Chat Messages */}
        {!!selectedContact ? (
          <Conversations
            selectedContact={selectedContact}
            messages={messages}
            setMessages={setMessages}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            getMessages={getMessages}
            totalMessages={totalMessages}
            isLoadingMessages={isLoadingMessages}
            chatEndRef={chatEndRef}
            isScroll={isScroll}
            setScroll={setScroll}
          />
        ) : (
          <Box
            flex={1}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconMessage size={"100px"} color="grey" />
          </Box>
        )}

        {/* Message Input */}
        {!!selectedContact && (
          <Stack direction={"row"} m={0.5} spacing={1} ml={2} mr={2}>
            <TextField
              disabled={
                isSendingMessage ||
                chatStatus === "Case Closed" ||
                chatStatus === "Inactive" ||
                chatStatus === "No Members"
              }
              fullWidth
              placeholder="Type a message..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
              helperText={
                "Upload up to 10 files (max total size: 50 MB) in these formats: Images, TXT, PDF, MP4, CSV, ZIP, DOC/DOCX, XLS/XLSX, MP3."
              }
              InputProps={{
                startAdornment: attachedFiles?.length > 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 0.5,
                      maxWidth: "100%",
                      maxHeight: "60px", // Limit the height of the chips section
                      overflowY: "auto", // Add scrolling if chips overflow
                      padding: "4px 0",
                      marginBottom: "8px", // Increased space between chips and text row
                    }}
                  >
                    {attachedFiles?.map((file: any, index: any) => (
                      <Chip
                        key={index}
                        label={shortenFileName(file.name)}
                        onDelete={() =>
                          setAttachedFiles((prev: any) =>
                            prev?.filter((item: any, i: any) => index !== i)
                          )
                        }
                        size="small"
                      />
                    ))}
                  </Box>
                ),
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mt: -2,
                      mb: attachedFiles?.length > 0 ? 3 : 1.8,
                    }}
                  >
                    <IconButton
                      color="primary"
                      disableRipple
                      disabled={
                        isSendingMessage ||
                        chatStatus === "Case Closed" ||
                        chatStatus === "Inactive" ||
                        chatStatus === "No Members"
                      }
                      component="label"
                    >
                      <AttachFileIcon />
                      <input
                        multiple
                        type="file"
                        hidden
                        onChange={handleFileChange} // Handle file selection here
                        accept="image/*,application/pdf,video/mp4,video/webm,video/avi,video/mkv,text/csv,application/zip,application/x-zip-compressed,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,audio/mpeg"
                      />
                    </IconButton>
                    <IconButton
                      color="primary"
                      onClick={handleSendMessage}
                      disableRipple
                      disabled={
                        isSendingMessage ||
                        (!message.trim() && attachedFiles?.length < 1)
                      }
                    >
                      <SendIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiInputBase-root": {
                  display: "flex",
                  flexDirection: attachedFiles?.length > 0 ? "column" : "row", // Dynamic layout
                  alignItems: "stretch",
                  height: "auto",
                  padding: "8px", // Inner padding
                },
                "& .MuiInputAdornment-root": {
                  alignSelf: "flex-end", // Align icons to the right
                },
                "& .MuiInputBase-input": {
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center", // Align text and icons in the same row
                  padding: "4px 0", // Minimize input padding
                  maxWidth:
                    attachedFiles?.length > 0
                      ? { sm: "calc(92% - 50px)", lg: "calc(95% - 50px)" }
                      : "100%", // Adjust width if files are attached
                },
              }}
            />
          </Stack>
        )}
      </Box>
    </Stack>
  );
};

export default Messages;
