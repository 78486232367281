import { useMutation, useQuery, useQueryClient } from "react-query";
import useAuth from "./useAuth";
import { jwtDecode } from "jwt-decode";
import { loginUser, logoutUser } from "../redux/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { GridPaginationModel, GridSortModel } from "@mui/x-data-grid";

// Type definitions for various payloads and responses
type Login = {
  email: string;
  password: string;
};

type VerifyOtp = {
  otp: string;
  email: string;
  webFcmToken: string;
};

type User = {
  firstName: string;
  middleName: string;
  lastName: string;
  phoneNumber?: string;
  email: string;
  password: string;
  role: string;
  profileImg: string;
  isActive: boolean;
  canChangeEmail: boolean;
  canChangeMobileNumber: boolean;
  canChangePhoneNumber: boolean;
  canChangePassword: boolean;
};

type ProfileImage = {
  profileImg: string;
  signature: string;
};

type LoginResponse = {
  email: string;
  message: string;
  token: string;
};

type VerifyOtpResponse = {
  token: string;
  message: string;
};

/**
 *  Custom hook for managing user.
 * @hook useUser
 * @author Priyanshu
 *
 */

export default function useUser(
  userId?: string,
  search?: string,
  paginationModel?: GridPaginationModel,
  sortModel?: GridSortModel,
  getAllData = false,
  showDeletedUsers?: boolean,
  caseId?: string,
  isChat?: boolean
) {
  const { request } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { user } = useSelector((state: any) => state.user);

  // Refetch users when needed
  const handleRefetchUsers = () => {
    queryClient.invalidateQueries(["/users"]);
  };

  // User login mutation
  const { mutateAsync: userLogin, isLoading: isLoggingIn } = useMutation(
    "/auth",
    (payload: Login) =>
      request
        .post("/auth", payload)
        .then((response) => response?.data as LoginResponse),
    {
      onSuccess: (data: LoginResponse) => {
        const token = data?.token || "";

        if (!token) {
          const verifyEmail = data?.email || "";
          localStorage.setItem("verifyEmail", verifyEmail);
          navigate("/twoFactorAuthentication");
        } else {
          const decodedToken: any = jwtDecode(token);
          const userId = decodedToken?.id || null;

          if (!userId) {
            console.error("user id not found");
            return;
          }

          dispatch(loginUser({ token, userId }));
          navigate("/cases");
        }
        // const token = data?.token || null;

        // if (!token) {
        //   console.error("Token not found");
        //   return;
        // }

        // const decodedToken: any = jwtDecode(token);
        // const userId = decodedToken?.id || null;

        // if (!userId) {
        //   console.error("user id not found");
        //   return;
        // }

        // localStorage.setItem("authToken", token);

        // dispatch(loginUser({ token, userId }));
      },
    }
  );

  // Verify OTP mutation
  const { mutateAsync: verifyOtp, isLoading: isOtpVerifying } = useMutation(
    "/auth",
    (payload: VerifyOtp) =>
      request
        .post("/auth/verifyOtp", payload)
        .then((response) => response?.data as VerifyOtpResponse),
    {
      onSuccess: (data: VerifyOtpResponse) => {
        localStorage.removeItem("verifyEmail");
        const token = data?.token || "";

        if (!token) {
          console.error("Token not found");
          return;
        }

        const decodedToken: any = jwtDecode(token);
        const userId = decodedToken?.id || null;

        if (!userId) {
          console.error("user id not found");
          return;
        }

        dispatch(loginUser({ token, userId }));
        navigate("/cases");
      },
    }
  );

  // const logoutAPI = useMutation(() => request.post("/auth/logout"));

  // User logout mutation
  const { mutateAsync: userLogout, isLoading: isLoggingOut } = useMutation(
    () =>
      request
        .post(`/auth/logout`, { userId: user?.userId, isSite: true })
        .then((response) => response.data),
    {
      onSuccess: () => {
        dispatch(logoutUser());
        localStorage.removeItem("persist:user");
        navigate("/login");
        // Handle logout success actions like clearing local storage, redirecting, etc.
      },
      onError: (error) => {
        console.error("Logout error:", error);
        // Handle logout error, such as displaying an error message
      },
    }
  );

  // Fetch users query
  const {
    data: usersData,
    isLoading: isLoadingUsers,
    refetch: refetchUserData,
  } = useQuery(
    [
      "/users",
      search,
      paginationModel,
      sortModel,
      getAllData,
      showDeletedUsers,
      caseId,
      isChat,
    ],
    () =>
      request
        .get("/users", {
          params: {
            search,
            paginationModel,
            sortModel,
            getAllData,
            showDeletedUsers,
            caseId,
            isChat,
          },
        })
        .then((response) => response.data as any),
    {
      enabled: !!user,
    }
  );

  // Fetch single user by ID
  const {
    data: userData,
    isLoading: isLoadingUser,
    refetch: refetchUserDataId,
  } = useQuery(
    `/users/${userId}`,
    () =>
      request.get(`/users/${userId}`).then((response) => response.data as any),
    {
      enabled: !!userId,
    }
  );

  // Fetch getRepTypes types for a user
  const { data: getRepTypes, isLoading: isLoadingRepTypes } = useQuery(
    `/users/${userId}/repTypes`,
    () =>
      request
        .get(`/users/${userId}/repTypes`)
        .then((response) => response.data as any),
    {
      enabled: !!userId,
    }
  );

  // Add new user mutation
  const { mutateAsync: addUser, isLoading: isAddingUser } = useMutation(
    "/users",
    (payload: User) =>
      request.post("/users", payload).then((response) => response?.data as any),
    {
      onSuccess: () => {
        handleRefetchUsers();
      },
    }
  );

  // Update user mutation
  const { mutateAsync: updateUser, isLoading: isUpdatingUser } = useMutation(
    `/users/${userId}`,
    (payload?: User) =>
      request
        .put(`/users/${userId}`, payload)
        .then((response) => response?.data as any),
    {
      onSuccess: () => {
        handleRefetchUsers();
        refetchUserDataId();
      },
    }
  );

  // Restore deleted user mutation
  const { mutateAsync: restoreUser, isLoading: isRestoreUserLoading } =
    useMutation(
      `/users/deleted/${userId}`,
      () =>
        request
          .put(`/users/deleted/${userId}`)
          .then((response) => response?.data as any),
      {
        onSuccess: () => handleRefetchUsers(),
      }
    );

  // Update user profile image mutation
  const { mutateAsync: updateProfile, isLoading: isUpdatingProfile } =
    useMutation(
      `/users/${userId}`,
      (payload?: ProfileImage) =>
        request
          .put(`/users/${userId}/profile-img`, payload)
          .then((response) => response?.data as any),
      {
        onSuccess: () => {
          handleRefetchUsers();
          refetchUserDataId();
        },
      }
    );

  // Delete user mutation
  const { mutateAsync: deleteUser, isLoading: isDeletingUser } = useMutation(
    `/users/${userId}`,
    () =>
      request
        .delete(`/users/${userId}`)
        .then((response) => response?.data as any),
    {
      onSuccess: () => {
        handleRefetchUsers();
      },
    }
  );

  const { mutateAsync: changePassword, isLoading: isChangingPassword } =
    useMutation(`/users/${userId}/change-password`, (payload: any) =>
      request
        .put(`/users/${userId}/change-password`, payload)
        .then((response) => response?.data as any)
    );

  const isAdmin = userData?.user?.role === "ADMIN";
  const isStaff = userData?.user?.role === "STAFF";
  const isLawyer = userData?.user?.role === "LAWYER";
  const isSupportContact =
    userData?.user?.role === "SUPPORT_CONTACT" ||
    userData?.user?.role === "ADDITIONAL_CONTACT";

  const { mutateAsync: resendOTP, isLoading: isResendOTPLoading } = useMutation(
    "/resendOtp",
    (payload: any) =>
      request
        .post("/auth/resendOtp", payload)
        .then((response) => response?.data as any),
    {
      onSuccess: () => {
        handleRefetchUsers();
      },
    }
  );
  const { mutateAsync: changeRepTypes, isLoading: isChangingRepTypes } =
    useMutation(
      `/users/${userId}/repTypes`,
      (payload: any) =>
        request
          .put(`/users/${userId}/repTypes`, payload)
          .then((response) => response?.data as any),
      {
        onSuccess: () => {
          handleRefetchUsers();
        },
      }
    );

  // Filter lawyers and staff from the user list
  const lawyers = usersData?.users?.filter(
    (user: any) => user?.role === "LAWYER" && user?.isActive === true
  );

  const staff = usersData?.users?.filter(
    (user: any) => user?.role === "STAFF" && user?.isActive === true
  );

  return {
    addUser,
    isAddingUser,
    verifyOtp,
    isOtpVerifying,
    userLogin,
    isLoggingIn,
    userLogout,
    isLoggingOut,
    users: usersData?.users || [],
    totalUsers: usersData?.totalUsers || 0,
    isLoadingUsers,
    updateUser,
    isUpdatingUser,
    deleteUser,
    isDeletingUser,
    user: userData?.user,
    refetchUserData,
    isLoadingUser,
    refetchUserDataId,
    changePassword,
    isChangingPassword,
    isAdmin,
    isStaff,
    isSupportContact,
    updateProfile,
    isUpdatingProfile,
    isLawyer,
    resendOTP,
    isResendOTPLoading,
    lawyers,
    staff,
    changeRepTypes,
    isChangingRepTypes,
    repTypes: getRepTypes?.repTypes,
    isLoadingRepTypes,
    restoreUser,
    isRestoreUserLoading,
  };
}
