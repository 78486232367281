import { useMutation, useQuery, useQueryClient } from "react-query";
import useAuth from "./useAuth";
import { GridPaginationModel } from "@mui/x-data-grid";

export default function useChat(
  chatId?: string,
  paginationModel?: GridPaginationModel,
  search?: string,
  caseId?: string,
  searchMembers?: string
) {
  const queryClient = useQueryClient();

  // Refetch users when needed
  const refetchChats = () => {
    queryClient.invalidateQueries(["/chats"]);
  };

  const refetchChat = () => {
    queryClient.invalidateQueries(["/chats/chatId"]);
  };
  const { request } = useAuth();

  const { data: chatData, isLoading: isLoadingChats } = useQuery(
    ["/chats", search, caseId],
    () =>
      request.get("/chats", { params: { search, caseId } }).then(({ data }) => {
        return data as any;
      })
  );

  const { mutateAsync: createChat, isLoading: isCreatingChat } = useMutation(
    "/chats",
    (payload: any) =>
      request.post("/chats", payload).then((response) => response?.data as any),
    {
      onSuccess: () => {
        // refetchById();
        refetchChats();
      },
    }
  );

  const { mutateAsync: readChat, isLoading: isReadingChat } = useMutation(
    "/chats/read",
    (payload: any) =>
      request
        .put("/chats/read", payload)
        .then((response) => response?.data as any),
    {
      onSuccess: () => {
        refetchChats();
      },
    }
  );

  const {
    data: getMessages,
    isLoading: isLoadingMessages,
    refetch: refetchById,
  } = useQuery(
    ["/messages", chatId, paginationModel],
    () =>
      request
        .get(`/messages`, {
          params: { chatId, paginationModel },
        })
        .then(({ data }) => {
          return data as any;
        }),
    {
      enabled: !!chatId && !!paginationModel,
    }
  );

  const { mutateAsync: sendMessages, isLoading: isSendingMessage } =
    useMutation(
      "/messages",
      (payload: any) =>
        request
          .post("/messages", payload)
          .then((response) => response?.data as any),
      {
        onSuccess: () => {
          refetchById();
          refetchChats();
        },
      }
    );

  const {
    data: chatUsers,
    isLoading: isLoadingUsers,
    refetch: refetchUsers,
  } = useQuery(["/chats/users", search, caseId], () =>
    request
      .get("/chats/users", {
        params: { search, caseId },
      })
      .then(({ data }) => {
        return data as any;
      })
  );

  const { data: chat, isLoading: isLoadingChat } = useQuery(
    ["/chats/chatId", chatId],
    () =>
      request.get(`/chats/${chatId}`).then(({ data }) => {
        return data?.chat as any;
      }),
    {
      enabled: !!chatId,
    }
  );

  const { mutateAsync: updateGroup, isLoading: isUpdatingGroup } = useMutation(
    `/chats/${chatId}`,
    (payload: any) =>
      request
        .put(`/chats/${chatId}`, payload)
        .then((response) => response?.data as any),
    {
      onSuccess: () => {
        refetchById();
        refetchChats();
        refetchChat();
      },
    }
  );

  const { data: members, isLoading: isLoadingMembers } = useQuery(
    [`/chats/${chatId}/members`, searchMembers],
    () =>
      request
        .get(`/chats/${chatId}/members`, { params: { search: searchMembers } })
        .then(({ data }) => {
          return data?.users as any;
        }),
    {
      enabled: !!chatId,
    }
  );

  return {
    chat,
    isLoadingChat,
    members,
    isLoadingMembers,
    users: chatUsers?.users || [],
    isLoadingUsers,
    refetchUsers,
    updateGroup,
    isUpdatingGroup,
    createChat,
    chats: chatData?.chats || [],
    chatMessage:chatData?.webMessage ||"No Chats available.",
    totalChats: chatData?.totalChats,
    sendMessages,
    isCreatingChat,
    getMessages,
    totalMessages: getMessages?.totalMessages || 0,
    isSendingMessage,
    isLoadingChats,
    isLoadingMessages,
    readChat,
    isReadingChat,
    refetchById,
    refetchChats,
  };
}
