import styled from "@emotion/styled";
import { VisibilityOutlined } from "@mui/icons-material";
import {
  Button,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { IconEdit, IconPlus, IconTrash } from "@tabler/icons-react";
import moment from "moment";
import { useModal } from "mui-modal-provider";
import { useCallback, useState } from "react";
import useAuth from "../../../hooks/useAuth";
import useLetters from "../../../hooks/useLetters";
import useUser from "../../../hooks/useUser";
import SubCard from "../../components/cards/SubCard";
import NoResultFound from "../../components/NoResultFound";
import { useSnackbarHelper } from "../../components/Snackbar";
import DeleteDocumentModal from "./DeleteDocumentModal";
import DeleteLetterModal from "./DeleteLetterModal";
import DigitalDocumentModal from "./DigitalDocumentModal";
import PreviewPdfModal from "./PreviewPdfModal";
import SelectLetterModal from "./SelectLetterModal";
import ViewDigitalDocument from "./ViewDigitalDocument";
import { LETTERS } from "../../../constants/MenuSelection";

function Documents({ caseData }: any) {
  const showSnackbar = useSnackbarHelper();
  const [letterLoading, setLetterLoading] = useState<any>(null);
  const { userId } = useAuth();
  const { isStaff } = useUser(userId);
  const CustomTableRow = styled(TableRow)(({ theme }) => ({
    height: "50px",
  }));
  const CustomTableCell = styled(TableCell)(({ theme }) => ({
    paddingTop: 0,
    paddingBottom: 0,
    width: 130,
    overflow: "hidden",
    textOverflow: "ellipsis",
  }));
  const { showModal } = useModal();
  const { getLetterData, isLetterLoading } = useLetters({
    caseId: caseData?._id,
  });

  const handleShowLetterPreview = useCallback(
    (Buffer: any) => {
      const modal: any = showModal(PreviewPdfModal, {
        onClose: () => modal.hide(),
        buffer: Buffer,
      });
    },
    [showModal]
  );

  const handleViewLetter = async (template: any) => {
    try {
      const response = await getLetterData({
        template: template,
        sendMail: false,
      });
      if (response) {
        setLetterLoading(null);
      }
      handleShowLetterPreview(response?.data);
    } catch (error: any) {
      const message = error?.response?.data?.message || "An error occurred";

      showSnackbar(message, "error");
    }
  };

  const handleAdd = useCallback(() => {
    const modal: any = showModal(SelectLetterModal, {
      onClose: () => {
        modal.hide();
      },
      caseId: caseData?._id,
      handleShowLetterPreview,
    });
  }, [showModal, handleShowLetterPreview, caseData]);

  const handleAddDigitalDocument = useCallback(() => {
    const modal: any = showModal(DigitalDocumentModal, {
      onClose: () => modal.hide(),
      caseId: caseData?._id,
    });
  }, [showModal, caseData]);
  const handleEdit = useCallback(
    (data: any) => {
      const modal: any = showModal(DigitalDocumentModal, {
        onClose: () => modal.hide(),
        caseId: caseData?._id,
        data: data,
      });
    },
    [showModal, caseData]
  );

  const handleViewDocument = useCallback(
    (data: any) => {
      const modal: any = showModal(ViewDigitalDocument, {
        onClose: () => modal.hide(),
        documentUrl: data?.document[data?.document.length - 1]?.url,
        mimeType: data?.type,
      });
    },
    [showModal]
  );
  const handleDeleteLetter = useCallback(
    (letterId: any) => {
      const modal: any = showModal(DeleteLetterModal, {
        onClose: () => modal.hide(),
        caseId: caseData?._id,
        letterId: letterId,
      });
    },
    [showModal, caseData]
  );
  const handleDeleteDocument = useCallback(
    (documentId: any) => {
      const modal: any = showModal(DeleteDocumentModal, {
        onClose: () => modal.hide(),
        caseId: caseData?._id,
        documentId: documentId,
      });
    },
    [showModal, caseData]
  );
  //---------------------------------------------------------------
  const caseLetters = caseData?.letters?.filter(
    (letter: any) => letter?.isDeleted !== true
  );
  const caseDocuments = caseData?.documents?.filter(
    (document: any) => document?.isDeleted !== true
  );
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SubCard
          title="Forms"
          secondary={
            <Tooltip title="Add letter">
              {isStaff ? (
                <Typography></Typography>
              ) : (
                <Button
                  sx={{
                    color: "green",
                    cursor: "pointer",
                  }}
                  onClick={handleAdd}
                >
                  <IconPlus />
                </Button>
              )}
            </Tooltip>
          }
        >
          {!!caseLetters?.length ? (
            <Stack sx={{ overflow: "auto" }}>
              <Table>
                {/* Wrap header in <TableHead> */}
                <TableHead>
                  <CustomTableRow sx={{ fontSize: { sm: "14px", md: "15px" } }}>
                    <CustomTableCell sx={{ minWidth: 100 }}>
                      <Typography variant="h5">Mode</Typography>
                    </CustomTableCell>
                    <CustomTableCell sx={{ minWidth: 100 }}>
                      <Typography variant="h5">Type</Typography>
                    </CustomTableCell>
                    <CustomTableCell sx={{ minWidth: 400 }}>
                      <Typography variant="h5">Letter</Typography>
                    </CustomTableCell>
                    <CustomTableCell sx={{ minWidth: 200 }}>
                      <Typography variant="h5">Date</Typography>
                    </CustomTableCell>
                    {/* <CustomTableCell>
                      <Typography variant="h5">Docusign Information</Typography>
                    </CustomTableCell> */}
                    <CustomTableCell>
                      <Typography variant="h5" sx={{ minWidth: 100 }}>
                        Action
                      </Typography>
                    </CustomTableCell>
                  </CustomTableRow>
                </TableHead>

                {/* Wrap table body in <TableBody> */}
                <TableBody>
                  {caseData?.letters?.map(
                    (row: any, index: any) =>
                      !row?.isDeleted && (
                        <CustomTableRow
                          key={index}
                          sx={{ fontSize: { sm: "14px", md: "15px" } }}
                        >
                          <CustomTableCell sx={{ textTransform: "capitalize" }}>
                            {row?.mode ? `${row?.mode}` : `-`}
                          </CustomTableCell>
                          <CustomTableCell>{row?.type}</CustomTableCell>
                          <CustomTableCell>
                            {LETTERS?.find(
                              (letter: any) => letter?.value === row?.title
                            )?.label || "-"}
                          </CustomTableCell>
                          <CustomTableCell>
                            {moment(row?.date).format("MM/DD/YYYY, h:mm A")}
                          </CustomTableCell>
                          {/* <CustomTableCell>
                            {row?.docuSignInfo ? `${row?.docuSignInfo}` : "-"}
                          </CustomTableCell> */}
                          <CustomTableCell>
                            <Tooltip title={"View Letter"}>
                              <span>
                                <IconButton
                                  onClick={() => {
                                    handleViewLetter(row?.title);
                                    setLetterLoading(row?._id);
                                  }}
                                  disabled={letterLoading === row?._id}
                                >
                                  <VisibilityOutlined />
                                </IconButton>
                              </span>
                            </Tooltip>
                            {!isStaff && (
                              <Tooltip title={"Delete Letter"}>
                                <IconButton
                                  onClick={() => handleDeleteLetter(row?._id)}
                                >
                                  <IconTrash style={{ color: "#D83630" }} />
                                </IconButton>
                              </Tooltip>
                            )}
                          </CustomTableCell>
                        </CustomTableRow>
                      )
                  )}
                </TableBody>
              </Table>
            </Stack>
          ) : (
            <NoResultFound>No Form Found</NoResultFound>
          )}
        </SubCard>
      </Grid>

      <Grid item xs={12}>
        <SubCard
          title="Documents"
          secondary={
            <Tooltip title="Add documents">
              {isStaff ? (
                <Typography></Typography>
              ) : (
                <Button
                  sx={{
                    color: "green",
                    cursor: "pointer",
                  }}
                  onClick={handleAddDigitalDocument}
                >
                  <IconPlus />
                </Button>
              )}
            </Tooltip>
          }
        >
          {!!caseDocuments?.length ? (
            <Stack sx={{ overflow: "auto" }}>
              <Table sx={{ tableLayout: "fixed" }}>
                {/* Again, wrap the header in <TableHead> */}
                <TableHead>
                  <CustomTableRow sx={{ fontSize: { sm: "10px", md: "15px" } }}>
                    <CustomTableCell>
                      <Typography variant="h5">Created At</Typography>
                    </CustomTableCell>
                    <CustomTableCell>
                      <Typography variant="h5">Updated At</Typography>
                    </CustomTableCell>
                    {/* <CustomTableCell>
                      <Typography variant="h5">Signed</Typography>
                    </CustomTableCell> */}
                    <CustomTableCell>
                      <Typography variant="h5">Title</Typography>
                    </CustomTableCell>
                    <CustomTableCell>
                      <Typography variant="h5">Uploaded By</Typography>
                    </CustomTableCell>
                    <CustomTableCell>
                      <Typography variant="h5">Action</Typography>
                    </CustomTableCell>
                    {/* <CustomTableCell sx={{ textAlign: "right" }}>
                    <Button
                      sx={{
                        color: "green",
                        cursor: "pointer",
                      }}
                      onClick={handleAddDigitalDocument}
                    >
                      <IconPlus />
                    </Button>
                  </CustomTableCell> */}
                  </CustomTableRow>
                </TableHead>

                {/* Wrap the table rows in <TableBody> */}
                <TableBody>
                  {caseData?.documents?.map(
                    (row: any, index: any) =>
                      !row?.isDeleted && (
                        <CustomTableRow
                          key={index}
                          sx={{ fontSize: { sm: "10px", md: "15px" } }}
                        >
                          <CustomTableCell>
                            {moment(row?.createdAt).format("MM/DD/YYYY")}
                          </CustomTableCell>
                          <CustomTableCell>
                            {moment(row?.updatedAt).format("MM/DD/YYYY")}
                          </CustomTableCell>
                          {/* <CustomTableCell>
                            {row?.isSigned ? "Signed" : "Not Signed"}
                          </CustomTableCell> */}
                          <CustomTableCell>{row?.title}</CustomTableCell>
                          <CustomTableCell>
                            {row?.uploadedBy?.userName}
                          </CustomTableCell>
                          <CustomTableCell>
                            {!isStaff && (
                              <IconButton>
                                <Tooltip title={"Edit Document"}>
                                  <IconEdit
                                    color="grey"
                                    cursor={"pointer"}
                                    onClick={() => handleEdit(row)}
                                  />
                                </Tooltip>
                              </IconButton>
                            )}
                            <Tooltip title={"View Document"}>
                              <IconButton
                                onClick={() => handleViewDocument(row)}
                              >
                                <VisibilityOutlined />
                              </IconButton>
                            </Tooltip>
                            {!isStaff && (
                              <Tooltip title={"Delete Document"}>
                                <IconButton>
                                  <IconTrash
                                    style={{ color: "#D83630" }}
                                    onClick={() =>
                                      handleDeleteDocument(row?._id)
                                    }
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                          </CustomTableCell>
                        </CustomTableRow>
                      )
                  )}
                </TableBody>
              </Table>
            </Stack>
          ) : (
            <NoResultFound>No Document Found</NoResultFound>
          )}
        </SubCard>
      </Grid>
    </Grid>
  );
}

export default Documents;
