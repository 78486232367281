import { yupResolver } from "@hookform/resolvers/yup";
import { Button, DialogProps, Grid } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import useCases from "../../../hooks/useCases";
import useContacts from "../../../hooks/useContacts";
import BasicModal from "../../components/modal/BasicModal";
import { useSnackbarHelper } from "../../components/Snackbar";
import AdditionalContact from "../contacts/AdditionalContact";
interface Props extends DialogProps {
  onClose: () => void;

  data: any;
  type?: string;
  contactId: string;
  caseId: any;
}
function AddSupportContactModal({
  onClose,
  data,
  type,
  contactId,
  caseId,
  ...props
}: Props) {
  const showSnackbar = useSnackbarHelper();
  const { AddSupport, updateContact, updateLoading } = useContacts();
  const { refetchCase } = useCases(caseId);

  const initialMainSupportValues = useMemo(
    () => ({
      additional_Support_firstName: data?.firstName || "",
      additional_Support_lastName: data?.lastName || "",
      additional_Support_address: data?.address || "",
      additional_Support_country: data?.country || "",
      additional_Support_state: data?.state || "",
      additional_Support_city: data?.city || "",
      additional_Support_zip: data?.zip || "",
      additional_Support_mi: data?.mi || "",
      additional_Support_suffix: data?.suffix || "",
      additional_Support_nickName: data?.nickName || "",
      additional_Support_email: data?.email || [{ email: "", emailType: "" }],
      additional_Support_phone: data?.phone || [{ phone: "", phoneType: "" }],
    }),
    [data]
  );
  const initialAdditionalSupportValues = useMemo(
    () => ({
      additional_Support_firstName:
        data?.additionalSupportContact?.firstName || "",
      additional_Support_lastName:
        data?.additionalSupportContact?.lastName || "",
      additional_Support_address: data?.additionalSupportContact?.address || "",
      additional_Support_country: data?.additionalSupportContact?.country || "",
      additional_Support_state: data?.additionalSupportContact?.state || "",
      additional_Support_city: data?.additionalSupportContact?.city || "",
      additional_Support_zip: data?.additionalSupportContact?.zip || "",
      additional_Support_mi: data?.additionalSupportContact?.mi || "",
      additional_Support_suffix: data?.additionalSupportContact?.suffix || "",
      additional_Support_nickName:
        data?.additionalSupportContact?.nickName || "",
      additional_Support_email: data?.additionalSupportContact?.email || [
        { email: "", emailType: "" },
      ],
      additional_Support_phone: data?.additionalSupportContact?.phone || [
        { phone: "", phoneType: "" },
      ],
    }),
    [data]
  );

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const zipRegex = /^[a-zA-Z0-9 _-]+$/;
  const phoneRegExp = /^\+\d{1,3} \(\d{3}\) \d{3}-\d{4}$/;

  const additional_Support_EmailSchema = Yup.object({
    email: Yup.string()
      .email("Invalid Email")
      .required("Required")
      .transform((value, originalValue) => originalValue.toLowerCase())
      .matches(emailRegex, "Must be a valid email"),
    emailType: Yup.string().required("Required"),
  });

  const additional_Support_PhoneSchema = Yup.object({
    phone: Yup.string()
      .required("Required")
      .matches(phoneRegExp, "Mobile number is not valid"),
    phoneType: Yup.string().required("Required"),
  });

  const validationSchema = Yup.object().shape({
    additional_Support_firstName: Yup.string()
      .matches(
        /^(?:[a-zA-Z]+(?:\s[a-zA-Z]+)*)?$/,
        "First name should not contain symbols/numbers or spaces"
      )
      .required("Required"),
    additional_Support_lastName: Yup.string()
      .matches(
        /^(?:[a-zA-Z]+(?:\s[a-zA-Z]+)*)?$/,
        "Last name should not contain symbols/numbers or spaces"
      )
      .required("Required"),
    additional_Support_email: Yup.array()
      .of(additional_Support_EmailSchema)
      .required("Required")
      .min(1, "At least one email is required"),

    additional_Support_phone: Yup.array()
      .of(additional_Support_PhoneSchema)
      .required("Required")
      .min(1, "At least one phone no is required"),

    additional_Support_address: Yup.string().required("Required"),
    additional_Support_city: Yup.string().required("Required"),
    additional_Support_zip: Yup.string().nullable().matches(zipRegex, {
      message: "Special characters not allowed",
      excludeEmptyString: true, // Only apply the regex match if the field is not empty
    }),
    additional_Support_state: Yup.string(),
    additional_Support_country: Yup.string().required("Required"),
  });
  const formContext = useForm({
    defaultValues:
      type === "main"
        ? initialMainSupportValues
        : initialAdditionalSupportValues,
    resolver: yupResolver<any>(validationSchema),
  });
  const getPayload = (values?: any) => {
    const { _id, ...d } = data;
    const additional_Support_phone = values?.additional_Support_phone?.map(
      (d: any) => ({
        phone: d?.phone,
        phoneType: d?.phoneType,
      })
    );
    const additional_Support_email = values?.additional_Support_email?.map(
      (d: any) => ({
        email: d?.email,
        emailType: d?.emailType,
      })
    );
    const phone = data?.phone?.map((d: any) => ({
      phone: d?.phone,
      phoneType: d?.phoneType,
    }));
    const email = data?.email?.map((d: any) => ({
      email: d?.email,
      emailType: d?.emailType,
    }));
    return type === "main"
      ? {
          firstName: values.additional_Support_firstName,
          lastName: values.additional_Support_lastName,
          address: values.additional_Support_address,
          country: values.additional_Support_country,
          state: values.additional_Support_state || "",
          city: values.additional_Support_city,
          zip: values.additional_Support_zip || "",
          mi: values.additional_Support_mi || "",
          suffix: values.additional_Support_suffix || "",
          nickName: values.additional_Support_nickName || "",
          email: additional_Support_email,
          phone: additional_Support_phone,
          inmate: data?.inmate?._id || undefined,
          isDeleted: data?.isDeleted,
          note: data?.note || "",
          sid: data?.sid || "",
          tdcj: data?.tdcj || "",
          unit: data?.unit || "",
          unit_location: data?.unit_location || "",
          inmate_dob: data?.inmate_dob || null,
          createdBy: data?.createdBy,
          additionalSupportContact: data.additionalSupportContact,
        }
      : {
          firstName: data?.firstName,
          lastName: data?.lastName,
          address: data?.address,
          country: data?.country,
          state: data?.state,
          city: data?.city,
          zip: data?.zip || "",
          mi: data?.mi || "",
          suffix: data?.suffix || "",
          nickName: data?.nickName || "",
          email: email,
          phone: phone,
          inmate: data?.inmate?._id || undefined,
          isDeleted: data?.isDeleted,
          note: data?.note || "",
          sid: data?.sid || "",
          tdcj: data?.tdcj || "",
          unit: data?.unit || "",
          inmate_dob: data?.inmate_dob || null,
          createdBy: data?.createdBy,
          unit_location: data?.unit_location || "",
          additionalSupportContact: {
            firstName: values.additional_Support_firstName,
            lastName: values.additional_Support_lastName,
            address: values.additional_Support_address,
            country: values.additional_Support_country,
            state: values.additional_Support_state || "",
            city: values.additional_Support_city,
            zip: values.additional_Support_zip,
            mi: values.additional_Support_mi,
            suffix: values.additional_Support_suffix,
            nickName: values.additional_Support_nickName,
            email: additional_Support_email,
            phone: additional_Support_phone,
          },
        };
  };

  const handleSubmit = async (values?: any) => {
    const isUpdatingContact = !!data;
    const payload = getPayload(values);
    try {
      if (isUpdatingContact) {
        await updateContact({ id: contactId, payload: payload });
      } else {
        await AddSupport({ id: contactId, payload: payload });
      }
      const message =
        type === "main"
          ? data?.firstName !== ""
            ? "Support contact updated Successfully"
            : "Support contact added Successfully"
          : data?.additionalSupportContact?.firstName !== ""
            ? "Additional contact updated Successfully"
            : "Additional contact added Successfully";

      await refetchCase();
      showSnackbar(message, "success");
      onClose();
    } catch (error: any) {
      const message = error?.response?.data?.message || "An error occurred";

      showSnackbar(message, "error");
    }
  };
  const { watch, setValue } = formContext;
  const selectedAdditionalSupportCountry = watch("additional_Support_country");
  let Country = require("country-state-city").Country;
  let State = require("country-state-city").State;

  useEffect(() => {
    if (type === "main") {
      if (selectedAdditionalSupportCountry !== data?.country) {
        setValue("additional_Support_state", "");
      }
    } else {
      if (
        selectedAdditionalSupportCountry !==
        data?.additionalSupportContact?.country
      ) {
        setValue("additional_Support_state", "");
      }
    }
  }, [selectedAdditionalSupportCountry, setValue, data, type]);

  //get the countries
  const countryList = Country.getAllCountries().map((item: any) => ({
    label: item.name,
    value: item.name,
    isoCode: item.isoCode,
  }));

  //get state list for support according to country
  const additionalSupportStatesList = useMemo(() => {
    const countryISOCode = countryList?.find(
      (country: any) => country.value === selectedAdditionalSupportCountry
    )?.isoCode;

    if (countryISOCode) {
      return State.getStatesOfCountry(countryISOCode).map((item: any) => ({
        label: `${item.isoCode} (${item.name})`,
        value: item.isoCode,
        isoCode: item.isoCode,
      }));
    }

    return [];
    //eslint-disable-next-line
  }, [selectedAdditionalSupportCountry, countryList]);
  return (
    <BasicModal
      formContext={formContext}
      onClose={onClose}
      onSubmit={handleSubmit}
      title={
        type === "main"
          ? data?.firstName !== ""
            ? "Update Support Contact"
            : "Add Support Contact"
          : data?.additionalSupportContact?.firstName !== ""
            ? "Update Additional Contact"
            : "Add Additional Contact"
      }
      content={
        <Grid container spacing={2}>
          <AdditionalContact
            statesList={additionalSupportStatesList}
            countryList={countryList}
          />
        </Grid>
      }
      actions={
        <>
          <Button
            color="error"
            variant="outlined"
            onClick={onClose}
            sx={{ textTransform: "none" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            sx={{ textTransform: "capitalize" }}
            type="submit"
            disabled={updateLoading}
          >
            {data?.additionalSupportContact?.firstName !== "" || type === "main"
              ? "Update"
              : "Add"}
          </Button>
        </>
      }
      {...props}
    />
  );
}

export default AddSupportContactModal;
