import { Stack, Typography } from "@mui/material";
import { useModal } from "mui-modal-provider";
import { useCallback, useState } from "react";
import { useSockets } from "../../../hooks/useSockets";
import BasicButton from "../../components/buttons/BasicButton";
import MainCard from "../../components/cards/MainCard";
import CreateChatModal from "./CreateChatModal";
import Messages from "./Messages";

export default function Sms() {
  const [selectedContact, setSelectedContact] = useState<any | null>(null);
  const socket = useSockets();

  const { showModal } = useModal();

  const handleCreateChat = useCallback(() => {
    const modal: any = showModal(CreateChatModal, {
      onClose: () => {
        modal.hide();
      },
      setSelectedContact,
    });

    //eslint-disable-next-line
  }, []);

  return (
    <MainCard
      title={
        <Typography
          variant={window?.innerWidth < 1420 ? "h2" : "h1"}
          sx={{ mx: 2 }}
        >
          Messages
        </Typography>
      }
      actions={
        <Stack
          direction={"row"}
          alignItems={"center"}
          mt={window.innerWidth < 1420 ? 1 : 0.2}
        >
          <BasicButton
            label={"New Chat"}
            variant="contained"
            color="success"
            size={window.innerWidth < 1420 ? "small" : "medium"}
            onClick={handleCreateChat}
          />
        </Stack>
      }
      content={
        <Messages
          socket={socket}
          selectedContact={selectedContact}
          setSelectedContact={setSelectedContact}
        />
      }
    />
  );
}
