import styled from "@emotion/styled";
import {
  Button,
  IconButton,
  SelectChangeEvent,
  Stack,
  Table,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { IconEdit, IconPlus, IconTrash } from "@tabler/icons-react";
import dayjs from "dayjs";
import { debounce } from "lodash";
import { useModal } from "mui-modal-provider";
import { useCallback, useRef, useState } from "react";
import useCaseNotes from "../../../hooks/useCaseNote";
import NoResultFound from "../../components/NoResultFound";
import SubCard from "../../components/cards/SubCard";
import SearchField from "../../components/inputs/SearchField";
import AddCaseNoteModal from "./AddCaseNoteModal";
import DeleteCaseNoteModal from "./DeleteCaseNoteModal";

const CustomTableRow = styled(TableRow)(({ theme }) => ({
  height: "50px !important",
  overflow: "hidden",
}));
const CustomTableCell = styled(TableCell)(({ theme }) => ({
  paddingTop: 0,
  paddingBottom: 0,
}));
function Notes({ caseId }: any) {
  const [search, setSearch] = useState("");
  const { caseNotes, allCaseNotes, refetchCaseNotes } = useCaseNotes({
    search,
    caseId,
  });
  // Add debounce to hold render events
  const debouncedSetSearch = useRef(
    debounce((value) => setSearch(value), 400)
  ).current;

  const handleSearchChange = useCallback(
    (event: any) => {
      debouncedSetSearch(event.target.value);
    },
    [debouncedSetSearch]
  );
  const names = [
    "Oliver Hansen",
    "Van Henry",
    "April Tucker",
    "Ralph Hubbard",
    "Omar Alexander",
    "Carlos Abbott",
    "Miriam Wagner",
    "Bradley Wilkerson",
    "Virginia Andrews",
    "Kelly Snyder",
  ];
  const [personName, setPersonName] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { showModal } = useModal();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorEl === null) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  // const handleAdd=()
  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleAdd = useCallback(
    (refetchCaseNotes: any) => {
      const modal: any = showModal(AddCaseNoteModal, {
        onClose: () => modal.hide(),
        refetchCaseNotes,
      });
    },
    [showModal]
  );
  const handleEdit = useCallback(
    (data: any, refetchCaseNotes: any) => {
      const modal: any = showModal(AddCaseNoteModal, {
        onClose: () => modal.hide(),
        data,
        refetchCaseNotes,
      });
    },
    [showModal]
  );
  const handleDelete = useCallback(
    (id: any, refetchCaseNotes: any) => {
      const modal: any = showModal(DeleteCaseNoteModal, {
        onClose: () => modal.hide(),
        id,
        refetchCaseNotes,
      });
    },
    [showModal]
  );

  return (
    <Stack
      sx={{
        // textAlign: "left",
        p: 2,
        // fontSize: "18px",
        color: "#3C3C3C",
        gap: 2,
      }}
    >
      <SubCard
        title="Notes"
        secondary={
          <Stack direction={"row"}>
            <SearchField
              onChange={handleSearchChange}
              size="small"
              placeholder="Search note"
            />
            <Tooltip title={"Add Case Note"}>
              <Button
                sx={{
                  color: "green",
                  cursor: "pointer",
                }}
                onClick={() => handleAdd(refetchCaseNotes)}
              >
                <IconPlus />
              </Button>
            </Tooltip>
          </Stack>
        }
      >
        <Stack overflow={"auto"}>
          {caseNotes?.length > 0 ? (
            <Table sx={{ tableLayout: "fixed" }}>
              <CustomTableRow sx={{ fontSize: { sm: "14px", md: "15px" } }}>
                <CustomTableCell sx={{ width: "200px" }}>
                  <Typography variant="h5">Created Date/Time</Typography>
                </CustomTableCell>
                <CustomTableCell sx={{ width: "150px" }}>
                  <Typography variant="h5">Created By</Typography>
                </CustomTableCell>
                <CustomTableCell sx={{ width: "200px" }}>
                  <Typography variant="h5">Note</Typography>
                </CustomTableCell>
                <CustomTableCell sx={{ width: "100px", textAlign: "center" }}>
                  <Typography variant="h5"> Action</Typography>
                </CustomTableCell>
                <CustomTableCell
                  sx={{ width: "100px", textAlign: "right" }}
                ></CustomTableCell>
              </CustomTableRow>
              {caseNotes?.map((row: any, index: any) => (
                <CustomTableRow
                  key={index}
                  sx={{ fontSize: { sm: "14px", md: "15px" } }}
                >
                  <CustomTableCell
                    sx={{
                      width: "200px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {dayjs(row?.updatedAt)
                      .subtract(5, "hour") // For change UTC to a timezone that is 5 hours behind UTC, such as EDT or EST.
                      .subtract(13, "minute") // Also use for change the timezone
                      .format("MM/DD/YYYY, h:mm a")}
                  </CustomTableCell>
                  <CustomTableCell
                    sx={{
                      width: "150px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {row?.createdBy?.firstName}
                  </CustomTableCell>
                  <CustomTableCell sx={{ width: "200px", overflow: "hidden" }}>
                    {row?.note.length > 50
                      ? row?.note.substring(0, 50) + "..."
                      : row?.note}
                  </CustomTableCell>
                  <CustomTableCell
                    sx={{
                      width: "150px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textAlign: "center",
                    }}
                  >
                    <IconButton>
                      <Tooltip title={"Edit Case Note"}>
                        <IconEdit
                          color="grey"
                          cursor={"pointer"}
                          onClick={() => handleEdit(row, refetchCaseNotes)}
                        />
                      </Tooltip>
                    </IconButton>
                    <IconButton>
                      <Tooltip title={"Delete Case Note"}>
                        <IconTrash
                          style={{ color: "#D83630", cursor: "pointer" }}
                          onClick={() =>
                            handleDelete(row?._id, refetchCaseNotes)
                          }
                        />
                      </Tooltip>
                    </IconButton>
                  </CustomTableCell>
                  <CustomTableCell sx={{ width: "100px" }}></CustomTableCell>
                </CustomTableRow>
              ))}
            </Table>
          ) : (
            <NoResultFound>No Case Note found</NoResultFound>
          )}
        </Stack>
      </SubCard>
    </Stack>
  );
}

export default Notes;
