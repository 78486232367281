import { useCallback, useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import {
  GridActionsCellItem,
  GridColDef,
  GridPaginationModel,
  GridSortModel,
} from "@mui/x-data-grid";
import { useModal } from "mui-modal-provider";
import moment from "moment";

//components
import useTimeEntry from "../../../hooks/useTimeEntry";
import BasicButton from "../../components/buttons/BasicButton";
import AddNewTimeEntryModal from "./AddNewTimeEntryModal";
import DeleteTimeEntryModal from "./DeleteTimeEntryModal";
import DataGridTable from "../../components/DataGridTable";
import MainCard from "../../components/cards/MainCard";
import { Type } from "../../../constants/MenuSelection";

export default function TimeEntry() {
  // const [search, setSearch] = useState("");
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "startTime", sort: "desc" },
  ]);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 12,
  });

  const { allTimeEntry, totalTimeEntry, isAllTimeEntryLoading } = useTimeEntry(
    {},
    "",
    paginationModel,
    sortModel
  );

  const handleSortModelChange = (newModel: any[]) => {
    setSortModel(newModel);
  };
  const { showModal } = useModal();
  const handleAddNewTimeEntry = useCallback(
    (value: any, start: any) => {
      // console.log("startTime", value, start);
      const modal: any = showModal(AddNewTimeEntryModal, {
        onClose: () => {
          modal.hide();
          handleReset();
        },
        end: value,
        start: start,
        handleReset: handleReset,
      });
    },
    [showModal]
  );
  const handleUpdateTimeEntry = useCallback(
    (data: any) => {
      const modal: any = showModal(AddNewTimeEntryModal, {
        onClose: () => modal.hide(),
        data: data,
        handleReset: handleReset,
      });
    },
    [showModal]
  );
  const handleDelete = useCallback(
    (data: any) => {
      const modal: any = showModal(DeleteTimeEntryModal, {
        onClose: () => modal.hide(),
        id: data?._id,
      });
    },
    [showModal]
  );
  const handleReset = () => {
    setTime(0);
    setStartTiming("hh:mm:ss");

    setClockStarted(false);
    setIsActive(false);
    localStorage.removeItem("startTime");
  };

  const [isActive, setIsActive] = useState(false);
  const [clockStarted, setClockStarted] = useState(false);
  const [time, setTime] = useState(0);
  const [start, setStart] = useState();
  const [startTiming, setStartTiming] = useState<any>("hh:mm:ss");
  const [endTime, setEndTime] = useState<any>();

  const columns: GridColDef[] = [
    {
      field: "startTime",
      headerName: "Start Time",
      headerClassName: "MuiDataGrid-cell",
      minWidth: 300,
      renderCell: (params: any) =>
        moment(params?.row?.startTime).format("MM/DD/YYYY, hh:mm a") || "-",
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    {
      field: "endTime",
      headerName: "End Time",
      headerClassName: "MuiDataGrid-cell",
      minWidth: 220,
      renderCell: (params: any) =>
        moment(params?.row?.endTime).format("MM/DD/YYYY, hh:mm a") || "-",
      renderHeader: (params: any) => params?.colDef?.headerName,
    },

    {
      field: "billedMinutes",
      headerName: "Billed Minutes",
      minWidth: 220,
      renderCell: (params: any) => params?.row?.billedMinutes || "-",
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    // {
    //   field: "staffName",
    //   headerName: "Staff Name",
    //   minWidth: 220,
    //   renderCell: (params: any) =>
    //     params?.row?.staffName?.contact?.inmate?.name
    //       ? params?.row?.staffName?.contact?.inmate?.name
    //       : "-",
    //   renderHeader: (params: any) => params?.colDef?.headerName,
    // },
    {
      field: `type`,
      headerName: "Type",
      minWidth: 250,
      renderCell: (params: any) =>
        Type?.find((t: any) => t?.value === params?.row?.type)?.label || "-",
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    {
      field: "client",
      headerName: "Client",
      minWidth: 250,
      flex: 1,
      renderCell: (params: any) => params?.row?.client?.firstName || "-",
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    {
      field: "note",
      headerName: "Note",
      minWidth: 220,
      renderCell: (params: any) =>
        params?.row?.note ? params?.row?.note : "-",
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      headerClassName: "MuiDataGrid-cell",
      minWidth: 220,
      cellClassName: "actions",
      getActions: (params: any) => {
        return [
          <GridActionsCellItem
            icon={<IconEdit />}
            label="view"
            onClick={() => {
              handleUpdateTimeEntry(params?.row);
            }}
          />,

          <GridActionsCellItem
            color="error"
            icon={<IconTrash />}
            label="view"
            onClick={() => {
              handleDelete(params?.row);
            }}
          />,
        ];
      },
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
  ];

  const handleClockStarted = () => {
    const date: any = new Date();
    localStorage.setItem("startTime", date);
    setClockStarted(!clockStarted);
    setIsActive(!isActive);
  };

  const startTime: any = localStorage.getItem("startTime");

  useEffect(() => {
    let currentTime;
    const startTime: any = localStorage.getItem("startTime");
    if (startTime) {
      currentTime = moment(startTime).format("hh:mm:ss A");
      // console.log(currentTime);
      setStartTiming(currentTime);
      setStart(startTime);
      const today: any = moment(new Date());

      const calculateMinutes = today.diff(startTime, "seconds");
      // const calculateSeconds = today.diff(startTime, "seconds");

      // console.log("calculateTimeSpent", calculateMinutes);
      setTime(calculateMinutes);
      setIsActive(true);
      setClockStarted(true);
    }
  }, [startTime]);

  useEffect(() => {
    let interval: any = null;

    if (isActive) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isActive]);
  const formatTime = (time: number) => {
    const hours = Math.floor(time / 3600)
      .toString()
      .padStart(2, "0");
    const minutes = Math.floor((time % 3600) / 60)
      .toString()
      .padStart(2, "0");
    const seconds = (time % 60).toString().padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };
  return (
    <Box>
      <MainCard
        title={
          <Typography variant={window?.innerWidth < 1420 ? "h2" : "h1"}>
            Time Entry
          </Typography>
        }
        actions={
          <Stack direction={"row"} alignItems={"center"} spacing={2}>
            <Stack direction={"row"} spacing={2}>
              <Stack direction={"row"} spacing={1}>
                <Typography variant="h4">Clock Started: </Typography>
                <Typography
                  variant="h4"
                  sx={{ fontWeight: "normal", width: 100 }}
                >
                  {startTiming}
                </Typography>
              </Stack>

              <Stack direction={"row"} spacing={1}>
                <Typography variant="h4">Elapsed Time: </Typography>
                <Typography
                  variant="h4"
                  sx={{ fontWeight: "normal", width: 75 }}
                >
                  {/* {("0" + Math.floor(time / 3600000)).slice(-2)}:
                  {("0" + Math.floor((time % 3600000) / 60000)).slice(-2)}:
                  {("0" + Math.floor((time % 60000) / 1000)).slice(-2)} */}
                  {formatTime(time)}
                </Typography>
              </Stack>
            </Stack>

            <Stack direction={"row"} spacing={2}>
              {clockStarted ? (
                <BasicButton
                  color={"error"}
                  variant="contained"
                  label={"Stop"}
                  size={window.innerWidth < 1420 ? "small" : "medium"}
                  onClick={() => {
                    const today: any = moment(new Date());

                    setEndTime(today);
                    // const duration = moment.duration(today.diff(startTime));
                    // const minutes = Math.floor(duration.asMilliseconds());
                    // setTime(minutes);

                    setIsActive(false);
                    localStorage.removeItem("startTime");
                    handleAddNewTimeEntry(endTime, start);
                  }}
                  endIcon={<StopIcon />}
                />
              ) : (
                <BasicButton
                  label={"Start"}
                  variant="contained"
                  size={window.innerWidth < 1420 ? "small" : "medium"}
                  onClick={handleClockStarted}
                  endIcon={<PlayArrowIcon />}
                />
              )}
              <BasicButton
                label={"Reset"}
                variant="outlined"
                onClick={() => {
                  handleReset();
                }}
              />
            </Stack>
          </Stack>
        }
        content={
          <>
            <DataGridTable
              onPaginationModelChange={setPaginationModel}
              paginationModel={paginationModel}
              totalRows={totalTimeEntry}
              column={columns}
              rows={allTimeEntry || []}
              sortModel={sortModel}
              onSortModelChange={handleSortModelChange}
              isLoading={isAllTimeEntryLoading}
            />
          </>
        }
      />
    </Box>
  );
}
