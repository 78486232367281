import { useMutation, useQuery, useQueryClient } from "react-query";
import useAuth from "./useAuth";
import { GridPaginationModel, GridSortModel } from "@mui/x-data-grid";
import { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";

/**
 * Custom hook to Fetch contacts with the specified parameters
 * @hook
 * @author Sanjay
 */

export default function useContacts(
  search?: string,
  paginationModel?: GridPaginationModel,
  sortModel?: GridSortModel,
  getAllData = false
) {
  const { request } = useAuth();

  //this is used because multiple conditions and populates added in backend
  const queryClient = useQueryClient();
  const handleRefetchUsers = () => {
    queryClient.invalidateQueries(["/contacts"]);
  };

  // Fetch contacts with the specified parameters
  const {
    data,
    refetch: refetchContacts,
    isLoading: isContactsLoading,
    isRefetching,
  } = useQuery(
    ["/contacts", search, paginationModel, sortModel, getAllData],
    () =>
      request
        .get("/contacts", {
          params: { search, paginationModel, sortModel, getAllData },
        })
        .then((response) => response?.data)
  );

  // Fetch all contacts
  // const { data: allContacts, isLoading: isAllContactLoading } = useQuery(
  //   ["/allcontacts"],
  //   () =>
  //     request
  //       .get("/contacts", { params: { isInmate: true } })
  //       .then((response) => response?.data?.data)
  // );

  const { mutateAsync: AddSupport, isLoading } = useMutation(
    "/contacts",
    (payload: any) =>
      request
        .post("/contacts", payload)
        .then((response) => response.data as any),
    {
      onSuccess: () => handleRefetchUsers(),
    }
  );

  const { mutateAsync: updateContact, isLoading: updateLoading } = useMutation(
    `/contacts/:id`,
    ({ id, payload }: { id: string; payload: any }) =>
      request
        .put(`/contacts/${id}`, payload)
        .then((response) => response.data as any),
    { onSuccess: () => handleRefetchUsers() }
  );

  const { mutateAsync: deleteContact, isLoading: deleteLoading } = useMutation(
    "/contacts/delete",
    (id: string) => request.delete(`/contacts/${id}`),
    {
      onSuccess: () => handleRefetchUsers(),
    }
  );

  const inmates = data?.data?.filter(
    (contact: any) => !!contact?.inmate?._id && !contact?.caseId
  );

  const combinedContactList = useMemo(() => {
    // Ensure contacts is an array before proceeding
    if (!Array.isArray(data?.data)) return [];

    const supportContacts = data?.data?.flatMap((item: any) => {
      if (item?.case?.caseId && Array.isArray(item?.phone)) {
        return item.phone.map((p: any) => ({
          id: uuidv4(),
          name: `${item?.firstName || ""} ${item?.lastName || ""}`.trim(),
          phone: p.phone,
          isAdditionalSupport: false,
          caseUniqueID: item?.case?.caseId,
          caseId: item?.caseId,
          contactId: item?._id,
        }));
      }
      return [];
    });

    const additionalContacts = data?.data?.flatMap((item: any) => {
      const additionalContactName =
        `${item?.additionalSupportContact?.firstName || ""} ${item?.additionalSupportContact?.lastName || ""}`.trim();

      if (
        item?.case?.caseId &&
        Array.isArray(item?.additionalSupportContact?.phone)
      ) {
        return item?.additionalSupportContact?.phone.map((p: any) => ({
          id: uuidv4(),
          name: additionalContactName,
          phone: p.phone,
          isAdditionalSupport: true,
          caseUniqueID: item?.case?.caseId,
          caseId: item?.caseId,
          contactId: item?._id,
        }));
      }
      return [];
    });

    return supportContacts
      .concat(additionalContacts)
      .filter((contact: any) => !!contact?.phone);
  }, [data]);

  //returns Observable
  return {
    inmates,
    contacts: data?.data,
    refetchContacts,
    isRefetching,
    AddSupport,
    updateContact,
    deleteContact,
    totalUsers: data?.totalContacts || 0,
    updateLoading,
    isLoading: isLoading || isContactsLoading || updateLoading || deleteLoading,
    isContactsLoading,
    combinedContactList,
  };
}
