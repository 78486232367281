import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { IconChevronsLeft } from "@tabler/icons-react";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import {
  CaseInfoFileStatus,
  RefferalTypes,
  RepType,
} from "../../../constants/MenuSelection";
import useAuth from "../../../hooks/useAuth";
import useCases from "../../../hooks/useCases";
import usePayment from "../../../hooks/usePayment";
import useUser from "../../../hooks/useUser";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import { useSnackbarHelper } from "../../components/Snackbar";
import BasicButton from "../../components/buttons/BasicButton";
import MainCard from "../../components/cards/MainCard";
import BasicAutocomplete from "../../components/inputs/BasicAutocomplete";
import BasicDatePicker from "../../components/inputs/BasicDatePicker";
import Checkbox from "../../components/inputs/CheckBox";
import MuiTextField from "../../components/inputs/MuiTextField";
import NumberField from "../../components/inputs/NumberField";

export type ClientFormValues = any;

const CaseInfo = ({ caseData, hideRoster, isCaseClosed, isStaff }: any) => {
  const { lawyers, staff } = useUser();
  const { updateCase, isCaseUpdating, boardMembers } = useCases(caseData?._id);
  const showSnackbar = useSnackbarHelper();

  const innerWidth = window?.innerWidth;

  const { paymentScheduling } = usePayment(caseData?._id);

  const isPaymentCompleted = useMemo(() => {
    return paymentScheduling?.payment?.every(
      (payment: any) => payment.isPaymentCompleted === true
    );
  }, [paymentScheduling]);

  const initialValues = useMemo(
    () => ({
      caseStatus: caseData?.caseStatus,
      mailType: caseData?.mailType || "",
      // phoneType: caseData?.phoneType || "",
      repType: caseData?.repType || "",
      feeQuoted: caseData?.feeQuoted || "",
      lawyer: caseData?.lawyer?._id || null,
      secondaryLawyer: caseData?.secondaryLawyer || null,
      staff: caseData?.staff || null,
      initialContactDate: caseData?.initialContactDate || null,
      followUpDate: caseData?.followUpDate?.[0] || null,
      progress: caseData?.progress || "",
      retentionDate: caseData?.retentionDate || null,
      leadVoter: caseData?.leadVoter || "",
      isExtraordinaryVoteCase: caseData?.isExtraordinaryVoteCase || false,
      isExistingClient: caseData?.isExistingClient || false,
      // isUnknown: caseData?.isUnknown || false,
      paroleHiringDateTime: caseData?.paroleHiringDateTime || null,
      referralType: caseData?.referralType || "",
      referralDetails: caseData?.referralDetails || "",
      isClientHiredAnotherAttorney:
        caseData?.isClientHiredAnotherAttorney || false,
      hiredAnotherAttorneyReason: caseData?.hiredAnotherAttorneyReason || "",
      // isHouseBill: caseData?.isHouseBill || false,
    }),
    [caseData]
  );

  const members = boardMembers?.map((option: any) => {
    return { label: option?.name, value: option?.name };
  });

  const validationSchema: any = Yup.object({
    // caseId: Yup.string().required(),
    caseStatus: Yup.string().required("Required"),
    repType: Yup.string().required("Required"),
    mailType: Yup.string().required("Required"),
    // phoneType: Yup.string().required("Required"),
    feeQuoted: Yup.string(),
    // lawyer: Yup.string().when("caseStatus", (caseStatus: any, schema: any) => {
    //   return caseStatus?.[0] !== "active"
    //     ? schema.nullable()
    //     : schema.required("Required");
    // }),
    lawyer: Yup.string().required("Required"),
    secondaryLawyer: Yup.string().nullable(),
    staff: Yup.string().required("Required"),
    initialContactDate: Yup.date().nullable(),
    followUpDate: Yup.date().nullable(),
    progress: Yup.string().nullable(),
    retentionDate: Yup.date().nullable(),
    leadVoter: Yup.string().notRequired(),
    isExtraordinaryVoteCase: Yup.boolean(),
    // isHouseBill: Yup.boolean(),
    // isUnknown: Yup.boolean(),
    paroleHiringDateTime: Yup.date().nullable(),
    referralType: Yup.string().nullable(),
    referralDetails: Yup.string(),
    isClientHiredAnotherAttorney: Yup.boolean(),
    hiredAnotherAttorneyReason: Yup.string().nullable(),
  });

  const { userId } = useAuth();
  const { repTypes } = useUser(userId);
  const formContext = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { watch, setValue } = formContext;
  const caseStatus = watch("caseStatus");
  const repType = watch("repType");
  const lawyer = watch("lawyer");
  const secondaryLawyer = watch("secondaryLawyer");

  const isClientHiredAnotherAttorney = caseData?.isClientHiredAnotherAttorney;
  const isAnotherAttorney = watch("isClientHiredAnotherAttorney");

  useEffect(() => {
    if (caseData?.representingInfo?.continueRepresentingThisCase === "yes") {
      return;
    }

    //set feeQuoted according to fee type
    const matchingRepType = repTypes?.find(
      (rep: any) => rep?.type.toLowerCase() === repType?.toLowerCase()
    );

    if (matchingRepType && repType !== caseData?.repType) {
      setValue("feeQuoted", matchingRepType.value);
    } else {
      setValue("feeQuoted", caseData?.feeQuoted);
    }
  }, [repType, setValue, repTypes, caseData]);

  const mailTypes = useMemo(() => {
    if (!caseData?.contactId?.email) return [];
    const array = caseData?.contactId?.email.map((item: any) => {
      return {
        label: capitalizeFirstLetter(item?.emailType),
        value: item?.emailType,
      };
    });
    return array;
  }, [caseData]);

  // const phoneTypes = useMemo(() => {
  //   if (!caseData?.contactId?.phone) return [];
  //   const array = caseData?.contactId?.phone.map((item: any) => {
  //     return {
  //       label: capitalizeFirstLetter(item?.phoneType),
  //       value: item?.phoneType,
  //     };
  //   });
  //   return array;
  // }, [caseData]);

  const onFormSubmit = async (values: any) => {
    try {
      const docuSignStatus = caseData?.docuSignStatus;
      const data: any = await updateCase({ ...values, docuSignStatus });

      const message = data?.message || "Success";

      showSnackbar(message, "success");
    } catch (error: any) {
      const message = error?.response?.data?.message || "An error occurred";

      showSnackbar(message, "error");
    }
  };

  return (
    <MainCard
      allowFormContainer={true}
      formContext={formContext}
      onSubmit={onFormSubmit}
      title={
        <Typography variant={innerWidth < 1420 ? "h2" : "h1"}>
          Case Info
        </Typography>
      }
      subheader={
        <Typography variant="h6">{`#${caseData?.caseId || "000001"}`}</Typography>
      }
      content={
        <Box
          sx={{
            height: "calc(100vh - 220px)",
            overflowY: "auto",
            p: 1,
            overflowX: "hidden",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <BasicAutocomplete
                disabled={isStaff}
                label="Case Status"
                name="caseStatus"
                required
                options={CaseInfoFileStatus}
                getOptionValue={(option: any) => option?.value}
              />
            </Grid>
            <Grid item xs={12}>
              <BasicAutocomplete
                label="Rep Type"
                name="repType"
                required
                options={RepType}
                disabled={!isPaymentCompleted || isCaseClosed || isStaff}
                getOptionValue={(option: any) => option?.value}
              />
            </Grid>
            <Grid item xs={12}>
              <NumberField
                label="Fee Quoted"
                name="feeQuoted"
                prefix="$ "
                decimalScale={2}
                fixedDecimalScale={true}
                max={999999}
                disabled={
                  caseData?.representingInfo?.continueRepresentingThisCase ===
                    "yes" ||
                  !isPaymentCompleted ||
                  isCaseClosed ||
                  isStaff
                }
                // options={Task}
              />
            </Grid>
            <Grid item xs={12}>
              <BasicAutocomplete
                label="Mail Type"
                name="mailType"
                options={mailTypes}
                required
                getOptionValue={(option: any) => option?.value}
                disabled={isCaseClosed || isStaff}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <BasicAutocomplete
                label="Phone Type"
                name="phoneType"
                options={phoneTypes}
                required
                getOptionValue={(option: any) => option?.value}
                disabled={isCaseClosed || isStaff}
              />
            </Grid> */}
            <Grid item xs={12}>
              <BasicAutocomplete
                label="Lawyer"
                name="lawyer"
                options={lawyers
                  ?.filter((user: any) => user?._id !== secondaryLawyer)
                  ?.map((user: any) => ({
                    label: `${user.firstName} ${user.lastName}`,
                    value: user?._id,
                  }))}
                getOptionValue={(option: any) => option?.value}
                required={caseStatus === "active" ? true : false}
                disabled={isCaseClosed || isStaff}
              />
            </Grid>
            <Grid item xs={12}>
              <BasicAutocomplete
                label="Secondary Lawyer"
                name="secondaryLawyer"
                options={lawyers
                  ?.filter((user: any) => user?._id !== lawyer)
                  ?.map((user: any) => ({
                    label: `${user.firstName} ${user.lastName}`,
                    value: user?._id,
                  }))}
                getOptionValue={(option: any) => option?.value}
                disabled={isCaseClosed || isStaff}
              />
            </Grid>
            <Grid item xs={12}>
              <BasicAutocomplete
                label="Staff"
                name="staff"
                required
                options={staff?.map((user: any) => ({
                  label: `${user.firstName} ${user.lastName}`,
                  value: user?._id,
                }))}
                getOptionValue={(option: any) => option?.value}
                disabled={isCaseClosed || isStaff}
              />
            </Grid>
            <Grid item xs={12}>
              <BasicDatePicker
                label="Initial Contact Date"
                name="initialContactDate"
                disabled={isCaseClosed || isStaff}
              />
            </Grid>
            <Grid item xs={12}>
              <BasicDatePicker
                label="Follow Up Date"
                name="followUpDate"
                disabled={isCaseClosed || isStaff}
              />
            </Grid>
            <Grid item xs={12}>
              <BasicDatePicker
                label="Parole Hiring Date"
                name="paroleHiringDateTime"
                disabled={isCaseClosed || isStaff}
              />
            </Grid>

            <Grid item xs={12}>
              <BasicDatePicker
                label="Retention Date"
                name="retentionDate"
                disabled={isCaseClosed || isStaff}
              />
            </Grid>
            <Grid item xs={12}>
              <BasicAutocomplete
                label="Lead Voter"
                name="leadVoter"
                freeSolo
                options={members}
                required={false}
                getOptionValue={(option: any) => option?.value}
                disabled={isCaseClosed || isStaff}
              />
            </Grid>
            <Grid item xs={6}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Checkbox
                  name="isExtraordinaryVoteCase"
                  label="Extraordinary Vote Case?"
                  disabled={isCaseClosed || isStaff}
                />
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Checkbox
                  name="isExistingClient"
                  label="Is Existing Client?"
                  disabled
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <BasicAutocomplete
                label="Referral Type"
                name="referralType"
                options={RefferalTypes}
                getOptionValue={(option: any) => option?.value}
                disabled={isCaseClosed || isStaff}
              />
            </Grid>
            <Grid item xs={12}>
              <MuiTextField
                minRows={2}
                multiline
                label="Referral Details"
                // placeholder="Referral Details"
                name="referralDetails"
                sx={{ borderColor: "#B2BAC2" }}
                // options={Task}
                //
                disabled={isCaseClosed || isStaff}
              />
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Checkbox
                  name="isClientHiredAnotherAttorney"
                  label="Has the client hired another attorney?"
                  disabled={
                    isCaseClosed || isStaff || isClientHiredAnotherAttorney
                  }
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <MuiTextField
                minRows={2}
                multiline
                label="Reason"
                // placeholder="Referral Details"
                name="hiredAnotherAttorneyReason"
                sx={{ borderColor: "#B2BAC2" }}
                // options={Task}
                //
                disabled={isCaseClosed || isStaff || !isAnotherAttorney}
              />
            </Grid>
          </Grid>
        </Box>
      }
      actions={
        <Stack direction={"row"} alignItems={"center"}>
          {!isStaff && (
            <BasicButton
              label={"Save"}
              type="submit"
              variant="contained"
              color="success"
              disabled={isCaseUpdating}
            />
          )}
          <Tooltip title="Hide Case Info">
            <IconButton color="primary" onClick={hideRoster} id="modalShow">
              <IconChevronsLeft />
            </IconButton>
          </Tooltip>
        </Stack>
      }
    />
  );
};

export default CaseInfo;
